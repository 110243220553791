import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { AuthMethodsService } from 'src/app/shared/services/auth/auth-methods.service';

@Component({
  selector: 'app-nutaku-login-modal',
  templateUrl: './nutaku-login-modal.component.html',
  styleUrls: ['./nutaku-login-modal.component.scss'],
})
export class NutakuLoginModalComponent implements OnInit {
  isSignUp = true;

  loading = false;
  emailField: string;
  passwordField: string;
  loginError = false;
  loginValidationError = false;

  constructor(
    private _modalCtrl: ModalController,
    private _analyticsService: AnalyticsService,
    private _authMethodsService: AuthMethodsService
  ) {}

  ngOnInit() {
    this._analyticsService.showNutakuAuthModal();
  }

  login() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.loginError = false;
    this.loginValidationError = false;

    this._authMethodsService
      .nutakuSignIn(this.emailField, this.passwordField)
      .subscribe(
        (res) => {
          this._modalCtrl.dismiss();
          this.loading = false;
        },
        (err) => {
          if (
            err.error.status === 'Credentialsmissing' ||
            err.error.status === 'InvalidCredentials'
          ) {
            this.loginValidationError = true;
          } else {
            this.loginError = true;
          }
          this.loading = false;
        }
      );
  }
}
