import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OpenNextMessage } from '../../types/open-next-message.interface';
import { NextMessagesPack } from '../../types/next-messages-pack.interface';
import { ChatApiMethodsService } from './chat-api-methods.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { UiStateService } from '../comunication_services/uiStates.service';

@Injectable({
  providedIn: 'root',
})
export class ChatStoreService {
  private _nextMessagePackArray: BehaviorSubject<NextMessagesPack[]> =
    new BehaviorSubject([]);
  private _modelName: string;

  constructor(
    private _chatApiMethodsService: ChatApiMethodsService,
    private _analyticsService: AnalyticsService,
    private _uiStateService: UiStateService
  ) {}

  public setModelName(name: string) {
    this._modelName = name;
  }

  private _getAndReduceMessages(chatId: string): OpenNextMessage[] {
    let nextMessage: OpenNextMessage = null;
    const packArray = this._nextMessagePackArray.getValue();

    if (!packArray) {
      return null;
    }
    const nextPackArray = packArray.map((chat) => {
      if (chat.chatId === chatId) {
        nextMessage = chat.messages?.shift();
        return chat;
      } else {
        return chat;
      }
    });

    this._nextMessagePackArray.next(nextPackArray);
    return [nextMessage];
  }

  private _getPackFromPacksOrCreate(chatId: string) {
    const _packs = this._nextMessagePackArray.getValue();
    const _pack = _packs.filter((pack) => pack?.chatId === chatId)[0];

    if (!_pack) {
      const newPack: NextMessagesPack = {
        chatId,
        messages: [],
      };
      _packs.push(newPack);
      return newPack;
    }

    return _pack;
  }

  public getNextMessagesFromPack(
    chatId: string
  ): Observable<OpenNextMessage[]> {
    const _packs = this._nextMessagePackArray.getValue();
    const _pack = this._getPackFromPacksOrCreate(chatId);

    if (_pack.messages.length === 0) {
      return this._chatApiMethodsService.getNextMessagePackFromAPI(chatId).pipe(
        map((res) => {
          if (res) {
            if (res[0]?.nextMessage) {
              this._uiStateService.changeData(
                UiStatesEnum.isOffline,
                res[0].isOffline
              );

              this._analyticsService.fetchMessagesPack({
                name: this._modelName,
                messageIndex: res[0].nextMessage.orderIdx,
                chatId: res[0].nextMessage.chatId,
              });
            }
            res.forEach((m) => {
              if (m.nextMessage) {
                m.nextMessage.isNewMessage = true;
                m.nextMessage.displayTime = this.getCurrentDateTime();
              }
            });

            const packs = _packs.map((pack) => {
              if (pack.chatId === chatId) {
                return {
                  chatId: pack.chatId,
                  messages: res, // Replace old value with new came from API
                };
              } else {
                return pack;
              }
            });
            this._nextMessagePackArray.next(packs);
          }
          return this._getAndReduceMessages(chatId);
        })
      );
    } else {
      return of(this._getAndReduceMessages(chatId));
    }
  }

  private getCurrentDateTime() {
    const date = new Date();

    return date.toLocaleString('en', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return (
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('00' + date.getDate()).slice(-2) +
      '/' +
      date.getFullYear() +
      ' ' +
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2)
    );
  }
}
