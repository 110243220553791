<ng-container
  *ngIf="onlineStreamNotificationsService.onlineModelData | async as data"
>
  <div
    class="girl-is-online-container"
    *ngIf="
      chatId === data.chatId && !onlineStreemModalSwitchedOffInCurrentSession
    "
    [@slideInOutBottomDir]
  >
    <a
      class="girl-is-online"
      [href]="data.link"
      target="_blank"
      (click)="clickOnOnlineStream()"
    >
      <img src="/assets/img/bonus-page/quest-line/stripchat.svg" alt="" />
      <span [innerHTML]="'CHATS_PAGE.ONLINE_STRIPCHAT' | translate"></span>
    </a>
    <button class="close" (click)="closeOnlineStreamModal()">×</button>
    <button class="turn-off-notifications" (click)="goToProfile()">
      <img src="/assets/img/notification-off.png" alt="" />
    </button>
  </div>
</ng-container>
