import { Component, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent {
  environment = environment;
  buildVersion = BuildVersion;
  constructor(private _modalController: ModalController) {}

  public close(action: boolean = false): void {
    this._modalController.dismiss(action);
  }
}
