<div class="neon-modal">
  <button (click)="close($event)" class="close-btn">
    <img src="/assets/img/modals/close-btn-black.svg" alt="" />
  </button>

  <img src="/assets/img/offers-new/big-pack/girl.png" alt="" class="girl-img" />

  <div class="top">
    <h1 class="title">Big<br />Pack</h1>

    <ul class="items">
      <li *ngFor="let item of offerData.items; let index = index" class="item">
        {{ itemNames[index] }}
      </li>
    </ul>
  </div>

  <div class="bottom">
    <button class="neon-btn btn-cyan">
      <app-price-block [purchase]="offerData.purchase"></app-price-block>

      <img src="/assets/img/offers-new/misc/shape2.png" alt="" class="shape" />
    </button>
    <p *ngIf="offerData.time !== null" class="time-left">
      Time left:
      <app-offer-timer [initialTime]="offerData.time"></app-offer-timer>
    </p>
  </div>
</div>
