import { Component, EventEmitter, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent {
  environment = environment;
  buildVersion = BuildVersion;
  constructor(private _modalController: ModalController) {}

  public close(action: boolean = false): void {
    this._modalController.dismiss(action);
  }
}
