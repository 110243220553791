import { OfferType } from './../../../../enums/offer-type.enum';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chats-pack',
  templateUrl: './chats-pack.component.html',
  styleUrls: ['./chats-pack.component.scss'],
})
export class ChatsPackComponent implements OnInit {
  @Input() offerData: OfferData;
  @Input() appearanceType: 'modal' | 'banner' | 'smallBanner';
  environment = environment;
  offerType = OfferType;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);
  constructor() {}

  ngOnInit() {}
}
