import { RealPurchaseData } from './../../types/purchase-data.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PaymentsStateService } from './payments-state.service';
import { PaymentAnimationStates } from '../../enums/payment-animation-states';
import { LocalStorageService } from '../comunication_services/localStorage.service';
import { PaymentResponse } from '../../types/payment-response.interface';
// import { Browser } from '@capacitor/browser';
import {
  catchError,
  map,
  take,
  retryWhen,
  takeWhile,
  switchMap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthStateService } from '../auth/auth-state.service';
import { PaymentMethod } from '../../enums/payment-method.enum';
import { NutakuPaymentService } from './nutaku-payment.service';
import { PaymentsCommonMethodsService } from './payments-common-methods.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class WebPaymentService {
  purchaseViaIframeAction: BehaviorSubject<PaymentResponse> =
    new BehaviorSubject(null);
  private _host: string = environment.apiUrl;
  isApk = this._platform.is('hybrid');
  constructor(
    private _http: HttpClient,
    private _platform: Platform,
    private _localStorageService: LocalStorageService,
    private _paymentsStateService: PaymentsStateService,
    private _authStateService: AuthStateService,
    private _nutakuPaymentService: NutakuPaymentService,
    private _paymentsCommonMethodsService: PaymentsCommonMethodsService
  ) {}

  purchase(data: RealPurchaseData, paymentMethod = null): Observable<boolean> {
    return this._purchase(data, paymentMethod);
  }

  private _purchase(
    data: RealPurchaseData,
    paymentMethod = null
  ): Observable<boolean> {
    this._paymentsStateService.paymentState = PaymentAnimationStates.progress;

    return this._getPurchaseDataFromAPI(data, paymentMethod).pipe(
      switchMap((res) => {
        this._localStorageService.setTransactionId(res.payment.transactionId);
        setTimeout(() => {
          // To avoid permanent loader due any error with iframes and inappbrowsers
          this._paymentsStateService.paymentState = null;
        }, 5000);
        if (this.isApk) {
          if (paymentMethod === PaymentMethod.nutakuClient) {
            return this._nutakuPaymentService.handleNutakuPurchase(res);
          } else {
            this._paymentsCommonMethodsService.openInCurrentWindow(res); // Open in current window for Segpay
          }
          return of(false);
        } else {
          if (data.purchase.useCentrobill) {
            this._openInIframe(res);
          } else {
            this._paymentsCommonMethodsService.openInCurrentWindow(res);
          }
          return of(false);
        }
      }),
      retryWhen((errors) =>
        errors.pipe(
          takeWhile((error) => {
            // IF 403 -> Go to RegForm in switchmap and then retry again a request
            if (error.status === 403) {
              this._paymentsStateService.paymentState = null;
              return true;
            } else {
              this._paymentsStateService.paymentState =
                PaymentAnimationStates.error;
              return false;
            }
          }),
          switchMap(() =>
            this._authStateService.showRegistrationForm$({
              isShow: true,
              data: {
                placeWereCalled: 'At purchase: ' + data.purchase.productId,
                enableClosing: true,
              },
            })
          ),
          map((res: boolean) => {
            if (!res) {
              // eslint-disable-next-line no-throw-literal
              throw 'closeReg'; // Any text to avoid stuck in 403 error looping
            }
            return res;
          })
        )
      ),
      catchError((err) => {
        throw err;
      })
    );
  }

  private _openInIframe(res: PaymentResponse) {
    this.purchaseViaIframeAction.next(res);
    return of(true);
  }

  private _getPurchaseDataFromAPI(
    data: RealPurchaseData,
    paymentMethod: PaymentMethod
  ): Observable<PaymentResponse> {
    const requestUrl = `${this._host}/api/Shop/buy`;
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    });
    // const str = 'cc';
    // If android - we need redirect. If no - we just close iframe without redirect. TODO investigate and optimize.
    // But Not for segpay
    // const url = this._platform.is('android') ? `${window.location.origin}${window.location.pathname}` : null;
    const url = `${window.location.origin}${window.location.pathname}`;

    const pm =
      paymentMethod ||
      (data.purchase.useCentrobill
        ? PaymentMethod.centrobill
        : PaymentMethod.seqpay);

    const bodyData = {
      paymentMethod: pm,
      redirectUrl: url,
      productId: data.purchase.productId,
      chatId: data.chatId,
      source: data.source,
    };

    const body = JSON.stringify(bodyData);

    return this._http
      .post<PaymentResponse>(requestUrl, body, {
        headers,
      })
      .pipe(take(1));
  }
}
