<div
  class="tutorial-layer"
  class="{{ 'step' + step }}"
  [ngClass]="{ 'show-tips': isShowTips }"
  *ngIf="false && step !== null && showTutorial"
>
  <div class="finger" (click)="onFingerTap()" #finger>
    <!-- <img [src]="environment.rootPath +  '/assets/img/finger.gif'"> -->
    <ng-lottie
      class="animated-finger"
      [options]="handTutorialAnimationOptions"
    ></ng-lottie>
  </div>

  <div class="tutorial-block">
    <div class="wrap">
      <div class="shadow-img">
        <img
          [src]="environment.rootPath + '/assets/img/girl.png'"
          class="assistant-avatar"
          alt="girl"
        />
        <img
          [src]="environment.rootPath + '/assets/img/shadow-small.png'"
          class="img-cloud"
          alt=""
        />
        <img
          [src]="environment.rootPath + '/assets/img/shadow-big.png'"
          class="img-cloud img-cloud-reverse"
          alt=""
        />
      </div>
      <div class="assistant">Assistant:</div>
      <div class="description">
        <div *ngIf="step === 1">
          Welcome to {{ appName }} app!<br />
          App where you can get access and read chats where playboy seduces sexy
          models and they send him hot photos and videos.<br />
          Let's start... <br /><br />
          Click on "tap to continue...." to see the chat continue.
        </div>
        <div *ngIf="step === 3">Click on the photo</div>
        <div *ngIf="step === 4">
          Don't worry. photos will be saved only to gallery of app, not to your
          phone
        </div>
        <div *ngIf="step === 5">
          The photo is saved to the gallery. Click the upper left button to
          return to the chat.
        </div>
        <div *ngIf="step === 6">
          Click on "tap to continue...." to see the chat continue.
        </div>
        <div *ngIf="step === 7">
          Press "SKIP TIMER" to continue read the chat now, then there will be
          more sexy photos. Or you can wait when she will back online
        </div>
        <div *ngIf="step === 8">
          Click on "tap to continue...." to see the chat continue.
        </div>
        <div *ngIf="step === 9">
          Click BUY and you'll see many more candid photos. You still have large
          number of crystals
        </div>
        <div *ngIf="step === 10">
          Click on "tap to continue....". And you'll see all the extra photos
          and videos
        </div>
        <div *ngIf="step === 11">You can get free crystals every day.</div>
        <div *ngIf="step === 12">
          Come into the game every day and get valuable prizes.<br />
          Click and claim today’s reward
        </div>
        <div *ngIf="step === 13">
          Click on CLAIM TODAY'S REWARD to collect your daily bonus
        </div>
        <div *ngIf="step === 16">
          Each girl is a unique experience that you have to uncover. You can buy
          them all!
        </div>
        <!-- <div *ngIf="step === 17">Now you know what to do and you can read other chats, there are lots of Adult Photos and Videos</div> -->
      </div>
      <!-- <button class="btn btn-block"  >Tap on chat to continue</button> -->
    </div>
  </div>
</div>
