<div class="neon-modal">
  <button (click)="close($event)" class="close-btn">
    <img src="/assets/img/modals/close-btn-black.svg" alt="" />
  </button>

  <img
    src="/assets/img/offers-new/five-chats/girls.png"
    alt=""
    class="girls-img"
  />

  <div class="title">
    <h1>5</h1>
    <h2>Secret<br />Chats</h2>
  </div>

  <div class="bottom">
    <p class="access-text">Get access to 5 new secret chats with top girs</p>
    <button class="neon-btn btn-green">
      <img
        src="/assets/img/offers-new/five-chats/emoji.png"
        alt=""
        class="emoji"
      />
      <app-price-block [purchase]="offerData.purchase"></app-price-block>
    </button>
    <p *ngIf="offerData.time !== null" class="time-left">
      Time left:
      <app-offer-timer [initialTime]="offerData.time"></app-offer-timer>
    </p>
  </div>
</div>
