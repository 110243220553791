import { Component, Input, OnInit } from '@angular/core';
import { LegalPaymentService } from 'src/app/shared/services/payments/legal-payment.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-price-block-new',
  templateUrl: './price-block-new.component.html',
  styleUrls: ['./price-block-new.component.scss'],
})
export class PriceBlockNewComponent implements OnInit {
  @Input() public offerData: OfferData;
  @Input() public withDiscount = true;
  @Input() public floating = false;

  public environment = environment;
  public buildVersion = BuildVersion;

  public isShowDiscount = false;
  public oldCost = '';

  constructor(public legalPaymentService: LegalPaymentService) {}

  public ngOnInit(): void {
    switch (environment.buildVersion) {
      case BuildVersion.default:
        this.oldCost = this.offerData.purchase.oldCost
          ? `$${this.offerData.purchase.oldCost}`
          : '';
        break;
      case BuildVersion.nutaku:
        this.oldCost = this.offerData.purchase.oldNutakuCost.toString();
        break;
      case BuildVersion.erogames:
        this.oldCost = this.offerData.purchase.oldErogamesCost.toString();
        break;
    }

    this.isShowDiscount = this.withDiscount && this.oldCost !== '';
  }
}
