import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { slideInOutTopDir } from '../../animations/slide-in-out.animation';
import { NavHelper } from '../../helpers';
import {
  OnlineStreamNotificationsService,
  ToastOnlineStreamData,
} from '../../services/comunication_services/online-stream-notifications.service';

@Component({
  selector: 'app-girl-online-toast',
  templateUrl: './girl-online-toast.component.html',
  styleUrls: ['./girl-online-toast.component.scss'],
  animations: [slideInOutTopDir],
})
export class GirlOnlineToastComponent implements OnInit, OnDestroy {
  data: ToastOnlineStreamData;
  private _lifeTimer;
  constructor(
    private _toastrService: ToastrService,
    private _onlineStreamNotificationsService: OnlineStreamNotificationsService,
    private _navHelper: NavHelper,
    private _analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.data = this._onlineStreamNotificationsService.passedDataToComponent;
    this._lifeTimer = setTimeout(() => {
      this.closeModal();
    }, 20000);

    this._analyticsService.showStreamToast(this.data);
  }

  editNotification() {
    this._navHelper.goToProfile(this.data.chatId);
    this.closeModal();
  }

  closeModal() {
    this._toastrService.remove(this._onlineStreamNotificationsService.toastId);
  }

  clickOnOnlineStream() {
    this._analyticsService.clickOnOnlineStream('toast', this.data.chatId);
  }

  ngOnDestroy(): void {
    clearTimeout(this._lifeTimer);
  }
}
