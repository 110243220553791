import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlbumData } from '../../types/album-data.interface';

@Injectable({
  providedIn: 'root',
})
export class AlbumDataService {
  private defaultData: AlbumData = {
    contentData: [],
    countContent: 0,
    id: '',
    name: '',
  };
  private data: AlbumData;
  public updateGalleryData: BehaviorSubject<AlbumData>;

  constructor() {
    this.data = this.defaultData;
    this.updateGalleryData = new BehaviorSubject(this.data);
  }

  public changeAllObject(value: any): void {
    this.data = value;
    this.updateGalleryData.next(this.data);
  }

  public changeDefaultData(): void {
    this.data = this.defaultData;
    this.updateGalleryData.next(this.data);
  }
}
