import { LegalPaymentService } from 'src/app/shared/services/payments/legal-payment.service';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'getStorePrice',
  pure: true
})
export class GetStorePricePipe implements PipeTransform {
  constructor(private _legalPaymentService: LegalPaymentService) {}
  transform(id: string): any {
    return this._legalPaymentService.getProductPriceByCommonId(id);
  }
}
