<div class="modal">
  <div class="modal-head">
    Privacy policy
    <button class="prev" (click)="close()">
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0607 3.06066C11.6464 2.47487 11.6464 1.52513 11.0607 0.93934C10.4749 0.353553 9.52513 0.353553 8.93934 0.93934L11.0607 3.06066ZM2 10L0.93934 8.93934C0.353553 9.52513 0.353553 10.4749 0.93934 11.0607L2 10ZM8.93934 19.0607C9.52513 19.6464 10.4749 19.6464 11.0607 19.0607C11.6464 18.4749 11.6464 17.5251 11.0607 16.9393L8.93934 19.0607ZM22 11.5C22.8284 11.5 23.5 10.8284 23.5 10C23.5 9.17157 22.8284 8.5 22 8.5V11.5ZM8.93934 0.93934L0.93934 8.93934L3.06066 11.0607L11.0607 3.06066L8.93934 0.93934ZM0.93934 11.0607L8.93934 19.0607L11.0607 16.9393L3.06066 8.93934L0.93934 11.0607ZM2 11.5H22V8.5H2V11.5Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </div>

  <div class="modal-content" style="font-size: 11px !important">
    <!-- <div class="list-item">1.1 How we may use the information we collect.</div>
        <div class="list-item-content">
            <p>We use the information in the following ways:</p>
            <p>Information you supply to us. We will use this information:</p>
            <p>To provide you with information and/or services that you request from us;</p>
            <p>[Describe any other uses of the information you collect (ie. other marketing communications to
                individuals who have requested a prospectus)]</p>
            <p>Information we automatically collect about you. We will use this information:</p>
            <p>to administer our site including </p>
        </div> -->

    <p class="c0 c15">
      <span class="c3"><br /></span>
    </p>
    <p class="c0">
      <span class="c7">We, </span><span class="c6">GETHINS LIMITED </span
      ><span class="c12 c7">Cyprus</span><span class="c6 c12">, a.s.</span
      ><span class="c12 c7"
        >&nbsp;(&ldquo;GETHINS&rdquo;), GETHINS, a corporate entity existing
        under the laws of the Republic of Cyprus company registration number: HE
        390472, having its principal place of business at Prodromou 75, Oneworld
        Parkview House, Floor 4, 2063 Nicosia, Cyprus, the provider of the
        website {{ environment.policy.site | lowercase }} (hereinafter referred
        to as the &ldquo;</span
      ><span class="c6 c12">Website</span
      ><span class="c12 c7"
        >&rdquo;). The provider of the Website is the responsible data
        controller in the sense of Article 4(7) of European Regulation (EU)
        2016/679 of the E</span
      ><span class="c7"
        >uropean Parliament and of the Council on the protection of natural
        persons with regard to the processing of personal data and on the free
        movement of such data, and repealing Directive 95/46/EC (hereinafter
        referred to as the &ldquo;</span
      ><span class="c6">GDPR</span><span class="c3">&rdquo;).</span>
    </p>
    <p class="c0">
      <span class="c3"
        >The Website will be used by the following categories of users:</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >a) Category 1: a visitor of the Website who does not create an account
        on the Website and cannot upload any content;</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >b) Category 2: a user of the Website who creates an account, but does
        not upload any content or other material;</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >c) Category 3: a user of the Website who creates an account and can
        upload text or visual content; and</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >d) Category 4: a user of the Website who creates an account and
        participates in revenue sharing.</span
      >
    </p>
    <p class="c0">
      <span class="c7"
        >Subject to the Terms of Service and as explained in the privacy notice
        at </span
      ><span class="c7 c16"
        ><a
          class="c9"
          [href]="
            'https://www.google.com/url?q=' +
            environment.policy.docsBaseUrl +
            '/legal/privacynotice&amp;sa=D&amp;source=editors&amp;ust=1635513749120000&amp;usg=AOvVaw2boeJ0xuI8U5HPf4Hqvl7w'
          "
          >{{ environment.policy.docsBaseUrl }}/legal/privacynotice</a
        ></span
      ><span class="c7"
        >, we do not process personal data of the Category 1 users (i.e., the
        visitors of the Website who have not created an account). Instead, in
        such instances, we use cookies for the purposes identified in our cookie
        policy, which is available at </span
      ><span class="c16 c7"
        ><a
          class="c9"
          [href]="
            'https://www.google.com/url?q=' +
            environment.policy.docsBaseUrl +
            '/legal/cookiepolicy&amp;sa=D&amp;source=editors&amp;ust=1635513749121000&amp;usg=AOvVaw1F0mKlNGioXLsNgIODh6v6'
          "
          >{{ environment.policy.docsBaseUrl }}/legal/cookiepolicy</a
        ></span
      ><span class="c7"
        >. For more information, including on how to manage your preferences on,
        and disable, cookies, please refer to our cookie policy, which is
        available at </span
      ><span class="c16 c7"
        ><a
          class="c9"
          [href]="
            'https://www.google.com/url?q=' +
            environment.policy.docsBaseUrl +
            '/legal/cookiepolicy&amp;sa=D&amp;source=editors&amp;ust=1635513749121000&amp;usg=AOvVaw1F0mKlNGioXLsNgIODh6v6'
          "
          >{{ environment.policy.docsBaseUrl }}/legal/cookiepolicy</a
        ></span
      ><span class="c3">.</span>
    </p>
    <p class="c0">
      <span class="c3"
        >For the individuals who fall within the remaining three categories of
        users listed above (i.e., Category 2; Category 3; and Category 4),
        personal data is processed in line with this privacy policy.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >This privacy policy for Categories 2, 3 and 4 above describes how we
        collect, use, process, and disclose your information, including personal
        information, in conjunction with your access to and use of the Website.
        We process personal data only in accordance with this privacy
        policy.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >By accessing the Website, you acknowledge that you have read this
        privacy policy and understand that your personal data may be processed,
        including as explained below any data that may reflect or concern your
        sex life, sexual preferences, and sexual orientation. You also
        acknowledge that without your consent, we may be unable to comply with
        our contractual obligations, may be unable to offer you the entire range
        of products and services available on the Website, and may prevent you
        from creating an account on this Website.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >By accessing this Website and creating an account, you consent to
        GETHINS processing your personal data, including data that may reflect
        or concern my sex life, sexual preferences, and sexual orientation. You
        grant my consent on the basis that GETHINS undertakes to rely on this
        basis only where so is necessary and proportionate in order to be able
        to carry out its contractual obligations and protect its legitimate
        business aims.</span
      >
    </p>
    <h3 class="c1" id="h.96h4uixubo8m">
      <span class="c5">1. INFORMATION WE COLLECT</span>
    </h3>
    <p class="c0">
      <span class="c3"
        >Personal data refers to any information that tells us something about
        you or that allows us to identify you, including your name, location
        data, and online identifiers.</span
      >
    </p>
    <p class="c0">
      <span class="c7"
        >Subject to the Terms of Service and as explained in the privacy notice,
        available at </span
      ><span class="c16 c7"
        ><a
          class="c9"
          [href]="
            'https://www.google.com/url?q=' +
            environment.policy.docsBaseUrl +
            '/legal/privacynotice&amp;sa=D&amp;source=editors&amp;ust=1635513749122000&amp;usg=AOvVaw1Kb-8rAx1yBuvauwt7hXY3'
          "
          >{{ environment.policy.docsBaseUrl }}/legal/privacynotice</a
        ></span
      ><span class="c7"
        >, we do not process personal data of the visitors of the Website who
        have not created an account (i.e., those that fall within the Category 1
        of users, listed on page 1 of this privacy policy). Instead, we use
        cookies for the purposes identified in our cookie policy, available at </span
      ><span class="c16 c7"
        ><a
          class="c9"
          [href]="
            'https://www.google.com/url?q=' +
            environment.policy.docsBaseUrl +
            '/legal/cookiepolicy&amp;sa=D&amp;source=editors&amp;ust=1635513749122000&amp;usg=AOvVaw2X_giLCMQvi-Vr8NPvzlg2'
          "
          >{{ environment.policy.docsBaseUrl }}/legal/cookiepolicy</a
        ></span
      ><span class="c7"
        >. For more information, including on how to manage your preferences on
        and to disable cookies, please refer to our cookie policy, available at </span
      ><span class="c16 c7"
        ><a
          class="c9"
          [href]="
            'https://www.google.com/url?q=' +
            environment.policy.docsBaseUrl +
            '/legal/cookiepolicy&amp;sa=D&amp;source=editors&amp;ust=1635513749123000&amp;usg=AOvVaw2u1M_owxZ8P1R5CbL0mvdZ'
          "
          >{{ environment.policy.docsBaseUrl }}/legal/cookiepolicy</a
        ></span
      ><span class="c3">.</span>
    </p>
    <p class="c0">
      <span class="c3"
        >For the individuals who have created an account on or through this
        Website (i.e., those individuals who fall within the Category 2,
        Category 3, or Category 4 of users listed on page 1 of this privacy
        policy), we process the following categories of information:</span
      >
    </p>
    <h4 class="c8" id="h.5ivi8h6raqfm">
      <span class="c4">1.1 Information You Give to Us.</span>
    </h4>
    <p class="c0">
      <span class="c3"
        >We collect your personal information that we receive from you when you
        use the Website, including personal information you provide to us
        including when you: watch a video, enquire or make an application for
        our services, register to use and/or use any of our services and when
        you communicate with us through email, the Website or portal, or any
        other electronic means.</span
      >
    </p>
    <p class="c0"><span class="c3">Such information may include:</span></p>
    <ul class="c18 lst-kix_6bx5uqaabumo-0 start">
      <li class="c2 li-bullet-0">
        <span class="c3"
          >your name including first name and family name, gender, date of
          birth, email address, billing address, username, password, photograph,
          nationality and country of residence, etc.;</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >additional personal information (e.g. data reflecting or concerning a
          natural person&rsquo;s sexual orientation).</span
        >
      </li>
    </ul>
    <p class="c0">
      <span class="c7"
        >We do not accept that we are a joint controller of personal data of any
        third parties (other than you) available or contained in any of the
        forms submitted, or otherwise provided to us, by you (&ldquo;</span
      ><span class="c6">Third Party Personal Data</span
      ><span class="c3"
        >&rdquo;), and disclaim any and all liability in connection with the
        Third Party Personal Data.</span
      >
    </p>
    <h4 class="c8" id="h.1m8izt5r0rbj">
      <span class="c4"
        >1.2 Information We Automatically And Necessarily Collect from Your Use
        of the Website.</span
      >
    </h4>
    <p class="c0">
      <span class="c3"
        >We collect information about the services that you use and how you use
        them, such as when you watch a video on the Website, visit a website
        that uses our advertising services or view or interact with our ads and
        content. This information includes:</span
      >
    </p>
    <ul class="c18 lst-kix_huugrkrp4box-0 start">
      <li class="c2 li-bullet-0">
        <span class="c6">Device information.</span
        ><span class="c3"
          >&nbsp;We collect device specific information such as IP address,
          access dates and times, hardware and software information, device
          information and cookie data.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c6">Geolocation Information.</span
        ><span class="c3"
          >&nbsp;When you use certain features of the Website, we may collect
          information about your approximate location, as determined through
          data such as your IP address.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c6">Usage Information.</span
        ><span class="c3"
          >&nbsp;We collect information about your interactions with the Website
          such as the content you view, your searches queries and other
          interactions with the Website.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c6">Cookies and Similar Technologies.</span
        ><span class="c7"
          >&nbsp;We use cookies and other similar technologies, such as web
          beacons, pixels, and mobile identifiers. For more information on our
          use of these technologies, see our Cookie Policy, available at </span
        ><span class="c16 c7"
          ><a
            class="c9"
            [href]="
              'https://www.google.com/url?q=' +
              environment.policy.docsBaseUrl +
              '/legal/cookiepolicy&amp;sa=D&amp;source=editors&amp;ust=1635513749125000&amp;usg=AOvVaw3FdfNd2NKDu6hnC0FVAsJ4'
            "
            >{{ environment.policy.docsBaseUrl }}/legal/cookiepolicy</a
          ></span
        ><span class="c3">.</span>
      </li>
    </ul>
    <h4 class="c8" id="h.daum09y2jsti">
      <span class="c4"
        >1.3 Information We Collect from Third Parties and Third-Party
        Links.</span
      >
    </h4>
    <p class="c0">
      <span class="c3"
        >We may collect information, including personal information that others
        provide about you when they use the Website, or obtain information from
        other sources and combine that with information we collect through the
        Website. We do not control or supervise and are not responsible for how
        the third parties providing your information process your personal data,
        and any information request regarding the disclosure of your personal
        information to us should be directed to such third parties.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >Furthermore, to the extent permitted by applicable law, we may receive
        additional information about you, such as demographic data or fraud
        detection information, and combine it with information we have about
        you.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >Please note that this Website may include links to third-party
        websites, plug-ins and applications. Clicking on those links or enabling
        those connections may allow third parties to collect or share data about
        you. We do not control or supervise these third-party websites and are
        not responsible for their privacy statements. When you leave our
        Website, we encourage you to read the privacy policy of every site you
        visit.</span
      >
    </p>
    <h4 class="c8" id="h.aa9raf1g134r">
      <span class="c4">1.4 Accuracy of Information.</span>
    </h4>
    <p class="c0">
      <span class="c3"
        >It is also important that the personal data we hold about you is
        accurate and current. Please keep us informed if your personal data
        changes during your relationship with us.</span
      >
    </p>
    <h3 class="c1" id="h.vy9pupsubqtp">
      <span class="c5">2. HOW WE USE INFORMATION WE COLLECT</span>
    </h3>
    <p class="c0">
      <span class="c3"
        >We use, store, and process information, including personal information,
        about you to provide, understand, improve, and develop the Website, to
        protect us and our users and to comply with our legal obligations. We
        also use this information to offer you tailored content &ndash; like
        giving you more relevant search results and ads.</span
      >
    </p>
    <p class="c0">
      <span class="c7"
        >For the avoidance of doubt, we do not accept that we are a joint
        controller of personal data of any third parties (other than you)
        available or contained in any user Submission uploaded by you pursuant
        to the Terms of Service (&ldquo;</span
      ><span class="c6">Third Party Personal Data</span
      ><span class="c3"
        >&rdquo;), and disclaim any and all liability in connection with the
        Third Party Personal Data.</span
      >
    </p>
    <h4 class="c8" id="h.kidb253xa8rz">
      <span class="c4">2.1 Provide, Improve, and Develop the Website.</span>
    </h4>
    <ul class="c18 lst-kix_g8ugfpvks60d-0 start">
      <li class="c2 li-bullet-0">
        <span class="c3">Enable you to access and use the Website.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3">Enable you to communicate with other users.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Operate, protect, improve, and optimize the Website, such as by
          performing analytics and conducting research.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3">Provide customer service.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Send you service or support messages, updates, security alerts, and
          account notifications.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >To operate, protect, improve, and optimize the Website, and
          personalize and customize your experience (such as making suggestions
          or ranking search results), we conduct profiling based on your
          interactions with the Website, your search and viewing history, your
          profile information and preferences, and other content you submit to
          the Website.</span
        >
      </li>
    </ul>
    <p class="c0">
      <span class="c6">Lawful bases</span
      ><span class="c3"
        >: we rely on the following three lawful bases to process your personal
        data for the specific purpose of providing, improving and developing the
        Website as described above.</span
      >
    </p>
    <ol class="c18 lst-kix_17h0yhg50ba-0 start" start="1">
      <li class="c2 li-bullet-0">
        <span class="c3"
          >We process this information in order to adequately perform the
          contract with you;</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >our legitimate business interests in processing your personal data,
          specifically for the purpose of improving the Website and our
          users&rsquo; experience with it, which we have determined can only be
          achieved by processing your personal data. We consider the processing
          of your personal data to be proportionate with your interests, rights
          and freedoms;</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >to the extent necessary to perform our contract with you and
          customize your experience, consent to process special categories of
          data.</span
        >
      </li>
    </ol>
    <h4 class="c8" id="h.i2vjzotuhqhi">
      <span class="c4"
        >2.2 Protect us and our Users and Comply with Legal Obligations.</span
      >
    </h4>
    <ul class="c18 lst-kix_wxf9ept0vdvo-0 start">
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Detect and prevent fraud, spam, abuse, security incidents, and other
          harmful activity.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Conduct security investigations and risk assessments, including any
          reports third parties may file about you or the content you have
          uploaded pursuant to the Terms of Service.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Comply with our legal obligations, including assistance in the
          prevention of crimes, including child sexual abuse material or
          non-consensual sexual acts.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Resolve any disputes with any of our users and enforce our agreements
          with third parties.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Communicate with you regarding any abuse reports (if you do not
          submit them anonymously), or any copyright infringement takedown
          requests or counter notifications (which cannot be submitted
          anonymously) that you may file pursuant to the Terms of Service.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3">Enforce our Terms of Service and other policies.</span>
      </li>
    </ul>
    <p class="c0">
      <span class="c6">Lawful bases</span
      ><span class="c3"
        >: we rely on the following three lawful basis to process your personal
        data:</span
      >
    </p>
    <ol class="c18 lst-kix_2blpkb9a9o87-0 start" start="1">
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Legal obligations to which we are subject, which may include
          disclosing your personal data to a third party or competent national
          authorities.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Consent to process certain special categories of personal data to the
          extent necessary to achieve the purposes set out above, such as
          transferring limited categories of your personal data to third parties
          against whom you have filed a copyright infringement takedown request
          under the Digital Millennium Copyright Act (DMCA) or to whom you are
          responding with a copyright counter-notification, in accordance with
          the Terms of Service and as further described below,</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >legitimate interests in protecting our business, which includes
          protecting the Website and our reputation as a law-abiding business to
          the extent appropriate. We consider the processing of your personal
          data to be proportionate with your interests, rights and
          freedoms.</span
        >
      </li>
    </ol>
    <p class="c0">
      <span class="c6 c10"
        >2.2.1 Personal data processed when filing a takedown request or a
        counter-notification pursuant to paragraph 6C of the Terms of
        Service</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >Although our Company is based outside the United States, we respect the
        rights of copyright owners and thus have implemented certain policies in
        an effort to voluntarily comply with laws, such as the Digital
        Millennium Copyright Act (&quot;DMCA&quot;), which are described further
        below.</span
      >
    </p>
    <p class="c0">
      <span class="c10 c6"
        >What is the Digital Millennium Copyright Act
        (&ldquo;DMCA&rdquo;)?</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >The takedown process provided for by the DMCA is a tool for copyright
        holders to have user-uploaded material that infringes their copyrights
        taken down from our website. The process entails the copyright owner (or
        the owner&rsquo;s agent) sending a copyright infringement takedown
        request to a service provider requesting that the provider remove
        material that the copyright owner contends is infringing their
        copyright(s). Once a website is in receipt of the takedown request, it
        is required to send the takedown request to the uploader of the material
        in question. The uploader is subsequently given a chance to respond to
        the takedown request . The mechanism serves as an initial step for the
        copyright holder and the uploader to resolve any dispute relating to the
        copyrighted material at issue. For that purpose, NKL is required to
        process and transfer limited personal data between the individuals
        submitting a takedown request (i.e., the alleged copyright holder) and
        the uploader.</span
      >
    </p>
    <p class="c0">
      <span class="c7"
        >In compliance with the DMCA, we only accept copyright infringement
        takedown notices from content owners or someone officially authorized to
        act on their behalf. To read more about the requirements of a complete
        notice, we invite you to visit </span
      ><span class="c17 c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://www.dmca.com/faq/What-is-a-DMCA-Takedown&amp;sa=D&amp;source=editors&amp;ust=1635513749132000&amp;usg=AOvVaw2L0t8Cf3tRC5DZ7KAbJ65H"
          >https://www.dmca.com/faq/What-is-a-DMCA-Takedown</a
        ></span
      ><span class="c3"
        >, and consult with your own counsel. (We do not and cannot provide you
        with legal advice, and none should be implied from this Privacy Notice
        or anything else on our site.) Information provided for purposes of
        copyright takedown requests / counter-notifications will be forwarded to
        the uploader of the material at issue.</span
      >
    </p>
    <p class="c0">
      <span class="c10 c6"
        >What lawful basis do we rely upon to process your personal data when
        you file a copyright infringement takedown request /
        counter-notification pursuant to the DMCA?</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >For the specific purpose of our review of your copyright infringement
        takedown request / counter-notification which you may have filed
        pursuant to paragraph 6C of the Terms of Service, you are required to
        provide the information needed to make a valid request, i.e.: (a)
        takedown website URL and (b) statement regarding the ownership of this
        content. We will process your personal data in order to further our
        legitimate interests as an entertainment business that respects
        intellectual property, abide by the law and acts appropriately when
        reports of copyright infringements are made. For those legitimate
        purposes, we process your personal data we deem necessary in order to be
        able to review the takedown request / counter-notification that you may
        have submitted pursuant to paragraph 6C of the Terms of Service and to
        allow us to contact you about its content. We consider the processing of
        your personal data to be proportionate with your interests, rights and
        freedoms.</span
      >
    </p>
    <p class="c0">
      <span class="c10 c6"
        >What lawful basis do we rely upon to transfer your personal data to the
        addressee of the copyright infringement takedown request /
        counter-notification pursuant to the DMCA?</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >In line with the mechanism provided for under the DMCA, explained
        above, we will also share with the takedown request claimant or the
        counter-notification claimant the following personal data to comply with
        and follow the prescribed steps under the DMCA: your name, your contact
        information, as well as the contents of your takedown request or your
        counter-notification. We rely on your consent to transmit your personal
        data to the addressees of your takedown request or
        counter-notifications. By submitting a copyright infringement takedown
        request, you consent to your data being transmitted to the uploader of
        the material in question in line with and for the reasons explained
        above and detailed in our Terms of Service. Without your consent, we are
        unable to process your takedown request or counter-notifications as
        prescribed under the DMCA.</span
      >
    </p>
    <h4 class="c8" id="h.6dvf653mn2as">
      <span class="c4"
        >2.3 Provide, Personalize, Measure, and Improve our Advertising and
        Marketing.</span
      >
    </h4>
    <ul class="c18 lst-kix_sjgy2pv3detw-0 start">
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Send you promotional messages, marketing, advertising, and other
          information that may be of interest to you based on your preferences
          (including information about Website).</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Personalize, measure, and improve our advertising based on your
          characteristics and preferences (based on the information you provide
          to us, your interactions with the Website, information obtained from
          third parties, and your search and booking history) to send you
          promotional messages, marketing, advertising and other information
          that we think may be of interest to you.</span
        >
      </li>
    </ul>
    <p class="c0">
      <span class="c3"
        >Lawful bases: we rely on the following two lawful bases to process your
        personal data:</span
      >
    </p>
    <ol class="c18 lst-kix_qiwefpe0wide-0 start" start="1">
      <li class="c2 li-bullet-0">
        <span class="c3"
          >We process your personal information for the purposes listed in this
          section, given our legitimate interest in undertaking marketing
          activities to offer you products or services that may be of your
          interest. We consider the processing of your personal data to be
          proportionate with your interests, rights and freedoms.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >To the extent necessary, we will also rely on your consent to process
          special categories of data in undertaking marketing activities and
          personalising the content on the Website.</span
        >
      </li>
    </ol>
    <p class="c0">
      <span class="c3"
        >You can opt out of receiving marketing communications from us by
        following the unsubscribe instructions included in our marketing
        communications or changing your notification settings within your
        account.</span
      >
    </p>
    <h4 class="c8" id="h.dsbsl4eo6e35">
      <span class="c4"
        >2.4 Your direct contact with us through our Website or by post, phone,
        email or otherwise</span
      >
    </h4>
    <ul class="c18 lst-kix_qpqsnxqp8pr3-0 start">
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Respond to your queries regarding service or your complaints.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Provide you with further information on privacy policies.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Any other queries you may have for which you contact us.</span
        >
      </li>
    </ul>
    <p class="c0">
      <span class="c3"
        >Lawful bases: when you contact us directly through our Website or by
        post, phone, email or otherwise, including for the purposes of
        exercising your rights under the GDPR or to file any complaints, we will
        rely on the following two bases to process your personal data:</span
      >
    </p>
    <ol class="c18 lst-kix_y0vg4ywjy0o5-0 start" start="1">
      <li class="c2 li-bullet-0">
        <span class="c3"
          >We will rely on our legitimate interests as a customer-friendly
          entertainment business that wants to improve the experience of the
          customers using our Website, to resolve satisfactorily any complaints
          made by our customers, or to respond to any requests for further
          information. We will process your personal data we deem necessary for
          these legitimate interests. Additionally, in rare cases where we
          reasonably determine that your direct communications with us or our
          staff are or become abusive, amount to harassment or otherwise illegal
          under the applicable laws, we may also process your personal data for
          these purposes and pass it onto the relevant governmental authorities.
          We consider the processing of your personal data to be proportionate
          with your interests, rights and freedoms.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >We may also need to rely on our legal obligations to process your
          personal data in cases where the applicable law compels us to
          communicate your personal data exchanged in direct communications with
          us to the responsible governmental authorities.</span
        >
      </li>
    </ol>
    <h3 class="c1" id="h.jyd6rh870thv">
      <span class="c5">3. COOKIES POLICY AND GOOGLE ANALYTICS</span>
    </h3>
    <p class="c0">
      <span class="c7"
        >Our Website uses session cookies and persistent cookies. To learn more
        about cookies, please visit our cookies policy, available at </span
      ><span class="c16 c7"
        ><a
          class="c9"
          [href]="
            'https://www.google.com/url?q=' +
            environment.policy.docsBaseUrl +
            '/legal/cookiepolicy&amp;sa=D&amp;source=editors&amp;ust=1635513749136000&amp;usg=AOvVaw1SjqiAS5Q4EMNv00t-Tew_'
          "
          >{{ environment.policy.docsBaseUrl }}/legal/cookiepolicy</a
        ></span
      ><span class="c3"
        >, which explains how you can manage your preferences.</span
      >
    </p>
    <h3 class="c1" id="h.b9cbc277twqm"><span class="c5">4. SECURITY</span></h3>
    <p class="c0">
      <span class="c3"
        >Note that all data is kept in a secure environment. We protect personal
        data by using appropriate safeguard procedures and measures, in
        particular:</span
      >
    </p>
    <ul class="c18 lst-kix_7nwptbtp7epe-0 start">
      <li class="c2 li-bullet-0">
        <span class="c7"
          >We encrypt our services using SSL (you can reach our </span
        ><span class="c7 c17"
          ><a
            class="c9"
            href="https://www.google.com/url?q=https://support.google.com/websearch/answer/173733?hl%3Den-GB&amp;sa=D&amp;source=editors&amp;ust=1635513749138000&amp;usg=AOvVaw1BbkNds8npgwUyhoGEswQ-"
            >SSL</a
          ></span
        ><span class="c7">&nbsp;protected website under </span
        ><span class="c16 c7"
          ><a
            class="c9"
            [href]="
              'https://www.google.com/url?q=' +
              environment.webSiteUrl +
              '/&amp;sa=D&amp;source=editors&amp;ust=1635513749138000&amp;usg=AOvVaw1jA7l-ceRizcVEJLi4BoyN'
            "
            >{{ environment.webSiteUrl }}</a
          ></span
        ><span class="c3">).</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3">We use firewalls to protect your information.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >We review our information collection, storage and processing
          practices regularly, including physical security measures, to guard
          against unauthorised access to systems.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >We restrict access to personal information to employees, contractors
          and agents who need to know that information in order to process it
          for us and who are subject to strict contractual or other legal
          confidentiality obligations.</span
        >
      </li>
    </ul>
    <p class="c0">
      <span class="c7"
        >If you know or have reason to believe that your account credentials
        have been lost, stolen, misappropriated, or otherwise compromised or in
        case of any actual or suspected unauthorized use of your account, </span
      ><span class="c7"
        >please contact us by email {{ environment.policy.email }}</span
      >
    </p>
    <h3 class="c1" id="h.8b8nvp4upknl">
      <span class="c5">5. YOUR RIGHTS</span>
    </h3>
    <p class="c0">
      <span class="c3"
        >You may exercise any of the rights described in this section by
        adjusting settings in your user account and, if it is not possible, you
        can contact us at our above-mentioned address. Please note that we will
        ask you to verify your identity before taking further action on your
        request, and if we cannot identify you, we cannot act according to your
        request.</span
      >
    </p>
    <h4 class="c8" id="h.rtu8gmcu38k8">
      <span class="c4">5.1 Managing Your Information.</span>
    </h4>
    <p class="c0">
      <span class="c3"
        >You may access and update some of your information through your account
        settings. You are responsible for keeping your personal information
        up-to-date in a secure place.</span
      >
    </p>
    <h4 class="c8" id="h.8v2pf0jpstvy">
      <span class="c4"
        >5.2 Rectification of Inaccurate or Incomplete Information.</span
      >
    </h4>
    <p class="c0">
      <span class="c3"
        >You have the right to ask us to correct inaccurate or incomplete
        personal information concerning you (and which you cannot update
        yourself within your account) by contacting us at the address listed in
        the Contact Us section below.</span
      >
    </p>
    <h4 class="c8" id="h.ypm40kmzqnn9">
      <span class="c4"
        >5.3 Withdrawing Consent and Restriction of Processing.</span
      >
    </h4>
    <p class="c0">
      <span class="c3"
        >Where you have provided your consent to the processing of your personal
        information by us, you may withdraw your consent at any time by changing
        your account settings. The withdrawal of your consent does not affect
        the lawfulness of any processing activities based on such consent before
        its withdrawal.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >Additionally, you have the right to limit the ways in which we use your
        personal information, in particular where (i) you contest the accuracy
        of your personal information; (ii) the processing is unlawful, and you
        oppose the erasure of your personal information; (iii) we no longer need
        your personal information for the purposes of the processing, but you
        require the information for the establishment, exercise or defence of
        legal claims; or (iv) you have objected to the processing pursuant to
        Section 5.6 and pending the verification whether the legitimate grounds
        of the Website provider override your own.</span
      >
    </p>
    <h4 class="c8" id="h.yxyiarxlntwy">
      <span class="c4">5.4 Objection to Processing.</span>
    </h4>
    <p class="c0">
      <span class="c3"
        >You have a right to require us not to process your personal information
        for certain specific purposes (including profiling) where such
        processing is based on legitimate interest. If you object to such
        processing, we will no longer process your personal information for
        these purposes unless we can demonstrate compelling legitimate grounds
        for such processing or such processing is required for the
        establishment, exercise or defence of legal claims.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >Where your sensitive personal data are processed you may, at any time,
        ask us to cease processing your data by changing your user&rsquo;s
        profile settings.</span
      >
    </p>
    <h4 class="c8" id="h.ielyx6v1ymnp">
      <span class="c4">5.5 Lodging Complaints.</span>
    </h4>
    <p class="c0">
      <span class="c7"
        >You have the right to file a complaint about the data processing
        activities carried out before the competent data protection authorities
        either in your country of residence if that country is a member of the
        EEA or with the </span
      ><span class="c12 c7">Cyprus</span
      ><span class="c7"
        >&nbsp;Data Protection Office, where GETHINS LIMITED is headquartered.
        More information about the </span
      ><span class="c12 c7">Cyprus</span
      ><span class="c7">&nbsp;Data Protection Office is available at </span
      ><span class="c7 c11"
        ><a
          class="c9"
          href="https://www.google.com/url?q=http://www.dataprotection.gov.cy&amp;sa=D&amp;source=editors&amp;ust=1635513749143000&amp;usg=AOvVaw2KxIPISa5jXkB1xFC6Jthr"
          >http://www.dataprotection.gov.cy</a
        ></span
      >
    </p>
    <h3 class="c1" id="h.l1253fgpnoh9">
      <span class="c5">6. OPERATING GLOBALLY</span>
    </h3>
    <p class="c0">
      <span class="c3"
        >Where we transfer, store and process your personal data outside the EEA
        we have ensured that appropriate safeguards are in place to ensure an
        adequate level of data protection. We rely on EU Model Clauses and
        adequacy decisions issued by competent European authorities. If we are
        unable to rely on these two bases, we will transfer data outside the EEA
        to the extent doing to do is: (a) necessary for the performance of a
        contract between you and us; (b) necessary for the conclusion or
        performance of a contract concluded for your interest between us and
        another natural or legal person; (c) necessary for the establishment,
        exercise or defence of legal claims; or (d) any other bases listed in
        Article 49 of the GDPR if applicable, such as seeking your
        consent.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >We share certain data within our corporate group of companies and with
        the following groups of recipients to the extent we deem it necessary to
        be able to perform our operations or are compelled to do by law:</span
      >
    </p>
    <ul class="c18 lst-kix_u5yk6srjd92s-0 start">
      <li class="c2 li-bullet-0">
        <span class="c3">Government authorities;</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3">Third party service providers;</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3">Financial institutions;</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3">Providers of storage systems;</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3">IT services providers;</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c3"
          >Providers of services necessary for the performance of our activity
          (administrative activities, archiving, legal advice, receivables
          management, etc.).</span
        >
      </li>
    </ul>
    <h3 class="c1" id="h.djufcqr048l">
      <span class="c5">7. CHANGES TO THIS PRIVACY POLICY</span>
    </h3>
    <p class="c0">
      <span class="c3"
        >We reserve the right to modify this Privacy Policy at any time in
        accordance with this provision. If we make changes to this Privacy
        Policy, we will post the revised Privacy Policy on the Website and
        update the &ldquo;Last Updated&rdquo; date at the top of this Privacy
        Policy. For the individuals who have created an account on or through
        this Website (i.e., those individuals who fall within the Category 2,
        Category 3, or Category 4 of users listed on page 1 of this privacy
        policy), we will also send you an email to inform you of any changes. If
        you disagree with the revised privacy policy, you may cancel your
        account. If you do not cancel your account before the date the revised
        privacy policy becomes effective, your continued access to or use of the
        Website will be subject to the revised privacy policy.</span
      >
    </p>
    <h3 class="c1" id="h.v24dqykytmab">
      <span class="c5">8. CONTACT US</span>
    </h3>
    <p class="c0"><span class="c3">You may contact us at</span></p>
    <p class="c14">
      <span class="c12 c7 c13"
        >GETHINS LIMITED Cyprus a.s. Att. of Data Privacy Officer</span
      >
    </p>
    <p class="c14">
      <span class="c13 c12 c7"
        >Postal address:ONEWORLD PARKVIEW HOUSE, Floor 4, 75 Prodromou Strovolos
        , 2063 Nicosia, Cyprus</span
      >
    </p>
    <p class="c14">
      <span class="c12 c7">Email address: </span
      ><span class="c16 c7"
        ><a class="c9" [href]="'mailto:' + environment.policy.email">{{
          environment.policy.email
        }}</a></span
      ><span class="c12 c7"><br /><br /><br /></span
      ><span class="c7">Last Updated: 01/10/2021</span>
    </p>
    <p class="c0 c15"><span class="c3"></span></p>
    <p class="c15 c19"><span class="c13 c25"></span></p>
  </div>
</div>
