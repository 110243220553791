<ng-container>
  <div class="gallery-grid">
    <ng-container>
      <div
        class="gallery-grid-item"
        [class.col-block-off]="!item?.isLoaded"
        [class.gallery-grid-item-locked]="item === null"
        *ngFor="let item of this.contentData; let i = index; let last = last"
        (click)="openFullScreen(this.contentData, i)"
      >
        <ng-container *ngIf="item !== null; else lockedItemShow">
          <img
            [attr.src]="item.url"
            class="element"
            (load)="chenchLoaded(i)"
            #element
            alt=""
            *ngIf="item.contentType === 'Image'"
          />
          <video
            preload="metadata"
            [attr.src]="item.url + '#t=0.001'"
            class="element"
            (loadeddata)="videoLoaded($event, i)"
            [attr.id]="'video' + i"
            #element
            *ngIf="item.contentType === 'Video'"
            (load)="videoLoaded($event, i)"
          ></video>
          <button class="video-play" *ngIf="item.contentType === 'Video'">
            <span class="btn-play"></span>
            <span *ngIf="durations[i]">{{
              this.timeConverter.toMinSec(durations[i])
            }}</span>
          </button>
        </ng-container>

        <ng-template #lockedItemShow>
          <div class="locked-item">
            <img src="/assets/img/lock.svg" alt="" class="locked-icon" />
          </div>
          <div class="photo-count-fixed-block" *ngIf="last">
            <img src="assets/img/cards-icon-gray.svg" />
            +{{ gallerySize - albumData?.countContent }}
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-container>

<swiper
  *ngIf="false"
  [slidesPerView]="4"
  [spaceBetween]="10"
  [centeredSlides]="true"
  class="mySwiper"
>
  <ng-template swiperSlide *ngFor="let item of this.contentData; let i = index">
    <div
      class="col-block"
      [class.col-block-off]="!item.isLoaded"
      (click)="openFullScreen(this.contentData, i)"
    >
      <div
        [style.background-image]="'url(' + item.url + ')'"
        class="element image-as-bg"
        *ngIf="item.contentType !== 'Video'"
      ></div>
      <img
        style="display: none"
        [attr.src]="item.url"
        class="element"
        (load)="chenchLoaded(i)"
        #element
        alt=""
        *ngIf="item.contentType === 'Image'"
      />
      <div class="slider-video-container">
        <video
          preload="metadata"
          [attr.src]="item.url + '#t=0.001'"
          class="element"
          (loadeddata)="videoLoaded($event, i)"
          [attr.id]="'video' + i"
          #element
          *ngIf="item.contentType === 'Video'"
          (load)="videoLoaded($event, i)"
        ></video>
      </div>
    </div>
  </ng-template>
</swiper>
