import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { NewMessageService } from './../../services/comunication_services/new-message.service';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NextMessageEmitterService } from '../../services/comunication_services/nextMessageEmitter.service';
import { TutorialService } from '../../services/comunication_services/tutorial.service';
import { filter } from 'rxjs/operators';
import { NavHelper } from '../../helpers';
import { animate, style, transition, trigger } from '@angular/animations';
import { AnimationOptions } from 'ngx-lottie';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1s ease-out', style({ height: 300, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 300, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class TutorialComponent implements OnInit, OnDestroy {
  @Input() parent: any;
  @ViewChild('finger') finger: ElementRef;
  tutorialShowSub: Subscription;
  stepSub: Subscription;
  isShowTipsSub: Subscription;
  showTutorial = true;
  isShowTips = true;
  step = null;
  currentRoute: any;
  appName = environment.appName;
  handTutorialAnimationOptions: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/hand-tutorial.json`,
    loop: true,
  };
  environment = environment;
  constructor(
    private _tutorialService: TutorialService,
    private nextMessageEmitterService: NextMessageEmitterService,
    private _newMessageService: NewMessageService,
    private _navHelper: NavHelper,
    private _renderer: Renderer2,
    private _analyticsService: AnalyticsService,
    private _router: Router
  ) {
    // If click outside the finger - show finger
    this._renderer.listen('window', 'click', (e: Event) => {
      if (!this.finger?.nativeElement.contains(e.target)) {
        this._tutorialService.showTips();
      }
    });

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.currentRoute = event;
      });
  }

  ngOnInit() {
    this.tutorialShowSub = this._tutorialService.showTutorial
      .pipe(filter((res) => res !== null))
      .subscribe((res) => {
        this.showTutorial = res;
      });
    this.stepSub = this._tutorialService.step$?.subscribe((step) => {
      this.step = step || null;
    });
    this.isShowTipsSub = this._tutorialService.isShowTips$?.subscribe(
      (action) => {
        this.isShowTips = action;
      }
    );
  }

  ngOnDestroy() {
    this.tutorialShowSub?.unsubscribe();
    this.stepSub?.unsubscribe();
    this.isShowTipsSub?.unsubscribe();
  }

  nextAction() {
    const isLast = this.step === 16; // Hadcoded. TODO refactor
    this._analyticsService.tutorial(this.step, isLast);
    switch (this.step) {
      case 1:
      case 2:
      case 6:
      case 8:
        const tutorialChatId = localStorage?.getItem('tutorialChatId');
        if (
          !tutorialChatId ||
          (tutorialChatId &&
            this.currentRoute?.url !== `/allChats/${tutorialChatId}`)
        ) {
          this._tutorialService.reInit();
        } else {
          this._tutorialService.showTips();
          // this.nextMessageEmitterService.changeData('tutorial');
          // this.parent.clickAnswer(this.parent.answers[0]);
        }

        break;
      case 3:
        if (this.parent.openFullScreen) {
          this.parent.openFullScreen(
            this.parent.messages[this.parent.messages.length - 2],
            'Image'
          );
          this._tutorialService.nextStep(5);
        } else {
          this._tutorialService.reInit();
        }
        break;
      // case 4:
      //   if(this.parent.tutorialBuyAction){
      //     this.parent.tutorialBuyAction.next(true);
      //     this._tutorialService.nextStep(5);
      //   } else {
      //     this._tutorialService.reInit();
      //   }
      //   break;
      case 5:
        if (this.parent.dismissModal) {
          this.parent.dismissModal();
          this._tutorialService.showTips();
          this._newMessageService.toggleState(true);
          this._tutorialService.nextStep(6);
        } else {
          this._tutorialService.reInit();
        }
        break;
      case 7:
        if (this.parent.tutorialBuyOfflineAction) {
          this.parent.tutorialBuyOfflineAction.next(true);
          this._tutorialService.showTips();
          this._tutorialService.nextStep(8);
        } else {
          this._tutorialService.reInit();
        }
        break;
      case 9:
        if (this.parent.tutorialAdditionalContentBuyAction) {
          this.parent.tutorialAdditionalContentBuyAction.next(true);
          this._tutorialService.showTips();
          this._tutorialService.nextStep(10);
        } else {
          this._tutorialService.reInit();
        }
        break;
      case 10:
        this._tutorialService.nextStep(11);
        break;
      case 11:
        if (this.parent.tutorialAdditionalContentBuyAction) {
          this.parent.tutorialAdditionalContentBuyAction.next(true);
          this._navHelper.goToMain();
          this._tutorialService.nextStep(12);
        } else {
          this._tutorialService.reInit();
        }
        break;
      case 12:
        this._navHelper.goToBonusDailyReweards();
        this._tutorialService.nextStep(13);
        break;
      case 13:
        if (this.parent.openPopUp) {
          this.parent.openPopUp();
          this._tutorialService.nextStep(14);
        } else {
          this._tutorialService.reInit();
        }
        break;
      case 14:
        if (this.parent.collectBonus) {
          this.parent.collectBonus();
          this._tutorialService.nextStep(15);
        } else {
          this._tutorialService.reInit();
        }
        break;
      case 15:
        this._navHelper.goToShopChats();
        this._tutorialService.nextStep(16);
        break;
      case 16:
        if (this.parent.openDetails) {
          this.parent.openDetails('584cc35b-8a0f-4171-9258-14575027af77');
          this._tutorialService.finishTutorial();
        } else {
          this._tutorialService.reInit();
        }
        break;
    }
  }

  onFingerTap() {
    this.nextAction();
  }
}
