import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ItemType } from 'src/app/shared/enums/item-type.enum';
import {
  OfferData,
  OfferDataNew,
} from 'src/app/shared/types/offer-data.interface';

@Component({
  selector: 'app-big-pack-offer',
  templateUrl: './big-pack.component.html',
  styleUrls: ['./big-pack.component.scss'],
})
export class BigPackComponent implements OnInit {
  @Input() public offerData: OfferDataNew;
  @Output() public closeModal: EventEmitter<boolean> = new EventEmitter(false);

  public itemNames: string[] = [];

  constructor() {}

  public ngOnInit() {
    this.setItemsNames();
  }

  public close(e: Event) {
    e.stopPropagation();
    this.closeModal.emit(true);
  }

  private setItemsNames() {
    this.offerData.items.forEach((item) => {
      switch (item.type) {
        case ItemType.gem:
          this.itemNames.push(`${item.count} Tokens`);
          break;
        case ItemType.swipe:
          this.itemNames.push(`Unlock ${item.count}\u00A0swipes`);
          break;
        case ItemType.randomGift:
          this.itemNames.push(
            `${item.count} ${
              item.count === 1 ? 'gift for girl' : 'gifts for girls'
            }`
          );
          break;
        case ItemType.skipOffline:
          this.itemNames.push(
            `${item.count} ${
              item.count === 1 ? 'skip offline' : 'skips offline'
            }`
          );
          break;
        default:
          this.itemNames.push(`${item.count} ${item.localizedName.en}`);
          break;
      }
    });
  }
}
