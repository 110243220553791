import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-spring-break-big-offer',
  templateUrl: './spring-break-big.component.html',
  styleUrls: ['./spring-break-big.component.scss'],
})
export class SpringBreakBigOfferComponent implements OnInit {
  @Input() offerData: OfferData;
  @Input() appearanceType: 'modal' | 'banner' | 'smallBanner';
  @Input() purchased: boolean;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);

  public buildVersion = BuildVersion;
  public environment = environment;

  constructor() {}

  ngOnInit() {}

  close() {
    this.closeModal.emit(true);
  }
}
