import { ResetPasswordRequestData } from './../../../types/ResetPasswordRequestData';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormClassNameEnum } from 'src/app/shared/enums/form-class-name.enum';
import { FormFieldTypeEnum } from 'src/app/shared/enums/form-field-type.enum';
import { FormField } from 'src/app/shared/types/form-field.interface';
import { RegistrationHelperService } from '../registration-helper.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NavHelper } from 'src/app/shared/helpers';
import { AuthApiService } from 'src/app/shared/services/auth/auth-api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  @Output() signInButton = new EventEmitter();
  public form: UntypedFormGroup;
  isLoggedin: boolean;
  step: 1 | 2 = 1;
  serverErrorsModel = {};
  serverErrors = {};
  forgotLoading = false;
  loading = false;
  forgotSubscription: Subscription = null;
  resetSubscription: Subscription = null;
  commonServerErrors: string[] = [];
  formArray: FormField[] = [
    {
      className: FormClassNameEnum.formGroup,
      fieldType: FormFieldTypeEnum.email,
      placeHolder: this._translateService.instant(
        'MODALS.LOGIN.EMAIL_PLACEHOLDER'
      ),
      controlName: 'email',
      validators: {
        required: true,
        email: true,
      },
    },
    {
      className: FormClassNameEnum.formGroup,
      fieldType: FormFieldTypeEnum.text,
      placeHolder: this._translateService.instant(
        'MODALS.LOGIN.FORGOT_PASSWORD_BLOCK.CODE_PLACEHOLDER'
      ),
      controlName: 'code',
      validators: {
        required: true,
      },
    },
    {
      className: FormClassNameEnum.formGroup,
      fieldType: FormFieldTypeEnum.password,
      placeHolder: this._translateService.instant(
        'MODALS.LOGIN.FORGOT_PASSWORD_BLOCK.NEW_PASSWORD_PLACEHOLDER'
      ),
      controlName: 'password',
      validators: {
        required: true,
      },
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _authApiService: AuthApiService,
    public registrationHelperService: RegistrationHelperService,
    private _navHelper: NavHelper,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.formArray.forEach((item: FormField) => {
      const validators = this.registrationHelperService.setValidators(
        item.validators
      );
      this.form.addControl(
        item.controlName,
        this.formBuilder.control('', validators)
      );
      this.serverErrorsModel[item.controlName] = [];
      this.serverErrors[item.controlName] = [];
    });
  }

  changeStep(step: 'prev' | 'next') {
    if (step === 'prev') {
      this.step = 1;
    } else if (step === 'next') {
      this.step = 2;
    }
  }

  getCode(invalid: boolean) {
    if (!invalid) {
      this.forgotLoading = true;
      this.forgotSubscription = this._authApiService
        .forgotPassword(this.form.value.email)
        .pipe(filter((res) => res))
        .subscribe(
          (res) => {
            this.changeStep('next');
            this.forgotLoading = false;
          },
          (err) => {
            this.commonServerErrors =
              this.registrationHelperService.setServerErrors(err, this.form);
            this.forgotLoading = false;
          }
        );
    }
  }

  public clearServerErrors() {
    this.serverErrors = { ...this.serverErrorsModel };
  }

  public resetPassword(): void {
    this.loading = true;
    const { form } = this;
    const { value } = form;
    if (form.valid) {
      const data: ResetPasswordRequestData = {
        email: value.email,
        password: value.password,
        code: value.code,
      };
      this.resetSubscription = this._authApiService
        .resetPassword(data)
        .subscribe(
          (res) => {
            this.loading = false;
            this._navHelper.goToMain();
          },
          (err) => {
            this.commonServerErrors =
              this.registrationHelperService.setServerErrors(err, this.form);
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      Object.keys(form.controls).forEach((field) => {
        const control = form.controls[field];
        if (!control.valid || control.value.lenght) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  public goToSignIn(): void {
    this.signInButton.emit();
  }

  ngOnDestroy() {
    this.forgotSubscription?.unsubscribe();
    this.resetSubscription?.unsubscribe();
  }
}
