import { Platform } from '@ionic/angular';
import { UserDataService } from './../comunication_services/userData.service';
import { ErogamesPaymentService } from './../payments/erogames-payment.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics/analytics.service';
import {
  RealPurchaseData,
  SoftPurchaseData,
} from '../../types/purchase-data.interface';
import { WebPaymentService } from './web-payment.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { NutakuPaymentService } from './nutaku-payment.service';
import { PaymentMethod } from '../../enums/payment-method.enum';
import { LegalPaymentService } from './legal-payment.service';
import { SoftPaymentsService } from './soft-payments.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(
    private _analyticsService: AnalyticsService,
    private _erogamesPaymentService: ErogamesPaymentService,
    private _webPaymentService: WebPaymentService,
    private _nutakuPaymentService: NutakuPaymentService,
    private _legalPaymentService: LegalPaymentService,
    private _softPaymentService: SoftPaymentsService,
    private _userDataService: UserDataService,
    private _platform: Platform
  ) {}

  purchase(data: RealPurchaseData | SoftPurchaseData): Observable<boolean> {
    console.log('payments service Purchase method', data);

    if (data.purchase) {
      return this.realPurchase(data as RealPurchaseData);
    } else {
      return this.softPurchase(data as SoftPurchaseData);
    }
  }

  private realPurchase(data: RealPurchaseData): Observable<boolean> {
    this._analyticsService.clickOnPurchase(
      data.source,
      data.purchase.productId,
      '',
      data.source,
      data.purchase.offerType
    );

    switch (environment.buildVersion) {
      case BuildVersion.erogames:
        return this._erogamesPaymentService.purchase(data);
      case BuildVersion.nutaku:
        if (this._userDataService.nutakuDisplayVersion === 'pc') {
          return this._nutakuPaymentService.purchase(data); // PC version
        }
        if (this._platform.is('hybrid')) {
          return this._webPaymentService.purchase(
            data,
            PaymentMethod.nutakuClient
          ); // APK version
        }
        return this._webPaymentService.purchase(data, PaymentMethod.nutakuSp); // // SP version
      case BuildVersion.legal:
        return this._legalPaymentService.purchase(data);
      default:
        return this._webPaymentService.purchase(data);
    }
  }

  private softPurchase(data: SoftPurchaseData): Observable<boolean> {
    return this._softPaymentService.purchase(data);
  }
}
