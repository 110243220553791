import { Language } from '../enums/language.enum';

export enum ServerPlatform {
  gmn = 'GMN',
  erogames = 'Erogames',
  nutaku = 'Nutaku',
}

export enum NotificationType {
  common = 'Common',
  pendingReward = 'PendingReward',
  questCompleted = 'QuestCompleted',
  text = 'Text',
  paymentAuth = 'PaymentAuth',
}

export enum SubscriptionStatus {
  none = 'None',
  active = 'Active',
  expired = 'Expired',
}

export interface UserNotification {
  id: string;
  notificationType: NotificationType;
  pendingReward: any;
  text: string;
  title?: string;
}
export interface UserMyResponse {
  id: string;
  // firstName: string;
  // lastName: string;
  // fullName: string;
  nickName: string;
  avatar: string;
  coins: number;
  isGuest: boolean;
  isNickNameSet: boolean;
  sound: boolean;
  music: boolean;
  notifications?: UserNotification[];
  pushNotifications?: boolean;
  email: string;
  tutorialStep: number;
  tutorialIsPassed: boolean;
  tutorialChatId?: string;
  serverPlatform: ServerPlatform;
  ve: number;
  vd: number;
  vn: number;
  vg: number;
  va: number;
  splitTests?: string[];
  quickMessaging: boolean;
  onlineStreamNotifications: boolean;
  subscriptionStatus: SubscriptionStatus;
  lang: Language | null;
  isAgeConfirmed: boolean;
  isAgreementAccepted: boolean;
  config: {
    serverLogs: boolean;
    paywallShowOnStart: boolean;
    paywallShowOnCards: boolean;
    requiredPremium: boolean;
  };
}
