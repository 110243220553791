import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const defaultTime = '00:00';

@Injectable({
  providedIn: 'root',
})
export class NextBonusTimeService {
  private data = {
    str: defaultTime,
    numb: null,
  };
  private defaultData = {
    str: defaultTime,
    numb: null,
  };
  private time = 0;
  private timer: any;
  public updateNextBonusTime = new BehaviorSubject(this.data);

  constructor() {
    setTimeout(() => {
      const t = this.updateNextBonusTime.getValue();
      if (t.str === defaultTime) {
        this.updateNextBonusTime.next(this.data);
      }
    }, 4000);
  }

  public changeObject(value: any): void {
    const newTime = {
      str: value,
      numb: 1,
    };
    this.updateNextBonusTime.next(value);
  }

  public changeTime(value: number): void {
    this.time = value / 60000;
    this.checkTime();
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.time -= 1;
      if (this.time < 0) {
        clearInterval(this.timer);
      }
      this.checkTime();
    }, 60000);
  }

  public setDefaultData(): void {
    this.updateNextBonusTime.next(this.defaultData);
  }

  private checkTime() {
    const h = Math.floor(this.time / 60);
    const m = Math.floor(this.time % 60);
    const strH = h > 9 ? `${h}` : `0${h}`;
    const strm = m > 9 ? `${m}` : `0${m}`;
    const strTime = `${strH}:${strm}`;
    if (this.time > 0) {
      this.changeObject({
        str: strTime,
        numb: this.time,
      });
    } else {
      this.changeObject({
        str: defaultTime,
        numb: 0,
      });
    }
  }
}
