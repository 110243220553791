import { PaymentsCommonMethodsService } from './payments-common-methods.service';
import { PaymentAnimationStates } from 'src/app/shared/enums/payment-animation-states';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics/analytics.service';
import { PaymentsStateService } from './payments-state.service';
import { PaymentResponse } from '../../types/payment-response.interface';
import { ShopService } from '../API_services/shop.service';
import { RealPurchaseData } from '../../types/purchase-data.interface';
import { PaymentResponeStatus } from '../../enums/payment-respone-status.enum';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';
export interface NutakuPaymentResponse {
  nutakuId: string;
  isOk: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NutakuPaymentService {
  private _host: string = environment.apiUrl;
  constructor(
    private _http: HttpClient,
    private _analyticsService: AnalyticsService,
    private _paymentsStateService: PaymentsStateService,
    private _shopService: ShopService,
    private _paymentsCommonMethodsService: PaymentsCommonMethodsService
  ) {}

  purchase(data: RealPurchaseData): Observable<boolean> {
    return new Observable((observer) => {
      this._paymentsStateService.paymentState = PaymentAnimationStates.progress;

      window.parent.postMessage(
        JSON.stringify({
          action: 'payment',
          data,
        }),
        environment.nutakuConfig?.parentUrl
      );

      window.addEventListener(
        'message',
        (res) => {
          const result = JSON.parse(res?.data);

          if (result?.action === 'payment') {
            observer.next({
              nutakuId: result?.data?.nutakuId || '',
              isOk: result.isOk || false,
            });
          }
        },
        false
      );
    }).pipe(
      switchMap((res: NutakuPaymentResponse) => {
        if (res.isOk) {
          this._paymentsStateService.paymentState = null;
          return this._afterPurchase(data.source, res.nutakuId, data.chatId);
        } else {
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
          return of(false);
        }
      })
    );
  }

  private _afterPurchase(
    source: PurchaseItemType,
    nutakuId: string,
    chatId = ''
  ): Observable<boolean> {
    return this._nutakuBuyRequest(source, nutakuId, chatId);
  }

  private _nutakuBuyRequest(
    source: PurchaseItemType,
    nutakuId: string,
    chatId = ''
  ): Observable<boolean> {
    const requestUrl = `${this._host}/api/Shop/nutaku-buy`;

    return this._http
      .post<PaymentResponse>(requestUrl, {
        nutakuId,
        source,
        chatId,
      })
      .pipe(map((res) => this._nutakuBuyHandler(res, nutakuId)));
  }

  private _nutakuBuyHandler(res: PaymentResponse, nutakuId: string): boolean {
    const idForAnalytic = nutakuId;
    if (
      res.payment.status === PaymentResponeStatus.error ||
      res.payment.status === PaymentResponeStatus.cancelled
    ) {
      this._paymentsStateService.paymentState = PaymentAnimationStates.error;
      this._analyticsService.paymentError(
        res?.payment.platform,
        idForAnalytic,
        res?.payment?.productId
      );

      return false;
    } else if (res.payment.status === PaymentResponeStatus.success) {
      this._paymentsStateService.paymentState = PaymentAnimationStates.success;
      this._analyticsService.payment(
        res?.payment.platform,
        idForAnalytic,
        res.payment.cost,
        res?.payment?.productId,
        res?.payment?.lotType,
        res?.payment?.source,
        res?.payment?.meta,
        res?.payment?.offerType,
        res?.payment?.purchaseInfo,
        res?.payment?.status
      );
      this._shopService.saveDiamonds(res.coins);
      return true;
    } else if (res.payment.status === PaymentResponeStatus.issued) {
      // Retry retry(2),
    }

    return false;
  }

  /*
   * Using from WebPayments Service for APK Nutaku handling
   */
  public handleNutakuPurchase(res): Observable<boolean> {
    if (res.payment.status === PaymentResponeStatus.error) {
      return of(false);
    } else {
      return from(
        this._paymentsCommonMethodsService.showConfirmationModal(
          res.nutakuAmount
        )
      ).pipe(
        switchMap((isSubmited) => {
          if (isSubmited.data) {
            return this.nutakuConfirmPayment(res.payment.nutakuPaymentId).pipe(
              map((confirmNutakuRes) => {
                switch (confirmNutakuRes.payment.status) {
                  case PaymentResponeStatus.insufficientFunds:
                    this._paymentsCommonMethodsService.showNoFundsModal();
                    return false;
                  case PaymentResponeStatus.success:
                  case PaymentResponeStatus.issued:
                  case PaymentResponeStatus.completed:
                    this._paymentsCommonMethodsService.checkTransaction();
                    return true;
                  default:
                    return false;
                }
              })
            );
          } else {
            return of(false);
          }
        })
      );
    }
  }

  nutakuConfirmPayment(nutakuId: string): Observable<PaymentResponse> {
    const requestUrl = `${this._host}/api/Shop/approve-nutaku-buy`;
    return this._http
      .post<PaymentResponse>(requestUrl, { nutakuId })
      .pipe(take(1));
  }
}
