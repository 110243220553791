<ion-footer class="ion-no-border">
  <ul
    class="tab-nav-new"
    *ngIf="this.state !== 'chat' && visibleMenuButtons.length"
  >
    <li
      *ngFor="let menuButton of visibleMenuButtons"
      [class.selected]="menuButton === selectedMenuButton"
      [class.bounce]="
        menuButton !== selectedMenuButton &&
        menuButton === uiStates.bonus &&
        hasBonusRewards
      "
      [class.disabled]="
        (tutorialStep | async) !== null && menuButton !== selectedMenuButton
      "
    >
      <button (click)="goTo(menuButton)">
        <div class="icon">
          <ng-container *ngIf="menuButton !== selectedMenuButton">
            <svg-icon
              *ngIf="menuButton === uiStates.gallery"
              [src]="environment.rootPath + '/assets/img/navbar/gallery.svg'"
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.bonus"
              [src]="environment.rootPath + '/assets/img/navbar/quests.svg'"
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.chat"
              [src]="environment.rootPath + '/assets/img/navbar/chats.svg'"
              class="heart"
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.swipe"
              [src]="environment.rootPath + '/assets/img/navbar/swipe.svg'"
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.shop"
              [src]="environment.rootPath + '/assets/img/navbar/shop.svg'"
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.settings"
              [src]="environment.rootPath + '/assets/img/navbar/profile.svg'"
            ></svg-icon>
          </ng-container>
          <ng-container *ngIf="menuButton === selectedMenuButton">
            <svg-icon
              *ngIf="menuButton === uiStates.gallery"
              [src]="
                environment.rootPath + '/assets/img/navbar/gallery-active.svg'
              "
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.bonus"
              [src]="
                environment.rootPath + '/assets/img/navbar/quests-active.svg'
              "
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.chat"
              [src]="
                environment.rootPath + '/assets/img/navbar/chats-active.svg'
              "
              class="heart"
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.swipe"
              [src]="
                environment.rootPath + '/assets/img/navbar/swipe-active.svg'
              "
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.shop"
              [src]="
                environment.rootPath + '/assets/img/navbar/shop-active.svg'
              "
            ></svg-icon>
            <svg-icon
              *ngIf="menuButton === uiStates.settings"
              [src]="
                environment.rootPath + '/assets/img/navbar/gallery-active.svg'
              "
            ></svg-icon>
          </ng-container>
        </div>

        <span class="title">
          <ng-container *ngIf="menuButton === uiStates.gallery">
            {{ "NAVBAR.GALLERY" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.bonus">
            {{ "NAVBAR.QUESTS" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.chat">
            {{ "NAVBAR.CHATS" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.swipe">
            {{ "NAVBAR.SWIPE" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.shop">
            {{ "NAVBAR.SHOP" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.settings">
            {{ "NAVBAR.PROFILE" | translate }}
          </ng-container>
        </span>
      </button>
    </li>
  </ul>

  <ng-container *ngIf="this.state === 'chat'">
    <app-chat-answers></app-chat-answers>
  </ng-container>

  <div class="ios-spacer" *ngIf="isShowBottomSpacer"></div>
</ion-footer>
