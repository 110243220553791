export enum DeviceType {
  android = 'android',
  ios = 'ios',
  web = 'web'
}

export interface AuthPayloadData {
  clickId: string;
  partnerId: string;
  trafficSource: string;
  forcedTest?: string;
  caduid?: string;
  tscid?: string;
  gclickid?: string;
  utmSource?: string;
  utmContent?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  deviceType?: DeviceType;
  uspidntfr: string;
  usptoken: string;
  uem: string; // User Email
  tta: string; // User token for auth form email
  ofi: string; // offerId
}
