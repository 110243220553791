import { Capacitor } from '@capacitor/core';
import { RouteName } from './shared/enums/route-name.enum';
import { NavController, Platform } from '@ionic/angular';
import { OnlineStreamNotificationsService } from './shared/services/comunication_services/online-stream-notifications.service';
import { IncomePayloadDataService } from './shared/services/income-payload-data.service';
import { NativePushNotificationService } from './shared/services/notifications_services/native-push-notification.service';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { LoaderService } from './shared/services/comunication_services/loader.service';
import { NotificationService } from './shared/services/notifications_services/notification.service';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { HeaderFooterService } from './shared/services/comunication_services/headerFooter.service';
import { AnalyticsService } from './shared/services/analytics/analytics.service';
// import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { SoundsService } from './shared/services/sounds.service';
import { AuthMethodsService } from './shared/services/auth/auth-methods.service';
import { CustomHeaderTextKey } from './shared/enums/custom-header-text-key.enum';
import { Observable, Subscription } from 'rxjs';
import { WebsocketSignalRService } from './shared/services/websocket-signalr.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { WebsocketCommandType } from './shared/enums/websocket-command-type.enum';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { PaymentsCommonMethodsService } from './shared/services/payments/payments-common-methods.service';
import { LegalPaymentService } from './shared/services/payments/legal-payment.service';
import { SafeArea } from 'capacitor-plugin-safe-area';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { StatusBar } from '@capacitor/status-bar';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { NavHelper } from './shared/helpers';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
//import { FacebookLogin } from '@capacitor-community/facebook-login';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AnalyticsService, NotificationService, LoaderService],
  // animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  public paddingTop = '0px';
  public offsetTop = '0px';
  public isShowHeader = false;
  public isShowFooter = false;
  public isShowLoader = false;
  public installPrompt = null;
  public isLowHeightScreen = false;
  public isDeskTop =
    this._platform.is('desktop') && !this._platform.is('cordova');
  public appHeight = '100%';
  registrationCustomTextKey: CustomHeaderTextKey;
  registrationFormEnableClosing: boolean;
  _wsSubscription: Subscription;
  currentRoute = '';
  routeName = RouteName;
  private _insetTop = 0;

  constructor(
    // private _authMethodsService: AuthMethodsService,
    private _headerFooterService: HeaderFooterService,
    private _analyticsService: AnalyticsService,
    private _notificationService: NotificationService,
    private _loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    // private ym: NgxMetrikaService,
    private swUpdate: SwUpdate,
    private _nativePushNotificationService: NativePushNotificationService,
    private _soundsService: SoundsService,
    private _paymentsCommonMethodsService: PaymentsCommonMethodsService,
    private _incomePayloadDataService: IncomePayloadDataService,
    public websocketSignalRService: WebsocketSignalRService,
    private _onlineStreamNotificationsService: OnlineStreamNotificationsService,
    private _legalPaymentService: LegalPaymentService,
    private _platform: Platform,
    private _deeplinks: Deeplinks,
    private _navController: NavController,
    private _navHelper: NavHelper,
    private _screenOrientation: ScreenOrientation
  ) {
    // PWA update on changes
    this.swUpdate.available.subscribe(() => {
      if (confirm('A new version is available. Load it?')) {
        window.location.reload();
      }
    });

    this._headerFooterService.isShowHeader.subscribe((state) => {
      this.isShowFooter = state;
    });
    this._headerFooterService.isShowFooter.subscribe((state) => {
      this.isShowHeader = state;
    });
    this._loaderService.isLoader.subscribe((state) => {
      this.isShowLoader = state;
      if (state) {
        this.cdr.detectChanges();
      }
    });
    this._analyticsService.init();

    if (environment.buildVersion === BuildVersion.default) {
      this._onlineStreamNotificationsService.init();
    }
    if (environment.buildVersion === BuildVersion.legal) {
      setTimeout(() => {
        this._legalPaymentService.init();
      }, 1000);
    }

    if (_platform.is('cordova')) {
      this.initDeeplinks();

      this._screenOrientation.lock(
        this._screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
      );
    }
  }

  ngOnInit() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      this._insetTop = insets.top;
      this.checkHeight();
    });

    this._notificationService.init();
    // this._nativePushNotificationService.init(); // Moved to quests

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (environment.allowRegistrationForm) {
        // Discord confirmation registration code
        // if (params.code) {
        //   this._authMethodsService.signUpWithDiscord(params.code).subscribe(
        //     (res) => {},
        //     (error) => {
        //       console.error(error);
        //     }
        //   );
        // }
        // Twitter confirmation token
        // if (params.oauth_token && params.oauth_verifier) {
        //   this._authMethodsService
        //     .signWithTwitter({
        //       oAuthToken: params.oauth_token,
        //       oAuthVerifier: params.oauth_verifier,
        //     })
        //     .subscribe(
        //       (res) => {},
        //       (error) => {
        //         console.error(error);
        //       }
        //     );
        // }
      }
      this._incomePayloadDataService.getPayloadDataFromParams(params);
    });
    // this.ym.hit.emit();
    this._analyticsService.setPlatform();

    // Check if we have pending transaction
    // In Localstorage
    // And finish it
    this._paymentsCommonMethodsService.checkTransaction();
    this._wsSubscription = this.websocketSignalRService
      .init()
      .pipe(
        filter((res) => res === true),
        switchMap(() => this._instantWebsocketConnections()),
        take(1)
      )
      .subscribe();

    try {
      if (Capacitor.getPlatform() === 'ios') {
        this.getStatus()
          .then(async (trackStat) => {
            if (trackStat.status !== 'authorized') {
              await AppTrackingTransparency.requestPermission().then(
                (res) => {}
              );
            }
          })
          .catch((e) => console.warn('IOS Error', e));
      }
    } catch (e) {
      console.warn('Authorization failed', e);
    }

    if (!this._platform.is('mobileweb') && this._platform.is('mobile')) {
      StatusBar.hide();
      document.body.addEventListener('click', () => {
        StatusBar.getInfo().then((info) => {
          if (info.visible) {
            StatusBar.hide();
          }
        });
      });
    }

    this._fbSdkInit();
  }

  private async _fbSdkInit() {
    // await FacebookLogin.initialize({ appId: '768301854963875' });
  }

  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }

  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }

  private initDeeplinks(): void {
    this._deeplinks.routeWithNavController(this._navController, {}).subscribe({
      next: (match) => {
        const path = match.$link.path;
        const queryParams = Object.fromEntries(
          new URLSearchParams(match.$link.queryString)
        );
        this._incomePayloadDataService.getPayloadDataFromParams(queryParams);
        this.router.navigateByUrl(path);
      },
      error: () => {
        this._navHelper.goToMain();
      },
    });
  }

  getInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
    });
  }

  askUserToInstallApp() {
    this.installPrompt.prompt();
  }

  private _instantWebsocketConnections(): Observable<boolean> {
    return this.websocketSignalRService.send(WebsocketCommandType.coins, {});
  }

  ngOnDestroy(): void {
    this._soundsService.destroy();
    this._wsSubscription?.unsubscribe();
  }

  refreshConnection(event) {
    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  private checkHeight(): void {
    this.isLowHeightScreen =
      !this._platform.is('cordova') && window.innerHeight < 650;

    if (this.isLowHeightScreen) {
      this.appHeight = '143%';
      this.paddingTop = `${this._insetTop * 1.43}px`;
      this.offsetTop = `${this._insetTop * 1.43}px`;
    } else {
      this.appHeight = '100%';
      if (!this._platform.is('ios')) {
        this.paddingTop = `${this._insetTop}px`;
      }
      this.offsetTop = `${this._insetTop}px`;
    }
  }
}
