import { Injectable } from '@angular/core';
import settings from '../../data/settings';

@Injectable({
  providedIn: 'root',
})
export class NewMessageService {
  public isCanGetNewMessage = true;

  constructor() {
    settings.isCanGetNewMessage = this.isCanGetNewMessage;
  }

  public async toggleState(state: boolean): Promise<void> {
    this.isCanGetNewMessage = state;
    settings.isCanGetNewMessage = state;
  }
}
