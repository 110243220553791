import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdmobService } from '../../services/admob.service';

@Component({
  selector: 'app-admob-loader',
  templateUrl: './admob-loader.component.html',
  styleUrls: ['./admob-loader.component.scss'],
})
export class AdmobLoaderComponent implements OnInit, OnDestroy {
  public isVisible = false;

  constructor(private _admobService: AdmobService) {}

  public ngOnInit(): void {
    this._admobService.fullscreenAdLoaded.subscribe(
      (res) => (this.isVisible = res)
    );
  }

  public ngOnDestroy(): void {}
}
