<!-- BANNER EXCLUSIVE -->
<div
  *ngIf="appearanceType === 'banner'"
  class="pcb-container offer-banner-container lifetime-offer"
>
  <app-offer-timer
    *ngIf="offerData.time"
    [initialTimer]="offerData.time"
    class="offer-timer"
  ></app-offer-timer>

  <img
    [src]="environment.rootPath + '/assets/imgx/offers/lifetime/bella.png'"
    draggable="false"
    class="bella-img"
  />
  <img
    [src]="environment.rootPath + '/assets/imgx/offers/lifetime/rosie.png'"
    draggable="false"
    class="rosie-img"
  />
  <h2 class="title">
    <span [innerHTML]="'OFFERS.LIFETIME.LIFETIME_SUBSCRIPTION_FOR' | translate : {price: ''}"></span>
     <app-offer-price [offerData]="offerData"></app-offer-price>
  </h2>
  <a class="buy-btn">{{ "SHARED.BUY_NOW" | translate }}</a>
  <p class="description" [innerHTML]="'OFFERS.LIFETIME.PAY_ONCE' | translate ">

  </p>
</div>

<div
  class="pcb-container slider-modal lifetime-offer-modal"
  *ngIf="appearanceType === 'modal'"
>
  <app-offer-timer
    *ngIf="offerData.time"
    [initialTimer]="offerData.time"
    class="offer-timer"
  ></app-offer-timer>

  <h2 class="title">
    <span [innerHTML]="'OFFERS.LIFETIME.LIFETIME_SUBSCRIPTION_FOR' | translate : {price: ''}"></span>
     <app-offer-price [offerData]="offerData"></app-offer-price>
  </h2>
  <a class="buy-btn">{{ "SHARED.BUY_NOW" | translate }}</a>
  <p class="description" [innerHTML]="'OFFERS.LIFETIME.PAY_ONCE' | translate ">
  </p>
  <img
    [src]="environment.rootPath + '/assets/imgx/offers/lifetime/bella.png'"
    draggable="false"
    class="bella-img"
  />
  <img
    [src]="environment.rootPath + '/assets/imgx/offers/lifetime/rosie.png'"
    draggable="false"
    class="rosie-img"
  />
</div>
