import { filter, take, switchMap } from 'rxjs/operators';
import { WebsocketSignalRService } from './../websocket-signalr.service';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GirlOnlineToastComponent } from '../../components/girl-online-toast/girl-online-toast.component';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { interval, Observable, BehaviorSubject } from 'rxjs';

export interface ToastOnlineStreamData {
  name: string;
  link: string;
  platform: 'stripchat';
  chatId: string;
}

export interface GetOnlineStripChat {
  avatarUrl: string;
  chatId: string;
  link: string;
  popularSnapshotUrl: string;
  previewUrl: string;
  previewUrlThumbBig: string;
  previewUrlThumbSmall: string;
  snapshotUrl: string;
  stream: {
    width: number;
    height: 480;
    url: string;
    urls: {
      original: string;
    };
  };
  username: string;
  widgetPreviewUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class OnlineStreamNotificationsService {
  private _toastId: number;
  private _passedDataToComponent: ToastOnlineStreamData;
  private _inited = false;
  private _onlineModelData: BehaviorSubject<GetOnlineStripChat> =
    new BehaviorSubject(null);
  private _onlineTosterData: BehaviorSubject<GetOnlineStripChat> =
    new BehaviorSubject(null);

  get onlineModelData(): Observable<GetOnlineStripChat> {
    return this._onlineModelData.asObservable();
  }

  get toastId(): number {
    return this._toastId;
  }

  get passedDataToComponent(): ToastOnlineStreamData {
    return this._passedDataToComponent;
  }

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _toastrService: ToastrService
  ) {}

  init() {
    if (this._inited) {
      return;
    }
    this._inited = true;

    this._checkOnline();

    this._onlineTosterData.pipe(filter((res) => !!res)).subscribe((res) => {
      this._passedDataToComponent = {
        name: res.username,
        link: res.link,
        platform: 'stripchat',
        chatId: res.chatId,
      };
      this.showToast();
    });
  }

  public forceGetOnline(chatId: string) {
    this._getOnline(chatId)
      .pipe(take(1))
      .subscribe((res) => {
        this._onlineModelData.next(res);
      });
  }

  private _getOnline(chatId: string = null): Observable<GetOnlineStripChat> {
    return this._websocketSignalRService.invoke<GetOnlineStripChat>(
      WebsocketCommandType.getOnlineStripChat,
      { chatId }
    );
  }

  private _checkOnline() {
    interval(10000)
      .pipe(switchMap(() => this._getOnline()))
      .subscribe((res) => {
        this._onlineTosterData.next(res);
      });
  }

  showToast() {
    this._toastId = this._toastrService.success('', '', {
      toastComponent: GirlOnlineToastComponent,
      closeButton: true,
      timeOut: 15000,
    }).toastId;
  }
}
