import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PremiumBlockComponent } from './premium-block.component';
import { PriceBlockModule } from '../../price-block/price-block.module';

@NgModule({
  declarations: [PremiumBlockComponent],
  imports: [CommonModule, TranslateModule, PriceBlockModule],
  exports: [PremiumBlockComponent],
})
export class PremiumBlockModule {}
