import { Injectable } from '@angular/core';
import { OpenNextMessage } from '../../types/open-next-message.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ChatByIdResponse } from '../../types/chat-by-id-response.interface';
import { catchError, tap } from 'rxjs/operators';
import { ChatData } from '../../types/chat-data.interface';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { ReasonType } from '../../enums/reason-type.enum';

export interface ChooseAnswerResponse {
  chatRating: number;
  isBanned: boolean;
  messageId: string;
  emoji: number;
  coins: number;
}

export interface UseGiftResponse {
  chatRating: number;
  ratingChange: number;
  chatLevel: number;
  chatLevelChange: number;
  coins: number;
}

export interface BuyChatByGemsResponse {
  coins: number;
  chatId: string;
}

export interface GetChatRewardsResponse {
  score: number;
  rewardItems: {
    itemId: string;
    name: string;
    count: number;
    imageUrl: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class ChatApiMethodsService {
  private _chooseAnswerInProgress = false;
  constructor(private _websocketSignalRService: WebsocketSignalRService) {}

  public getNextMessagePackFromAPI(
    chatId: string
  ): Observable<OpenNextMessage[]> {
    // Avoid overlaping this request to response-answer request. @TODO refactor on BE-FE

    if (!this._chooseAnswerInProgress) {
      return this._websocketSignalRService.invoke<OpenNextMessage[]>(
        WebsocketCommandType.nextMessages,
        { chatId }
      );
      // const requestUrl = `${this._host}/api/chats/dialogs/open-next-message`;
      // return this._http.put<OpenNextMessage[]>(requestUrl, { chatId });
    } else {
      return of(null);
    }
  }

  public getChatById(chatId: string): Observable<ChatByIdResponse> {
    const command = { chatId };
    return this._websocketSignalRService.invoke<ChatByIdResponse>(
      WebsocketCommandType.myChat,
      command
    );
    // const requestUrl = `${this._host}/api/chats/${chatId}`;
    // return this._http.get<ChatByIdResponse>(requestUrl);
  }

  public getAllChats(): Observable<ChatData[]> {
    const command = {};
    return this._websocketSignalRService.invoke<ChatData[]>(
      WebsocketCommandType.myChats,
      command
    );
    // const requestUrl = `${this._host}/api/chats`;
    // return this._http
    //   .get<GetAllChatsResponse>(requestUrl);
  }

  public zeroingOffline(
    chatId: string,
    skipReason?: ReasonType
  ): Observable<{ coins: number }> {
    return this._websocketSignalRService.invoke<{ coins: number }>(
      WebsocketCommandType.skipOffline,
      { chatId, skipReason }
    );
    // const requestUrl = `${this._host}/api/Chats/skip-offline`;
    // return this._http.put<{coins: number}>(requestUrl, { chatId });
  }

  public buyAdditionalContent(chatId: string): Observable<{ coins: number }> {
    return this._websocketSignalRService.invoke<{ coins: number }>(
      WebsocketCommandType.buyAddOns,
      { chatId }
    );
    // const requestUrl = `${this._host}/api/Chats/buy-add-ons`;
    // return this._http.post<{coins: number}>(requestUrl, { chatId });
  }

  public sendFeedbackForm(chatId: string, data): Observable<{ coins: number }> {
    return this._websocketSignalRService.invoke<any>(
      WebsocketCommandType.chatFeedback,
      { chatId, ...data }
    );
    // const requestUrl = `${this._host}/api/Chats/${chatId}/feedback`;
    // return this._http.post<any>(requestUrl, { chatId, ...data });
  }

  public chooseAnswer(
    chatId: string,
    answerId: number
  ): Observable<ChooseAnswerResponse> {
    this._chooseAnswerInProgress = true;
    const command = { chatId, answerId };
    return this._websocketSignalRService
      .invoke<ChooseAnswerResponse>(WebsocketCommandType.chooseAnswer, command)
      .pipe(
        tap(() => {
          this._chooseAnswerInProgress = false;
        }),
        catchError((error: HttpErrorResponse) => {
          this._chooseAnswerInProgress = false;
          return throwError(error);
        })
      );

    // this._chooseAnswerInProgress = true;
    // const requestUrl = `${this._host}/api/Chats/dialogs/choose-answer`;
    // return this._http.put<ChooseAnswerResponse>(requestUrl, { chatId, answerId }).pipe(
    //   tap(res => {
    //     this._chooseAnswerInProgress = false;
    //   }),
    //   catchError((error: HttpErrorResponse) => {
    //     this._chooseAnswerInProgress = false;
    //     return throwError(error);
    //   })
    // );
  }

  public saveToGalery(
    chatId: string,
    url: string,
    contentType: string,
    messageId: string
  ): Observable<{ coins: number }> {
    // COST is rudiment. Kill on Server side
    return this._websocketSignalRService.invoke<{ coins: number }>(
      WebsocketCommandType.addToGallery,
      { chatId, url, cost: 0, contentType, messageId }
    );

    // const requestUrl = `${this._host}/api/user/gallery`;
    // return this._http.put<{coins: number}>(requestUrl, { chatId, url, cost: 0, contentType, messageId });
  }

  public useGift(giftId: string, chatId: string): Observable<UseGiftResponse> {
    return this._websocketSignalRService.invoke<UseGiftResponse>(
      WebsocketCommandType.useGift,
      { chatId, giftId }
    );
    // const requestUrl = `${this._host}/api/Chats/use-gift`;
    // return this._http.put<UseGiftResponse>(requestUrl, { chatId, giftId });
  }

  public buyChatByGems(chatId: string): Observable<BuyChatByGemsResponse> {
    return this._websocketSignalRService.invoke<BuyChatByGemsResponse>(
      WebsocketCommandType.buySoftChat,
      { chatId }
    );
    // const requestUrl = `${this._host}/api/Chats/buy-soft-chat`;
    // return this._http.put<BuyChatByGemsResponse>(requestUrl, { chatId });
  }

  public unbanChat(chatId: string): Observable<BuyChatByGemsResponse> {
    return this._websocketSignalRService.invoke<BuyChatByGemsResponse>(
      WebsocketCommandType.unban,
      { chatId }
    );
    // const requestUrl = `${this._host}/api/Chats/unban`;
    // return this._http.put<BuyChatByGemsResponse>(requestUrl, { chatId });
  }

  public getChatRewards(chatId: string): Observable<GetChatRewardsResponse> {
    return this._websocketSignalRService.invoke<GetChatRewardsResponse>(
      WebsocketCommandType.getChatRewardInfo,
      { chatId }
    );
  }
}
