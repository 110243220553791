<img
  *ngIf="isErogames"
  [src]="environment.rootPath + '/assets/img/erogold.svg'"
  draggable="false"
  class="coin-icon"
/>
<img
  *ngIf="isNutaku"
  [src]="environment.rootPath + '/assets/img/nutaku-gold-small.png'"
  draggable="false"
  class="coin-icon"
/>

<ng-container *ngIf="price">{{ price }}</ng-container>
<span class="spinner spinner-inline" *ngIf="!price"></span>


