import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';
import { OffersModalService } from 'src/app/shared/services/offers-modal.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-premium-subscription',
  templateUrl: './premium-subscription.component.html',
  styleUrls: ['./premium-subscription.component.scss'],
})
export class PremiumSubscriptionComponent implements OnInit {
  @Input() offerData: OfferData;
  @Input() appearanceType: 'modal' | 'banner' | 'smallBanner';
  @Input() purchased: boolean;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);

  public environment = environment;
  public buildVersion = BuildVersion;

  constructor(private _offersModalService: OffersModalService) {}

  ngOnInit() {}

  close() {
    this.closeModal.emit(true);
  }

  clickOnReadMore(e: MouseEvent) {
    e.stopPropagation();
    this._offersModalService
      .showPremiumModal(this.offerData, 'banner')
      .pipe(take(1))
      .subscribe();
  }
}
