import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  OfferData,
  OfferDataNew,
} from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-summer-kickoff-small',
  templateUrl: './summer-kickoff-small.component.html',
  styleUrls: ['./summer-kickoff-small.component.scss'],
})
export class SummerKickoffSmallComponent implements OnInit {
  @Input() public offerData: OfferDataNew;
  @Output() public closeModal: EventEmitter<boolean> = new EventEmitter(false);
  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;

  constructor() {}

  public ngOnInit() {}

  public close(e: Event) {
    e.stopPropagation();
    this.closeModal.emit(true);
  }
}
