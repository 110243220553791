import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  OfferData,
  OfferDataNew,
} from 'src/app/shared/types/offer-data.interface';
import { OfferType } from 'src/app/shared/enums/offer-type.enum';
import { Logger, LoggingService } from 'ionic-logging-service';
import { OffersService } from '../../services/offers.service';

@Component({
  selector: 'app-offers-modal',
  templateUrl: './offers-modal.component.html',
  styleUrls: ['./offers-modal.component.scss'],
})
export class OffersModalComponent implements OnInit {
  @Input() public offerData: OfferDataNew;
  public readonly offerType = OfferType;
  private _logger: Logger;
  private _purchased = false;

  constructor(
    private _analyticsService: AnalyticsService,
    private _modalCtrl: ModalController,
    private _loggingService: LoggingService,
    private _offersService: OffersService
  ) {}

  public ngOnInit() {
    this._analyticsService.showOffer(this.offerData);
    this._logger = this._loggingService.getLogger('App.OffersComponent');
  }

  public close() {
    this._modalCtrl.dismiss();
  }

  purchase() {
    this._logger.entry('ClickOnPurchase', this.offerData, 'banner');
    if (this._purchased) {
      return;
    }
    this.purchaseOffer();
  }

  purchaseOffer() {
    this._offersService
      .purchaseOffer(this.offerData, 'banner')
      .subscribe((res) => {
        if (res) {
          this._purchased = true;
          this.close();
        }
      });
  }
}
