<div class="kickoff-modal">
  <button (click)="close($event)" class="close-btn">
    <img src="/assets/img/modals/close-btn-black.svg" alt="" />
  </button>

  <h2 class="title">SUMMER<br />Kickoff Event<br />2023!</h2>

  <div class="items-block">
    <p class="pack-title">Exclusive mega pack:</p>

    <ul class="items">
      <li class="item">
        <img src="/assets/img/shop-page/tokens/tokens2.png" alt="" />
        <p>2500<br />Tokens</p>
      </li>
      <li class="item">
        <img src="/assets/img/quests-page/daily-icons/swipe.png" alt="" />
        <p>10<br />Swipes</p>
      </li>
    </ul>
  </div>
  <div class="spacer"></div>

  <img
    *ngIf="environment.buildVersion !== buildVersion.legal"
    src="/assets/imgx/offers/summer-kickoff/big-girl1-modal.png"
    alt=""
    class="girl-img1"
  />
  <img
    *ngIf="environment.buildVersion !== buildVersion.legal"
    src="/assets/imgx/offers/summer-kickoff/big-girl2-modal.png"
    alt=""
    class="girl-img2"
  />
  <img
    src="/assets/img/offers/summer-kickoff/leafs-bg-big.png"
    alt=""
    class="leafs"
  />

  <div class="btn-wrapper">
    <button class="btn btn-gradient-green">
      <app-price-block [purchase]="offerData.purchase"></app-price-block>
    </button>
  </div>
</div>
