import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialPaywallModalComponent } from './paywall-modal/tutorial-paywall-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { PriceBlockModule } from '../price-block/price-block.module';
import { TutorialChatPopupComponent } from './chat-popup/tutorial-chat-popup.component';

@NgModule({
  declarations: [TutorialPaywallModalComponent, TutorialChatPopupComponent],
  imports: [CommonModule, TranslateModule, PriceBlockModule],
  exports: [TutorialPaywallModalComponent, TutorialChatPopupComponent],
})
export class TutorialModule {}
