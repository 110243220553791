<button (click)="close()" class="close-btn">&times;</button>

<div></div>
<div>
  <h1>LoveChats</h1>
  <h2>This is a chat with hot girls</h2>
</div>

<div class="subscribe-block">
  <img
    src="/assets/img/tutorial/paywall/v1/bg-girl.png"
    alt=""
    draggable="false"
    class="bg-img"
  />
  <div class="subscribe-content">
    <div class="dialog">
      <div class="message">
        <p>Hi baby, you are hot like the sun.<br />What is your name?</p>
      </div>

      <div class="message">
        <img src="/assets/img/tutorial/paywall/v1/avatar.png" alt="" />
        <p>Haha my name is Alla Taylor.<br />But you can call me Sin.</p>
      </div>
    </div>

    <ul class="features">
      <li class="feature-card">
        <img src="/assets/img/quests-page/daily-icons/skipOffline.png" alt="" />
        Girls always<br />online
      </li>
      <li class="feature-card">
        <img src="/assets/img/quests-page/daily-icons/photo.png" alt="" />
        +300<br />Hot photos
      </li>
      <li class="feature-card">
        <img src="/assets/img/quests-page/icons/chat.png" alt="" />
        Girls text<br />you first
      </li>
      <li class="feature-card">
        <img src="/assets/img/quests-page/icons/email.png" alt="" />
        <span>+30</span>Flirty stories
      </li>
    </ul>

    <button
      (click)="purchaseOffer()"
      class="btn btn-gradient-green subscribe-btn"
    >
      <span class="btn-title"> Subscribe </span>
      <span *ngIf="offerData" class="price">
        3 days free then
        <app-price-block [purchase]="offerData.purchase"></app-price-block>
        / week
      </span>
    </button>
  </div>
</div>

<p class="subscription-text">
  After the 3 days free trial this subscription automatically renews for&nbsp;
  <app-price-block [purchase]="offerData.purchase"></app-price-block
  >&nbsp;per&nbsp;week unless it is cancelled at least 24 hours before the end
  of the trial period.
</p>

<div
  *ngIf="environment.buildVersion === buildVersion.legal"
  class="paywall-policies"
>
  <a
    (click)="openTermsOfUseModal()"
    [innerHTML]="'SETTINGS_PAGE.TERMS_OF_USE' | translate"
  ></a>
  <a
    (click)="openPolicyModal()"
    [innerHTML]="'SETTINGS_PAGE.PRIVACY' | translate"
  ></a>
</div>
