import { BuildVersion, EnvironmentModel } from './environment-model.interface';

export const environment: EnvironmentModel = {
  appId: 'com.fgf.lovestory',
  appName: 'LoveChats',
  gameName: 'LoveChats',
  production: true,
  apiUrl: 'https://api.flirtext.com',
  buildVersion: BuildVersion.legal,
  webSiteUrl: 'https://lovechats.app',
  discordLink: 'https://discord.gg/7B6NX5U5Tj',
  rootPath: '',
  mode: 'prod',
  showTncs: false,
  allowRegistrationForm: true,
  allowPaymentIframe: false,
  isPromoWidgetChat: false,
  showAdmobAds: true,
  showOnlineWebcamNotification: false,
  splashScreenImgSrc: 'assets/img/bg.png',
  firebaseConfig: {
    apiKey: 'AIzaSyCchXDxWly2G8Pf2pyWwU49SxxRo3rZ12A',
    authDomain: 'getmynudes-d4d83.firebaseapp.com',
    projectId: 'getmynudes-d4d83',
    storageBucket: 'getmynudes-d4d83.appspot.com',
    messagingSenderId: '215449707370',
    appId: '1:215449707370:web:1404c575674bc96385bb13',
    measurementId: 'G-3PN0MYE8RD',
  },
  policy: {
    name: 'LoveChats',
    site: 'LoveChats.app',
    docsBaseUrl: 'https://info.lovechats.app',
    email: 'hello@perchapps.com',
  },
  logging: {
    logLevels: [
      {
        loggerName: 'root',
        logLevel: 'DEBUG',
      },
    ],
  },
  promoCodeIsAvailable: false,
  banners: [],
  admob: {
    bannerId: 'ca-app-pub-2557225532996172/4276933613',
    interstitial: 'ca-app-pub-2557225532996172/2688088578',
    rewardVideoId: 'ca-app-pub-2557225532996172/7079659451',
  },
  appMetricaKey: '0a16f5a1-9a78-4be3-bb52-b0362b58b8df'
};
