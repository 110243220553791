<div
  *ngIf="appearanceType === 'banner'"
  class="pcb-container offer-banner-container spring-break-big-offer"
>
  <div class="border"></div>
  <h1>Spring<br />cleaning<br>Event 2023</h1>
  <p>Exclusive pack</p>
  <ul>
    <li><span>30</span> {{ "OFFERS.RINGS" | translate }}</li>
    <li><span>2000</span> {{ "OFFERS.GEMS" | translate }}</li>
  </ul>

  <img
    *ngIf="environment.buildVersion !== buildVersion.legal"
    [src]="environment.rootPath + '/assets/imgx/offers/spring-break/girl1.png'"
    draggable="false"
    class="girl1"
  />
  <img
    *ngIf="environment.buildVersion !== buildVersion.legal"
    [src]="environment.rootPath + '/assets/imgx/offers/spring-break/girl2.png'"
    draggable="false"
    class="girl2"
  />
  <div class="gifts-wrapper">
    <img
      [src]="
        environment.rootPath +
        '/assets/img/offers/spring-break/big/gifts-offer.png'
      "
      draggable="false"
      class="gifts"
    />
    <app-price-block-new
      [offerData]="offerData"
      [withDiscount]="false"
      [floating]="true"
    ></app-price-block-new>
  </div>
</div>

<div
  class="pcb-container slider-modal spring-break-big-offer-modal"
  *ngIf="appearanceType === 'modal'"
  [class.offer-big]="offerData.type === offerType.springCleaningOfferBig"
>
  <h1>Spring<br />cleaning<br>Event 2023</h1>
  <p class="subtitle">Exclusive pack</p>

  <img
    *ngIf="environment.buildVersion !== buildVersion.legal && offerData.type === offerType.springCleaningOffer"
    [src]="environment.rootPath + '/assets/imgx/offers/spring-cleaning/g1.png'"
    draggable="false"
    class="girl"
  />
  <img
    *ngIf="environment.buildVersion !== buildVersion.legal && offerData.type === offerType.springCleaningOfferBig"
    [src]="environment.rootPath + '/assets/imgx/offers/spring-cleaning/g2.png'"
    draggable="false"
    class="girl"
  />

  <img
  *ngIf="offerData.type === offerType.springCleaningOffer"
    [src]="
      environment.rootPath +
      '/assets/img/offers/spring-break/small/gifts-modal.png'
    "
    draggable="false"
    class="gifts"
  />
  <img
  *ngIf="offerData.type === offerType.springCleaningOfferBig"
    [src]="
      environment.rootPath +
      '/assets/img/offers/spring-break/big/gifts-modal.png'
    "
    draggable="false"
    class="gifts"
  />

  <ul *ngIf="offerData.type === offerType.springCleaningOffer">
    <li><span>1000</span> {{ "OFFERS.GEMS" | translate }}</li>
    <li><span>15</span> {{ "OFFERS.FLOWERS" | translate }}</li>
  </ul>
  <ul *ngIf="offerData.type === offerType.springCleaningOfferBig">
    <li><span>2500</span> {{ "OFFERS.GEMS" | translate }}</li>
    <li><span>30</span> {{ "OFFERS.RINGS" | translate }}</li>
  </ul>

  <app-price-block-new
    [offerData]="offerData"
    [withDiscount]="false"
    [floating]="true"
  ></app-price-block-new>
</div>
