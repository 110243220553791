import { UiStateService } from './../comunication_services/uiStates.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ShopDataService } from '../comunication_services/shopData.service';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { ShopLot } from '../../types/shop-lot.interface';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  private _host: string = environment.apiUrl;

  constructor(
    private _http: HttpClient,
    private _shopDataService: ShopDataService,
    private _uiStateService: UiStateService
  ) {}

  public getGems(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${this._host}/api/chatlot/gems`;
      const response = await this._http.get<ShopLot[]>(requestUrl).toPromise();
      this.saveGemsData(response);
      resolve(true);
      return true;
    });
  }

  public getGifts(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${this._host}/api/chatlot/gifts`;
      const response = await this._http.get<ShopLot[]>(requestUrl).toPromise();

      this.saveGiftsData(response);
      resolve(true);
      return true;
    });
  }

  public getChats(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${this._host}/api/chatlot`;
      const response = await this._http.get<ShopLot[]>(requestUrl).toPromise();
      this.saveChatsData(response);
      resolve(true);
      return true;
    });
  }

  private saveGemsData(data: ShopLot[]) {
    this._shopDataService.changeAllGems(data);
  }

  private saveGiftsData(data: ShopLot[]) {
    this._shopDataService.changeAllGifts(data);
  }

  private saveChatsData(data: ShopLot[]) {
    this._shopDataService.changeAllChats(data);
  }

  saveDiamonds(value: number): void {
    this._uiStateService.changeData(UiStatesEnum.diamonds, value);
  }
}
