import { ChatDetailsData } from './../../types/chat-details-data.interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ShopLot } from '../../types/shop-lot.interface';

@Injectable({
  providedIn: 'root',
})
export class ShopDataService {
  private gemsData: ShopLot[] = [];
  private chatsData: ShopLot[] = [];
  private giftsData: ShopLot[] = [];
  public updateGemsData: BehaviorSubject<ShopLot[]> = new BehaviorSubject(
    this.gemsData
  );
  public updateChatsData: BehaviorSubject<ShopLot[]> = new BehaviorSubject(
    this.chatsData
  );
  public updateGiftsData: BehaviorSubject<ShopLot[]> = new BehaviorSubject(
    this.giftsData
  );
  public openDetailsAction: Subject<ChatDetailsData> = new Subject();

  constructor() {}

  public changeGemsData(fieldName: string, value: any): void {
    const defaultGemsData = this.setNewData(this.gemsData, fieldName, value);
    this.gemsData = defaultGemsData;
    this.updateGemsData.next(defaultGemsData);
  }

  public changeChatsData(fieldName: string, value: any): void {
    const defaultChatsData = this.setNewData(this.giftsData, fieldName, value);
    this.giftsData = defaultChatsData;
    this.updateChatsData.next(defaultChatsData);
  }

  public setNewData(obj: any, field: string, value: any): any {
    const setPath = (object: any, path: any, value2: any) =>
      path
        .split('.')
        .reduce(
          (o: any, p: any, i: any) =>
            (o[p] = path.split('.').length === ++i ? value2 : o[p] || {}),
          object
        );
    setPath(obj, field, value);
    return obj;
  }

  public changeAllGems(value: ShopLot[]): void {
    this.gemsData = value;
    this.updateGemsData.next(this.gemsData);
  }

  public changeAllGifts(value: ShopLot[]): void {
    this.giftsData = value;
    this.updateGiftsData.next(this.giftsData);
  }

  public changeAllChats(value: ShopLot[]): void {
    this.chatsData = value;
    this.updateChatsData.next(this.chatsData);
  }

  public openDetails(details: ChatDetailsData) {
    this.openDetailsAction.next(details);
  }
}
