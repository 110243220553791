<div
  *ngIf="subscriptionStatus !== subscriptionStatuses.active"
  class="new-design"
>
  <button *ngIf="isCloseButtonEnabled" (click)="close()" class="close-btn">
    &times;
  </button>

  <div></div>

  <h1 class="title">PREMIUM</h1>

  <div class="screens-img">
    <ul
      class="features"
      [style]="{
        '--activeIndex': activeCardIndex,
        transition: cardsTransitionEnabled ? null : 'none'
      }"
    >
      <ng-container *ngFor="let number of [0, 1, 2]">
        <li
          class="feature-card"
          [class.active]="activeCardIndex === 0 || activeCardIndex === 5"
        >
          <img src="/assets/img/quests-page/icons/chat.png" alt="" />
          Endless<br />chats
        </li>
        <li class="feature-card" [class.active]="activeCardIndex === 1">
          <img src="/assets/img/quests-page/daily-icons/swipe.png" alt="" />
          Unlimited<br />Swipes
        </li>
        <li class="feature-card" [class.active]="activeCardIndex === 2">
          <img src="/assets/img/quests-page/daily-icons/photo.png" alt="" />
          +300<br />Photos
        </li>
        <li class="feature-card" [class.active]="activeCardIndex === 3">
          <img src="/assets/img/quests-page/icons/email.png" alt="" />
          <span>+30</span>Flirty stories
        </li>
        <li class="feature-card" [class.active]="activeCardIndex === 4">
          <img src="/assets/img/quests-page/icons/notifications.png" alt="" />
          Girls always<br />online
        </li>
      </ng-container>
    </ul>
  </div>

  <button
    (click)="purchaseOffer()"
    class="btn btn-gradient-green subscribe-btn"
  >
    <span class="btn-title"> Subscribe </span>
    <span *ngIf="offerData" class="price">
      3 days free then
      <app-price-block [purchase]="offerData.purchase"></app-price-block>
      / week
    </span>
  </button>

  <p class="subscription-text">
    After the 3 days free trial this subscription automatically renews for&nbsp;
    <app-price-block [purchase]="offerData.purchase"></app-price-block
    >&nbsp;per&nbsp;week unless it is cancelled at least 24 hours before the end
    of the trial period.
  </p>

  <div
    *ngIf="environment.buildVersion === buildVersion.legal"
    class="paywall-policies"
  >
    <a
      (click)="openTermsOfUseModal()"
      [innerHTML]="'SETTINGS_PAGE.TERMS_OF_USE' | translate"
    ></a>
    <a
      (click)="openPolicyModal()"
      [innerHTML]="'SETTINGS_PAGE.PRIVACY' | translate"
    ></a>
  </div>
</div>

<ng-container *ngIf="subscriptionStatus === subscriptionStatuses.active">
  <div class="animate-bottom modal-container">
    <div class="modal modal-vertical modal-no-padding modal-auto-height">
      <button (click)="this.close()" class="close-modal-btn">
        <img [src]="environment.rootPath + '/assets/img/close-modal-btn.svg'" />
      </button>
      <div class="premium-modal-content">
        <div class="bg"></div>
        <div class="premium-modal-head">
          <img
            [src]="environment.rootPath + '/assets/img/premium/bokeh.png'"
            draggable="false"
            class="bokeh"
          />
          <img
            *ngIf="environment.buildVersion !== buildVersion.legal"
            [src]="environment.rootPath + '/assets/img/premium/premium.png'"
            draggable="false"
            class="vip-img"
          />
          <img
            *ngIf="environment.buildVersion === buildVersion.legal"
            [src]="
              environment.rootPath + '/assets/img/premium/premium-legal.png'
            "
            draggable="false"
            class="vip-img"
          />
          <img
            [src]="environment.rootPath + '/assets/img/premium/lensFlare.png'"
            draggable="false"
            class="flare"
          />
        </div>

        <div class="slider">
          <swiper [config]="swiperConfig" class="swiper-old">
            <ng-template swiperSlide>
              <div class="premium-card">
                <img
                  [src]="
                    environment.rootPath +
                    '/assets/img/premium/cards/hearts.svg'
                  "
                  draggable="false"
                />
                <h5>
                  {{ "MODALS.PREMIUM.FREE_CHATS" | translate }}
                </h5>
                <p>
                  {{ "MODALS.PREMIUM.FREE_CHATS_DESCRIPTION" | translate }}
                </p>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="premium-card">
                <img
                  [src]="
                    environment.rootPath + '/assets/img/premium/cards/gift.svg'
                  "
                  draggable="false"
                />
                <h5>
                  {{ "MODALS.PREMIUM.EXCLUSIVE_CONTENT" | translate }}
                </h5>
                <p>
                  {{
                    "MODALS.PREMIUM.EXCLUSIVE_CONTENT_DESCRIPTION" | translate
                  }}
                </p>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="premium-card">
                <img
                  [src]="
                    environment.rootPath +
                    '/assets/img/premium/cards/calendar.svg'
                  "
                  draggable="false"
                />
                <h5>
                  {{ "MODALS.PREMIUM.QUICK_MESSAGES" | translate }}
                </h5>
                <p>
                  {{ "MODALS.PREMIUM.QUICK_MESSAGES_DESCRIPTION" | translate }}
                </p>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div *ngIf="environment.isPromoWidgetChat" class="premium-card">
                <img
                  [src]="
                    environment.rootPath +
                    '/assets/img/premium/cards/no-ads.svg'
                  "
                  draggable="false"
                />
                <h5>
                  {{ "MODALS.PREMIUM.NO_ADS" | translate }}
                </h5>
                <p>{{ "MODALS.PREMIUM.NO_ADS_DESCRIPTION" | translate }}</p>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="premium-card">
                <img
                  [src]="
                    environment.rootPath +
                    '/assets/img/premium/cards/hearts.svg'
                  "
                  draggable="false"
                />
                <h5>
                  {{ "MODALS.PREMIUM.BETTER_LOOK" | translate }}
                </h5>
                <p>
                  {{ "MODALS.PREMIUM.BETTER_LOOK_DESCRIPTION" | translate }}
                </p>
              </div></ng-template
            >
          </swiper>
        </div>

        <a
          *ngIf="environment.buildVersion === buildVersion.legal"
          (click)="showUnsubscribeModal()"
          class="unsubscribe-btn"
        >
          {{ "MODALS.PREMIUM.UNSUBSCRIBE" | translate }}
        </a>

        <div
          *ngIf="environment.buildVersion === buildVersion.legal"
          class="policies"
        >
          <a
            (click)="openTermsOfUseModal()"
            [innerHTML]="'MODALS.PREMIUM.TERMS_OF_USE' | translate"
          ></a>
          <a
            (click)="openSubscriptionTermsModal()"
            [innerHTML]="'MODALS.PREMIUM.SUBSCRIPTION_TERMS' | translate"
          ></a>
          <a
            (click)="openPolicyModal()"
            [innerHTML]="'MODALS.PREMIUM.PRIVACY_POLICY' | translate"
          ></a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--! old -->
<ng-container *ngIf="false">
  <ng-container
    *ngIf="
      environment.buildVersion !== buildVersion.legal ||
      subscriptionStatus === subscriptionStatuses.active
    "
  >
    <div class="animate-bottom modal-container">
      <div class="modal modal-vertical modal-no-padding modal-auto-height">
        <button (click)="this.close()" class="close-modal-btn">
          <img
            [src]="environment.rootPath + '/assets/img/close-modal-btn.svg'"
          />
        </button>
        <div class="premium-modal-content">
          <div class="bg"></div>
          <div class="premium-modal-head">
            <img
              [src]="environment.rootPath + '/assets/img/premium/bokeh.png'"
              draggable="false"
              class="bokeh"
            />
            <img
              *ngIf="environment.buildVersion !== buildVersion.legal"
              [src]="environment.rootPath + '/assets/img/premium/premium.png'"
              draggable="false"
              class="vip-img"
            />
            <img
              *ngIf="environment.buildVersion === buildVersion.legal"
              [src]="
                environment.rootPath + '/assets/img/premium/premium-legal.png'
              "
              draggable="false"
              class="vip-img"
            />
            <img
              [src]="environment.rootPath + '/assets/img/premium/lensFlare.png'"
              draggable="false"
              class="flare"
            />
          </div>

          <div class="slider">
            <swiper [config]="swiperConfig" class="swiper-old">
              <ng-template swiperSlide>
                <div class="premium-card">
                  <img
                    [src]="
                      environment.rootPath +
                      '/assets/img/premium/cards/hearts.svg'
                    "
                    draggable="false"
                  />
                  <h5>
                    {{ "MODALS.PREMIUM.FREE_CHATS" | translate }}
                  </h5>
                  <p>
                    {{ "MODALS.PREMIUM.FREE_CHATS_DESCRIPTION" | translate }}
                  </p>
                </div>
              </ng-template>

              <ng-template swiperSlide>
                <div class="premium-card">
                  <img
                    [src]="
                      environment.rootPath +
                      '/assets/img/premium/cards/gift.svg'
                    "
                    draggable="false"
                  />
                  <h5>
                    {{ "MODALS.PREMIUM.EXCLUSIVE_CONTENT" | translate }}
                  </h5>
                  <p>
                    {{
                      "MODALS.PREMIUM.EXCLUSIVE_CONTENT_DESCRIPTION" | translate
                    }}
                  </p>
                </div>
              </ng-template>

              <ng-template swiperSlide>
                <div class="premium-card">
                  <img
                    [src]="
                      environment.rootPath +
                      '/assets/img/premium/cards/calendar.svg'
                    "
                    draggable="false"
                  />
                  <h5>
                    {{ "MODALS.PREMIUM.QUICK_MESSAGES" | translate }}
                  </h5>
                  <p>
                    {{
                      "MODALS.PREMIUM.QUICK_MESSAGES_DESCRIPTION" | translate
                    }}
                  </p>
                </div>
              </ng-template>

              <ng-template swiperSlide>
                <div *ngIf="environment.isPromoWidgetChat" class="premium-card">
                  <img
                    [src]="
                      environment.rootPath +
                      '/assets/img/premium/cards/no-ads.svg'
                    "
                    draggable="false"
                  />
                  <h5>
                    {{ "MODALS.PREMIUM.NO_ADS" | translate }}
                  </h5>
                  <p>{{ "MODALS.PREMIUM.NO_ADS_DESCRIPTION" | translate }}</p>
                </div>
              </ng-template>

              <ng-template swiperSlide>
                <div class="premium-card">
                  <img
                    [src]="
                      environment.rootPath +
                      '/assets/img/premium/cards/hearts.svg'
                    "
                    draggable="false"
                  />
                  <h5>
                    {{ "MODALS.PREMIUM.BETTER_LOOK" | translate }}
                  </h5>
                  <p>
                    {{ "MODALS.PREMIUM.BETTER_LOOK_DESCRIPTION" | translate }}
                  </p>
                </div></ng-template
              >
            </swiper>
          </div>

          <div
            *ngIf="
              offerData && subscriptionStatus !== subscriptionStatuses.active
            "
            (click)="purchaseOffer()"
            class="price-block-wrapper"
          >
            <p
              *ngIf="subscriptionStatus === subscriptionStatuses.none"
              class="subscribtion-text"
            >
              {{ "MODALS.PREMIUM.JOIN_NOW" | translate }}
            </p>

            <p
              *ngIf="subscriptionStatus === subscriptionStatuses.expired"
              class="subscribtion-text"
            >
              {{ "MODALS.PREMIUM.SUBSCRIPTION_EXPIRED" | translate }}
            </p>

            <!-- <app-price-block
              [offerData]="offerData"
              [subscriptionText]="true"
              class="premium-price-block"
            ></app-price-block> -->
          </div>

          <a
            *ngIf="
              environment.buildVersion === buildVersion.legal &&
              subscriptionStatus === subscriptionStatuses.active
            "
            (click)="showUnsubscribeModal()"
            class="unsubscribe-btn"
          >
            {{ "MODALS.PREMIUM.UNSUBSCRIBE" | translate }}
          </a>

          <div
            *ngIf="environment.buildVersion === buildVersion.legal"
            class="policies"
          >
            <a
              (click)="openTermsOfUseModal()"
              [innerHTML]="'MODALS.PREMIUM.TERMS_OF_USE' | translate"
            ></a>
            <a
              (click)="openSubscriptionTermsModal()"
              [innerHTML]="'MODALS.PREMIUM.SUBSCRIPTION_TERMS' | translate"
            ></a>
            <a
              (click)="openPolicyModal()"
              [innerHTML]="'MODALS.PREMIUM.PRIVACY_POLICY' | translate"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="
      environment.buildVersion === buildVersion.legal &&
      subscriptionStatus !== subscriptionStatuses.active
    "
    class="paywall-container"
  >
    <swiper style="height: 100%" [config]="swiperConfig2" #paywallSwiper>
      <ng-template swiperSlide>
        <div class="paywall-center-wrapper slide-1">
          <div>
            <h2>LoveChats</h2>
            <p>This is a chat with hot girls</p>
          </div>
          <img src="/assets/img/paywall/slide1.png" class="slide-img" />

          <button class="next-slide-btn" (click)="goToNext(2)">
            <span>Next</span>
          </button>
        </div>
      </ng-template>

      <ng-template swiperSlide>
        <div class="paywall-center-wrapper slide-2">
          <div></div>

          <img src="/assets/img/paywall/slide2.png" class="slide-img" />

          <p class="text-reflection">
            <span>Over 30 unique girls</span>
            <span>Over 30 unique girls</span>
            <span>Over 30 unique girls</span>
          </p>

          <button class="next-slide-btn" (click)="goToNext(3)">
            <span>Next</span>
          </button>
        </div>
      </ng-template>

      <ng-template swiperSlide>
        <div class="paywall-center-wrapper slide-3">
          <button
            *ngIf="isCloseButtonEnabled"
            class="close-btn"
            (click)="close()"
          >
            <img src="/assets/img/paywall/close-btn.svg" alt="" />
          </button>

          <div class="slide-top">
            <div class="slide-top_content">
              <img
                class="girl-left"
                src="/assets/img/paywall/l-girl.png"
                alt=""
              />
              <img
                class="girl-right"
                src="/assets/img/paywall/r-girl.png"
                alt=""
              />

              <h3>Premium Access</h3>
              <p>Get access to<br />all the features of LoveChats!</p>
            </div>
          </div>

          <ul>
            <li>
              <div class="icon-wrapper">
                <img class="icon" src="/assets/img/paywall/slide3-list-1.png" />
              </div>
              <span> Unlimited swipes </span>
              <img class="check" src="/assets/img/paywall/check.png" />
            </li>
            <li>
              <div class="icon-wrapper">
                <img class="icon" src="/assets/img/paywall/slide3-list-2.png" />
              </div>
              <span> Over 300 juicy photos </span>
              <img class="check" src="/assets/img/paywall/check.png" />
            </li>
            <li>
              <div class="icon-wrapper">
                <img class="icon" src="/assets/img/paywall/slide3-list-3.png" />
              </div>
              <span> Over 30 flirty stories </span>
              <img class="check" src="/assets/img/paywall/check.png" />
            </li>
            <li>
              <div class="icon-wrapper">
                <img class="icon" src="/assets/img/paywall/slide3-list-4.png" />
              </div>
              <span> Unique dialogues to seduce girls </span>
              <img class="check" src="/assets/img/paywall/check.png" />
            </li>
            <li>
              <div class="icon-wrapper">
                <img class="icon" src="/assets/img/paywall/slide3-list-5.png" />
              </div>
              <span> Endless exciting chats </span>
              <img class="check" src="/assets/img/paywall/check.png" />
            </li>
          </ul>

          <button (click)="purchaseOffer()" class="next-slide-btn">
            <span>Subscribe</span>
            <span>
              3 days free then
              <!-- <app-offer-price [offerData]="offerData"></app-offer-price> / week -->
            </span>
          </button>

          <p class="subscription-terms">
            After the 3 days free trial this subscription automatically renews
            for
            <!-- <app-offer-price [offerData]="offerData"></app-offer-price> per week -->
            unless it is cancelled at least 24 hours before the end of the trial
            period.
          </p>

          <div
            *ngIf="environment.buildVersion === buildVersion.legal"
            class="paywall-policies"
          >
            <a
              (click)="openTermsOfUseModal()"
              [innerHTML]="'SETTINGS_PAGE.TERMS_OF_USE' | translate"
            ></a>
            <!-- <a
            (click)="openSubscriptionTermsModal()"
            [innerHTML]="'SETTINGS_PAGE.SUBSCRIPTION_TERMS' | translate"
          ></a> -->
            <a
              (click)="openPolicyModal()"
              [innerHTML]="'SETTINGS_PAGE.PRIVACY' | translate"
            ></a>
          </div>
        </div>
      </ng-template>
    </swiper>

    <div
      [style.display]="step === 2 ? 'none' : null"
      class="swiper-pagination"
    ></div>
  </div>
</ng-container>
