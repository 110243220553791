import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { PolicyComponent } from '../../policy/policy.component';
import { SubscriptionTermsComponent } from '../../subscriptionTerms/subscription-terms.component';
import { TermsOfUseComponent } from '../../terms-of-use/terms-of-use.component';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { OffersService } from 'src/app/shared/services/offers.service';

@Component({
  selector: 'app-tutorial-paywall-modal',
  templateUrl: './tutorial-paywall-modal.component.html',
  styleUrls: ['./tutorial-paywall-modal.component.scss'],
})
export class TutorialPaywallModalComponent implements OnInit, OnDestroy {
  @Input() public offerData: OfferData;
  public environment = environment;
  public buildVersion = BuildVersion;

  constructor(
    private _modalController: ModalController,
    private _modalsService: ModalsService,
    private _offersService: OffersService
  ) {}

  public ngOnInit(): void {}

  public ngOnDestroy(): void {}

  public close(): void {
    this._modalController.dismiss();
  }

  public purchaseOffer(): void {
    this._offersService
      .purchaseOffer(this.offerData, 'modal')
      .subscribe((res) => {
        if (res) {
          this.close();
        }
      });
  }

  public openPolicyModal(): void {
    this._modalsService.openModal(PolicyComponent).subscribe();
  }

  public openTermsOfUseModal(): void {
    this._modalsService.openModal(TermsOfUseComponent).subscribe();
  }

  public openSubscriptionTermsModal(): void {
    this._modalsService.openModal(SubscriptionTermsComponent).subscribe();
  }
}
