<div
  class="price-block-new"
  [class.with-discount]="isShowDiscount"
  [class.floating]="floating"
>
  <div *ngIf="isShowDiscount" class="old-price">{{ oldCost }}</div>

  <div class="price">
    <span *ngIf="environment.buildVersion === buildVersion.default">
      ${{ offerData.purchase.cost }}
    </span>
    <span
      *ngIf="environment.buildVersion === buildVersion.erogames"
      class="btn-erogold-price"
    >
      {{ offerData.purchase.erogamesCost }}
    </span>
    <span
      *ngIf="environment.buildVersion === buildVersion.nutaku"
      class="btn-nutakugold-price"
    >
      {{ offerData.purchase.nutakuCost }}
    </span>
    <span *ngIf="environment.buildVersion === buildVersion.legal">
      {{ offerData.purchase.googlePlayId | getStorePrice | async }}
    </span>
  </div>
</div>
