import { map, tap, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileData } from '../../types/profile-data.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _host: string = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  getData(chatId: string): Observable<ProfileData> {
    if (chatId) {
      const requestUrl = `${this._host}/api/profile`;
      return this._http
        .get<any>(requestUrl, {
          params: {
            chatId,
          },
        })
        .pipe(
          switchMap((res) => {
            if (res?.status === 'profileNotFound.') {
              return throwError('Profile with this id is not found');
            }
            return of(res);
          })
        );
    } else {
      return of(null);
    }
  }
}
