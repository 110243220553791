import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TimeConverter } from 'src/app/shared/helpers/timeConverter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-offer-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class OfferTimerComponent implements OnInit, OnDestroy {
  @Input() public initialTimer: number;

  public environment = environment;
  public timeStr = '0h 0min';
  private _interval: ReturnType<typeof setInterval>;
  private _timer = 0;

  constructor(private _timeConverter: TimeConverter) {}

  public ngOnInit(): void {
    this._timer = this.initialTimer;
    this.timeStr = this._timeConverter.toDaysHourMinSec(this._timer);

    this._interval = setInterval(() => {
      this._timer--;
      if (this._timer <= 0) {
        this._timer = 0;
        clearInterval(this._interval);
      }
      this.timeStr = this._timeConverter.toDaysHourMinSec(this._timer);
    }, 1000);
  }

  public ngOnDestroy(): void {
    clearInterval(this._interval);
  }
}
