<!-- BANNER EXCLUSIVE -->
<div
  *ngIf="appearanceType === 'banner'"
  class="pcb-container offer-banner-container app-two-hot-lesbian-offer-banner"
>
  <!-- <app-offer-timer
    *ngIf="offerData.time"
    [initialTimer]="offerData.time"
    class="offer-timer"
  ></app-offer-timer> -->
  <div class="bg-container">

    <img class="heart-img" src="/assets/img/red-hot-heart.png">
    <div class="text-top">
      <span [innerHTML]="'OFFERS.TWO_HOT.TWO_HOT_CHATS' | translate"></span>
    </div>

    <div class="text-bottom">
      <span [innerHTML]="'OFFERS.TWO_HOT.ABOUT_GIRLFRIENDS' | translate"></span>
    </div>

    <div class="price-container">
      <button class="btn red-hot-button"><app-offer-price [offerData]="offerData"></app-offer-price></button>
    </div>
  </div>
</div>

<div
  class="pcb-container slider-modal app-two-hot-lesbian-offer-modal"
  *ngIf="appearanceType === 'modal'"
>

  <div class="bg-container">

    <img class="heart-img" src="/assets/img/red-hot-heart.png">
    <div class="text-top">
      <span [innerHTML]="'OFFERS.TWO_HOT.TWO_HOT_CHATS' | translate"></span>
    </div>

    <div class="text-bottom">
      <span [innerHTML]="'OFFERS.TWO_HOT.ABOUT_GIRLFRIENDS' | translate"></span>
    </div>
  </div>
  <!-- <app-offer-timer
    *ngIf="offerData.time"
    [initialTimer]="offerData.time"
    class="offer-timer"
  ></app-offer-timer> -->

  <div class="price-container">
    <button class="btn red-hot-button"><app-offer-price [offerData]="offerData"></app-offer-price></button>
  </div>







</div>
