<ng-container *ngIf="offerData">
  <app-chats-pack
    (click)="purchase()"
    *ngIf="
      offerData?.type === offerType.fiveChats ||
      offerData?.type === offerType.threeChats ||
      offerData?.type === offerType.oneChatAndGems ||
      offerData?.type === offerType.oneCheapChat
    "
    [appearanceType]="appearanceType"
    [offerData]="offerData"
  ></app-chats-pack>
  <!-- <app-exclusive-pack
  [appearanceType]="appearanceType"
  [offerData]="offerData"
></app-exclusive-pack> -->
  <app-five-likes
    *ngIf="offerData?.type === offerType.hiddenFiveChats"
    (click)="purchase()"
    (closeModal)="emitCloseModal()"
    [purchased]="purchased"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
  ></app-five-likes>
  <app-premium-subscription
    *ngIf="
      offerData.type === offerType.subscription ||
      offerData.type === offerType.subscriptionContinue ||
      offerData.type === offerType.subscriptionRenewal
    "
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  ></app-premium-subscription>

  <app-new-year-offer
    *ngIf="offerData.type === offerType.twoChatsForNewYear"
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-new-year-offer>

  <app-lifetime-offer
    *ngIf="offerData.type === offerType.lifetimeSubscription"
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-lifetime-offer>

  <app-valentine-day-small-offer
    *ngIf="offerData.type === offerType.valentineDaySmall"
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-valentine-day-small-offer>

  <app-valentine-day-big-offer
    *ngIf="offerData.type === offerType.valentineDayBig"
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-valentine-day-big-offer>

  <app-two-hot-lesbian-offer
    *ngIf="offerData.type === offerType.twoHotLesbianChats"
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-two-hot-lesbian-offer>

  <app-three-german-chats-offer
    *ngIf="offerData.type === offerType.threeGermanChats"
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-three-german-chats-offer>

  <app-spring-break-small-offer
    *ngIf="offerData.type === offerType.springBreakSmall"
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-spring-break-small-offer>

  <app-spring-break-big-offer
    *ngIf="offerData.type === offerType.springBreakBig"
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-spring-break-big-offer>
  <app-spring-cleaning-pack
    *ngIf="
      offerData.type === offerType.springCleaningOffer ||
      offerData.type === offerType.springCleaningOfferBig
    "
    (click)="purchase()"
    [appearanceType]="appearanceType"
    [offerData]="offerData"
    [purchased]="purchased"
    (closeModal)="emitCloseModal()"
  >
  </app-spring-cleaning-pack>
</ng-container>
