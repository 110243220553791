export enum LotType {
  giftPack = 'GiftPack',
  gems = 'Gems',
  chat = 'Chat',
  chatPack = 'ChatPack',
  unban = 'Unban',
  offer = 'Offer',
  subscription = 'Subscription',
  subscriptionContinue = 'SubscriptionContinue',
  subscriptionRenewal = 'SubscriptionRenewal',
  lifetimeSubscription = 'LifetimeSubscription',
  swipePack = 'SwipePack',
  skipOfflinePack = 'SkipOfflinePack',
}
