import { UiStateService } from './../comunication_services/uiStates.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { environment } from 'src/environments/environment';
import { NextBonusTimeService } from '../comunication_services/nextBonusTime.service';
import { BonuseDataService } from '../comunication_services/bonusData.service';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { PromoBonusInfoResponse } from '../../types/promo-bonus-info-response';
import { UiState } from '../../types/UiState';
import { UserQuestLinesResponse } from '../../types/user-quest-lines-response.interface';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { ReasonType } from '../../enums/reason-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ToastsService } from '../toasts.service';

@Injectable({
  providedIn: 'root',
})
export class BonusService {
  private _host: string = environment.apiUrl;
  private isGetTime = false;
  public state: UiState = null;
  private _questLines: BehaviorSubject<UserQuestLinesResponse[]> =
    new BehaviorSubject(null);

  constructor(
    private _http: HttpClient,
    private _tokenService: JWTTokenService,
    private _nextBonusTimeService: NextBonusTimeService,
    private _bonuseDataService: BonuseDataService,
    private _uiStateService: UiStateService,
    private _websocketSignalRService: WebsocketSignalRService,
    private _toastsService: ToastsService,
    private _translateService: TranslateService
  ) {
    this._nextBonusTimeService.updateNextBonusTime.subscribe((res) => {
      if (res.numb !== null) {
        this.isGetTime = true;
      } else {
        this.isGetTime = false;
      }
    });

    this._uiStateService.updateStateData.subscribe(async (res: UiState) => {
      this.state = res;
    });

    timer(0, 30000)
      .pipe(switchMap(() => this.getAllBonuses().pipe(take(1))))
      .subscribe();
  }

  public getAllBonuses(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke(WebsocketCommandType.getRewards, {})
      .pipe(
        map((res) => {
          this.saveData(res);
          return true;
        })
      );
    // return new Promise<boolean>(async (resolve) => {
    //   const requestUrl = `${this._host}/api/user/my_reward`;
    //   const response = await this._http.get<any>(requestUrl).toPromise();
    //   this.saveData(response);
    //   return true;
    // });
  }

  public getTime(getAnyway: boolean = false): Observable<boolean> {
    if (!this.isGetTime || getAnyway) {
      if (this._tokenService.getToken()) {
        return this._websocketSignalRService
          .invoke<{ time: any }>(WebsocketCommandType.getTimeReward, {})
          .pipe(
            map((res) => {
              this.saveTime(res.time);
              return true;
            })
          );
      } else {
        return of(false);
      }
    } else {
      return of(true);
    }

    // return new Promise<boolean>(async (resolve) => {
    //   if (!this.isGetTime || getAnyway) {
    //     if (this._tokenService.getToken()) {
    //       const requestUrl = `${this._host}/api/user/time_reward`;
    //       const response = await this._http.get<any>(requestUrl).toPromise();
    //       this.saveTime(response.time);
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     return true;
    //   }
    // });
  }

  public getBonuse(adWatched: boolean): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<{ coins: number }>(WebsocketCommandType.collectReward, {
        bonusReason: adWatched ? ReasonType.adWatched : undefined,
      })
      .pipe(
        map((res) => {
          this.saveDiamonds(res.coins);
          return true;
        })
      );
    // return new Promise<boolean>(async (resolve) => {
    //   const requestUrl = `${this._host}/api/user/claimed_reward`;
    //   const response = await this._http.put<any>(requestUrl, {}).toPromise();
    //   this.saveDiamonds(response.coins);
    //   resolve(true);
    //   return true;
    // });
  }

  public getPromoBonusInfo(promoCode): Observable<PromoBonusInfoResponse> {
    return this._websocketSignalRService.invoke<PromoBonusInfoResponse>(
      WebsocketCommandType.getPromoCode,
      { promoCode }
    );
    // const requestUrl = `${this._host}/api/PromoCode?promoCode=${promoCode}`;
    // return this._http.get<any>(requestUrl, {}).pipe();
  }

  public getPromoBonus(
    promoCode: string,
    promocodeGems: number
  ): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<{ gems: number }>(WebsocketCommandType.activatePromoCode, {
        promoCode,
      })
      .pipe(
        map((res) => {
          if (promocodeGems) {
            this._toastsService.showMessage(
              this._translateService.instant('BONUS_PAGE.COINS_RECEIVED', {
                value: promocodeGems,
              })
            );
          }

          this.saveDiamonds(res?.gems);
          return true;
        })
      );
    // const requestUrl = `${this._host}/api/PromoCode/activate`;
    // return this._http.post<any>(requestUrl, { promoCode }).pipe(
    //   tap((response) => {
    //     this.saveDiamonds(response?.gems);
    //   })
    // );
  }

  public getRewardsBonus(id: string): Observable<boolean> {
    const requestUrl = `${this._host}/api/PendingReward/collect`;
    return this._http
      .post<{ totalGems: number; addedGems: number }>(requestUrl, { id })
      .pipe(
        tap((response) => {
          this.saveDiamonds(response.totalGems);
        }),
        map((res) => true)
      );
  }

  get questLines(): Observable<UserQuestLinesResponse[]> {
    return this._questLines.asObservable();
  }

  public fetchQuestLines(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<UserQuestLinesResponse[]>(WebsocketCommandType.getQuests, {})
      .pipe(
        take(1),
        map((res) => {
          this._questLines.next(res);
          return true;
        })
      );
    // const requestUrl = `${this._host}/api/User/quests`;
    // return this._http
    //   .get<UserQuestLinesResponse[]>(requestUrl, {})
    //   .pipe(
    //     take(1),
    //     map(res => {
    //       this._questLines.next(res);
    //       return true;
    //     })
    //   );
  }

  public collectQuestLineReward(questId: string): Observable<{
    addedGems: number;
    totalGems: number;
  }> {
    return this._websocketSignalRService
      .invoke<{
        addedGems: number;
        totalGems: number;
      }>(WebsocketCommandType.takeQuestReward, { questId })
      .pipe(
        take(1),
        switchMap((res) => this.fetchQuestLines().pipe(map(() => res))),
        tap((res) => {
          if (res.addedGems) {
            this._toastsService.showMessage(
              this._translateService.instant('BONUS_PAGE.COINS_RECEIVED', {
                value: res.addedGems,
              })
            );
          }
          this.saveDiamonds(res.totalGems);
        })
      );

    // const requestUrl = `${this._host}/api/User/quests`;
    // return this._http
    //   .post<{
    //     addedGems: number;
    //     totalGems: number;
    //   }>(requestUrl, { id })
    //   .pipe(
    //     tap((response) => {
    //       this.saveDiamonds(response.totalGems);
    //       this.fetchQuestLines().subscribe();
    //     })
    //   );
  }

  public questLineAcceptation(questId: string): Observable<{
    addedGems: number;
    totalGems: number;
  }> {
    return this._websocketSignalRService
      .invoke<{
        addedGems: number;
        totalGems: number;
      }>(WebsocketCommandType.manualQuestCompletion, { questId })
      .pipe(
        take(1),
        switchMap((res) => this.fetchQuestLines().pipe(map(() => res))),
        tap((res) => {
          if (res?.totalGems) {
            this.saveDiamonds(res.totalGems);
          }
        })
      );
    // const requestUrl = `${this._host}/api/User/quests`;
    // return this._http
    //   .put<{
    //     addedGems: number;
    //     totalGems: number;
    //   }>(requestUrl, { id })
    //   .pipe(
    //     tap((response) => {
    //       this.fetchQuestLines();
    //       if (response?.totalGems) {
    //         this.saveDiamonds(response.totalGems);
    //       }
    //     })
    //   );
  }

  private saveData(data: any): void {
    this._bonuseDataService.setAllData(data);
  }

  private saveTime(time: number): void {
    this._nextBonusTimeService.changeTime(time);
  }

  public saveDiamonds(value: number): void {
    this._uiStateService.changeData(UiStatesEnum.diamonds, value);
  }
}
