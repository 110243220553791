<ng-container>
  <div class="modal">
    <div class="modal-head">{{ "SHARED.WARNING" | translate }}</div>
    <div class="modal-body">
      <div
        class="confirm-text text-center"
        *ngIf="environment.buildVersion !== buildVersion.nutaku"
        [innerHTML]="'SHARED.WE_GOT_NEW_VERSION_APK' | translate"
      ></div>
      <div
        class="confirm-text text-center"
        *ngIf="environment.buildVersion === buildVersion.nutaku"
        [innerHTML]="'SHARED.WE_GOT_NEW_VERSION_NUTAKU_APK' | translate"
      >
      </div>

      <div class="buttons">
        <!-- <button
          class="btn btn-block btn-uppercase"
          type="submit"
          (click)="cancel()"
        >Not now</button> -->

        <a
          *ngIf="environment.buildVersion !== buildVersion.nutaku"
          [href]="url"
          target="_blank"
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="confirm()"
          >{{ "SHARED.UPDATE" | translate }}</a
        >
      </div>
    </div>
  </div>
</ng-container>
