<div
  class="price-block"
  [ngClass]="{
    'with-subscription-text': subscriptionText,
    legal: environment.buildVersion === buildVersion.legal
  }"
>
  <div *ngIf="isShowDiscount" class="old-price">
    <span *ngIf="environment.buildVersion === buildVersion.default"
      >${{ offerData.purchase.oldCost }}</span
    >
    <span *ngIf="environment.buildVersion === buildVersion.erogames">{{
      offerData.purchase.oldErogamesCost
    }}</span>
    <span *ngIf="environment.buildVersion === buildVersion.nutaku">{{
      offerData.purchase.oldNutakuCost
    }}</span>
    <!-- <span *ngIf="environment.buildVersion === buildVersion.legal"
      >${{ offerData.purchase.oldCost }}</span
    > -->
    <span class="red-cross-line"></span>
  </div>
  <div class="new-price">
    <span *ngIf="environment.buildVersion === buildVersion.default"
      >${{ offerData.purchase.cost }}</span
    >
    <span
      *ngIf="environment.buildVersion === buildVersion.erogames"
      class="btn-erogold-price"
      >{{ offerData.purchase.erogamesCost }}</span
    >
    <span
      *ngIf="environment.buildVersion === buildVersion.nutaku"
      class="btn-nutakugold-price"
      >{{ offerData.purchase.nutakuCost }}</span
    >
    <span *ngIf="environment.buildVersion === buildVersion.legal"
      >{{ offerData.purchase.googlePlayId | getStorePrice | async }}
    </span>
  </div>
</div>
<p
  *ngIf="environment.buildVersion === buildVersion.default && subscriptionText"
  class="subscription-text"
>
  {{
    "OFFERS.PRICE_BLOCK.PRICE_AFTER_MONTH"
      | translate : { price: offerData?.purchase.nextCost }
  }}
</p>

<p
  *ngIf="environment.buildVersion !== buildVersion.default && subscriptionText"
  class="subscription-text"
>
  {{ "OFFERS.PRICE_BLOCK.SUBSCRIPTION_TEXT" | translate }}
</p>
