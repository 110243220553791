<ng-container>
  <div class="modal">
    <div class="modal-head">{{ notification.title || "Notification" }}</div>
    <div class="modal-body">
      <div class="confirm-text text-center">{{ notification.text }}</div>

      <div class="buttons">
        <button
          target="_blank"
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="close()"
        >
        {{ "SHARED.CLOSE" | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-container>
