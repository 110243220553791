import { Injectable } from '@angular/core';
import { Validators, ValidationErrors } from '@angular/forms';

@Injectable()
export class RegistrationHelperService {
  constructor() {}

  setValidators(validators) {
    const v = [];
    Object.entries(validators)
      .filter((validator) => validator[1])
      .forEach((validator) => {
        switch (validator[0]) {
          case 'required':
            v.push(Validators.required);
            break;
          case 'email':
            v.push(Validators.email);
            break;
          case 'min':
            v.push(Validators.min(validator[1] as number));
            break;
          default:
            break;
        }
      });
    return v;
  }

  getErrors(form, controlName) {
    return (
      (form.controls[controlName].invalid &&
        form.controls[controlName].touched &&
        form.controls[controlName].errors) ||
      null
    );
  }

  getValidationErrors(form, controlName) {
    return (
      (form.controls[controlName].invalid &&
        form.controls[controlName].touched &&
        form.controls[controlName].serverError) ||
      null
    );
  }

  isErrorClass(form, controlName) {
    return (
      form.controls[controlName].invalid && form.controls[controlName].touched
    );
  }

  // Kind of dirty method coz it has 2 directions
  // 1. Setting errors to fom controls
  // 2. Collecting commong errors and return them back;
  setServerErrors(err, form): string[] {
    const commonServerErrors: string[] = [];
    if (err.status === 400) {
      const errors = err.error.validationErrors || null;
      if (errors) {
        Object.keys(errors).forEach((item) => {
          const formControl = form.get(item.toLowerCase());
          if (formControl) {
            formControl.setErrors({
              [errors[item]]: true,
            });
          } else {
            commonServerErrors.push(errors[item][0]);
          }
        });
      }
    }
    return commonServerErrors;
  }
}
