<div class="modal">
  <div class="modal-head">
    Terms of use
    <button class="prev" (click)="close()">
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0607 3.06066C11.6464 2.47487 11.6464 1.52513 11.0607 0.93934C10.4749 0.353553 9.52513 0.353553 8.93934 0.93934L11.0607 3.06066ZM2 10L0.93934 8.93934C0.353553 9.52513 0.353553 10.4749 0.93934 11.0607L2 10ZM8.93934 19.0607C9.52513 19.6464 10.4749 19.6464 11.0607 19.0607C11.6464 18.4749 11.6464 17.5251 11.0607 16.9393L8.93934 19.0607ZM22 11.5C22.8284 11.5 23.5 10.8284 23.5 10C23.5 9.17157 22.8284 8.5 22 8.5V11.5ZM8.93934 0.93934L0.93934 8.93934L3.06066 11.0607L11.0607 3.06066L8.93934 0.93934ZM0.93934 11.0607L8.93934 19.0607L11.0607 16.9393L3.06066 8.93934L0.93934 11.0607ZM2 11.5H22V8.5H2V11.5Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </div>

  <div class="modal-content">
    <h2 class="c3" id="h.4x53odqxjfjb">
      <span class="c0">General terms of use and conditions</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >These Terms of Use regulate the use of the website
        {{ environment.policy.site }}, the conditions of purchase of the
        products and services that are offered at {{ environment.policy.site }};
        owned by Gethins limited.<br
      /></span>
    </p>
    <p class="c4">
      <span
        >Gethins is a limited liability company, registered in the Republic of
        Cyprus under registration number </span
      ><span class="c2">HE 390472</span
      ><span>, having its registered office at </span
      ><span class="c2"
        >Prodromou 75, Oneworld Parkview House, Floor 4, 2063 Nicosia,
        Cyprus,</span
      >
    </p>
    <p class="c4">
      <span class="c1"
        >Further contact can be made to {{ environment.policy.email }}</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <h2 class="c3" id="h.2f62mdya2gz6">
      <span class="c0">Age and legal right of access</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >Through subscription or the downloading of material on this website,
        the User warrants and confirm that they are at least 18 years of age,
        they are familiar with and know the local, federal and international
        laws in their area and / or country that affects the legal right to
        access to adult-oriented materials, the User have the legal right to
        access adult-oriented materials and {{ environment.policy.site }} has
        the legal right to transmit such content. In addition, the User warrants
        and confirms that they are accessing adult-oriented materials for their
        own private enjoyment and will not make these materials available to any
        person below the age of 18.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >All actors and actresses and authors that appear in
        {{ environment.policy.site }} are at least 18 years old at the beginning
        of production of all accessible adult content.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span
        >This website does not contain any forbidden content including real
        violence, pedophilia, zoophile, incest, people intoxicated by drugs,
        people asleep, weapons or anything else prohibited by national law.<br /><br />All
        stories and photos created &nbsp;by models and authors especially for
        {{ environment.policy.site }} and are artistic fiction.<br /><br /></span
      ><span class="c6"
        >If you notice any inappropriate content and suspect it depicts
        individuals who are underage, please report it to
        {{ environment.policy.email }}</span
      ><span class="c6"
        >,&nbsp;and we will process that report immediately.
      </span>
    </p>
    <h2 class="c3">
      <span class="c0">WARNING FOR THE USER</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >The Game offers the Game Supplementary Functionality, which is provided
        to the User upon his/her request for a fee (in-app purchase). The User
        is not obliged to use the Game Supplementary Functionality; in this case
        no money will be debited to the User’s account.</span
      >
    </p>
    <p class="c4">
      <span class="c1"
        >Game Supplementary Functionality may be offered in the form of
        subscription and provided to the User upon his/her request for a fee
        (Subscription). The Subscription will be provided to a User for a stated
        period of time and will be automatically renewed until User or Licensor
        cancels the Subscription.</span
      >
    </p>
    <p class="c4">
      <span class="c1"
        >A Subscription fee will be charged to the User's chosen payment method
        in the App Store. The amount of Subscription fee may be changed by the
        Licensor at any time at his sole discretion.</span
      >
    </p>
    <p class="c4">
      <span class="c1"
        >To stop automatic renewal of the respective Subscription and automatic
        withdrawal of Subscription fees, User must cancel the Subscription not
        later than 24 hours before it renews for the length of the next
        Subscription period. The Subscription may be cancelled at any time by
        managing the subscription settings at App Store. Instructions for
        canceling a Subscription may be found here:
        https://support.apple.com/en-us/HT202039.</span
      >
    </p>
    <p class="c4">
      <span class="c1"
        >By downloading and using the Game the User confirms that he/she is
        eligible to use the Game, including the Game Supplementary
        Functionality, besides the User confirms that we may collect and use
        technical data and related information-including but not limited to
        technical information about the device, system and application software,
        and peripherals-that is gathered periodically to facilitate the
        provision of software updates, product support, and other services to
        the User (if any) related to the EULA. We may use this information, as
        long as it is in a form that does not personally identify the User, to
        improve its products or to provide services or technologies to the
        User.</span
      >
    </p>
    <p class="c4">
      <span class="c1"
        >We shall not be liable for any actions of the User within the Game,
        including use of the Game Supplementary Functionality.</span
      >
    </p>
    <h2 class="c3" id="h.huaght9e5xnw">
      <span class="c0">Content restricted only for personal use</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >{{ environment.policy.site }} provides the content to its users
        exclusively for their own personal and non-commercial use. Users of this
        website are only permitted to use their downloaded copy for their own
        personal purposes, private and non-commercial content. We reserve the
        right to terminate the User’s rights to use the content if the Terms of
        Use or our copyright is violated.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <h2 class="c3" id="h.28d2ahlgb0ed">
      <span class="c0">Exchange of Passwords &amp; Abuse</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >As a subscriber or user via pay per download of this website, the User
        is granted a username and password that provides access to content.
        Users agree not to share or publish this information to another website
        or in any way provide access to User’s username and / or password to any
        other person or organization other than yourself. Shared passwords if
        detected will be deactivated immediately. This abuse of password and /
        or user will be reported to our processing enterprises, preventing
        further credit card use. Likewise, any violation of the copyright will
        be prosecuted to the full extent of the law.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >Exemption of responsibility for any technical failure, information or
        typographical errors</span
      >
    </p>
    <p class="c4">
      <span class="c1"
        >{{ environment.policy.site }} declines any responsibility in the case
        of interruptions or malfunctions of services of content offered on this
        website, whatever the cause. Also, {{ environment.policy.site }} will be
        not responsible for network or internet connection failure, temporary
        power supply or any other type of indirect damage that can be caused to
        users through no fault of {{ environment.policy.site }}.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >The information and services included or available into our websites
        may include inaccuracies or typographical errors. We may periodically
        incorporate changes to this information.
        {{ environment.policy.site }} may enter at any time improvements and /
        or changes in our services or content. {{ environment.policy.site }} has
        obtained the information and materials included in our websites from
        sources considered reliable, but, although they have taken the
        appropriate steps to ensure that the information is correct, does not
        guarantee its accuracy and updating. In addition to that, it is also
        noted that the contents of our websites have information purposes
        regarding the quality, situation, hosting, services and prices.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <h2 class="c3" id="h.afao16pw881f"><span class="c0">Social Media</span></h2>
    <p class="c4">
      <span class="c1"
        >{{ environment.policy.name }} as trademark and legal domain have or may
        have some open profiles on any social networks. In all this networks we
        will be responsible for the processing the data of the users, and / or
        followers which have been voluntarily included in our company profile.
        The treatment that {{ environment.policy.name }} carried out with such
        data will be the one that the social network allows us within their
        political and social network. {{ environment.policy.name }} may inform
        his followers -by any means that the network allows- about its
        activities, new products, events and personalized offers.
        {{ environment.policy.name }} do not extract any data from our social
        networks, unless we have the explicitly consent from the user for
        it.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <h2 class="c3" id="h.u08cwmbam2b">
      <span class="c0">Exemption of responsibility for any user behaviors</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >{{ environment.policy.name }} assumes no responsibility for any
        consequences derived from the behaviors and performances mentioned
        before, as well as, we do not assume any responsibility for the content,
        services, products, etc. of third parties which may be accessed directly
        or through banners, links, hyperlinks, framing or similar links from the
        websites {{ environment.policy.name }}.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >Accordingly, with the information indicated in the preceding paragraph,
        {{ environment.policy.name }} is also authorized to proceed with the
        modification or alteration of such observations, opinions or comments,
        in order to adapt them to the needs of the editorial format of our
        websites. These modifications or alterations do not presume at all any
        injury to any of the moral faculties of copyright that Users may hold
        over them.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <h2 class="c3" id="h.tn4pq0503v3e">
      <span class="c0">Intellectual and Industrial Property</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >All the contents provided by {{ environment.policy.site }} on all their
        websites ({{ environment.policy.site }}, etc...) constitute a work
        within the meaning of the legislation on intellectual property and so
        are protected by laws and international conventions.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >Any form of reproduction, distribution, public communication,
        processing, and in general, any other public act procedure referring
        both Web pages and their contents and information is prohibited without
        prior express written consent from {{ environment.policy.site }}.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >Consequently, all content published on our different websites
        specifically: videos, cinematographic works, designs, text, graphics,
        logos, icons, buttons, software, trade names, text, Web itself,
        trademarks, industrial or any other signs of industrial and commercial
        use are subject to rights of intellectual property of
        {{ environment.policy.site }} or third parties that have been authorized
        by the legal owners of this website.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >Non-compliance with the above information will be considered a serious
        illegal act and persecution will follow through civil and criminal
        law.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >Any act from the users of the services or content which exploits or
        makes commercial use, directly or indirectly, in whole or in part, of
        any content, images, forms, indexes and other formal expressions that
        forms part of our websites is forbidden without prior written permission
        of {{ environment.policy.site }}. {{ environment.policy.site }} is free
        to limit access to its websites, products and / or services offered, as
        well as the subsequent publication of the opinions, observations, images
        or comments that users can send us via e-mail or forum (unless they
        express the true and unmistakable manner otherwise).</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >{{ environment.policy.site }}, in this sense, may establish, if
        appropriate, without damages the responsibility of users, the necessary
        filters needed to avoid opinions considered racist, xenophobic,
        discriminatory, and defamatory or which in any way could generate
        violence or the propagation of contents clearly illicit or
        harmful.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4">
      <span class="c1"
        >It is forbidden for any technical, logical or technological resources
        within a third party to benefit, directly or indirectly, with or without
        profit, from each and every one of the contents, forms, indexes and
        other formal expressions that are part of our websites, or created by
        {{ environment.policy.site }}. Specifically, any link, hyperlink,
        framing or similar that can be established in any of our websites,
        without the prior writing consent of {{ environment.policy.site }} is
        prohibited. Any violation of this section shall be considered a
        grievance to the legitimate intellectual Property rights of
        {{ environment.policy.site }} on Websites and all the contents.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <h2 class="c3" id="h.rewspfvoi8wg"><span class="c0">Language</span></h2>
    <p class="c4">
      <span class="c1"
        >The main legal language established in our websites will be
        English.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <h2 class="c3" id="h.dss8pfa8qppu"><span class="c0">Copyright</span></h2>
    <p class="c4">
      <span class="c1"
        >The contents, materials, images, designs, videos and any other content
        included in this website {{ environment.policy.site }} are protected by
        national and international copyright laws and cannot be reproduced,
        transmitted, copied, edited or published in any way without the written
        permission of {{ environment.policy.site }}. The unauthorized
        reproduction, distribution or use of our content is strictly prohibited.
        Any violation, without exception, will be pursued and prosecuted to the
        fullest extent of the law of copyright, data protection and copyright
        protection.</span
      >
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <h2 class="c3" id="h.4jbjotpr8szf">
      <span class="c0">18 U.S.C. 2257 declaration of conformity</span>
    </h2>
    <p class="c4">
      <span
        >All models, actors, actresses and other persons that appear in any
        visual depiction of actual or simulated sexually explicit conduct in
        {{ environment.policy.site }} are at least eighteen (18) years old when
        the visual depictions were created. Pursuant to Section 2257 of Title
        18, United States Code, records verifying the age of all models,
        artists, actors, actresses and other persons that appear in sexually
        explicit conduct real or simulated on this website are stored and kept
        in the records of our company: P</span
      ><span class="c2"
        >rodromou 75, Oneworld Parkview House, Floor 4, 2063 Nicosia,
        Cyprus,</span
      >
    </p>
    <p class="c4 c5">
      <span class="c1"><br /><br /></span>
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4 c5"><span class="c1"></span></p>
  </div>
</div>
