<div *ngIf="offerData" class="modal-wrapper">
  <app-starter-pack-offer
    *ngIf="offerData.type === offerType.starterPack"
    (click)="purchase()"
    (closeModal)="close()"
    [offerData]="offerData"
  >
  </app-starter-pack-offer>

  <app-big-pack-offer
    *ngIf="offerData.type === offerType.bigPack"
    (click)="purchase()"
    (closeModal)="close()"
    [offerData]="offerData"
  >
  </app-big-pack-offer>

  <app-three-chats-offer
    *ngIf="offerData.type === offerType.threeChats"
    (click)="purchase()"
    (closeModal)="close()"
    [offerData]="offerData"
  >
  </app-three-chats-offer>

  <app-five-chats-offer
    *ngIf="offerData.type === offerType.fiveChats"
    (click)="purchase()"
    (closeModal)="close()"
    [offerData]="offerData"
  >
  </app-five-chats-offer>

  <app-premium-offer
    *ngIf="
      offerData.type === offerType.subscription ||
      offerData.type === offerType.subscriptionContinue ||
      offerData.type === offerType.subscriptionRenewal
    "
    (click)="purchase()"
    (closeModal)="close()"
    [offerData]="offerData"
  >
  </app-premium-offer>

  <app-summer-kickoff-small
    *ngIf="offerData.type === offerType.summerKickoffOffer"
    (click)="purchase()"
    (closeModal)="close()"
    [offerData]="offerData"
  >
  </app-summer-kickoff-small>

  <app-summer-kickoff-big
    *ngIf="offerData.type === offerType.summerKickoffOfferBig"
    (click)="purchase()"
    (closeModal)="close()"
    [offerData]="offerData"
  >
  </app-summer-kickoff-big>
</div>
