<div
  *ngIf="appearanceType === 'banner'"
  class="pcb-container offer-banner-container valentine-big-offer"
>
  <h2 [innerHTML]="'OFFERS.SEDUCTION_PACK.TITLE' | translate"></h2>
  <ul>
    <li
      [innerHTML]="'OFFERS.SEDUCTION_PACK.CHATS' | translate : { count: 5 }"
    ></li>
    <li
      [innerHTML]="'OFFERS.SEDUCTION_PACK.GEMS' | translate : { count: 14000 }"
    ></li>
    <li
      [innerHTML]="'OFFERS.SEDUCTION_PACK.RINGS' | translate : { count: 20 }"
    ></li>
  </ul>

  <div class="portraits-wrapper">
    <div class="portraits">
      <img
        [src]="offerData.chats[0].imageUrl"
        alt=""
        draggable="false"
        class="portrait"
      />
      <img
        [src]="offerData.chats[1].imageUrl"
        alt=""
        draggable="false"
        class="portrait"
      />
      <img
        [src]="offerData.chats[2].imageUrl"
        alt=""
        draggable="false"
        class="portrait"
      />
      <img
        [src]="offerData.chats[3].imageUrl"
        alt=""
        draggable="false"
        class="portrait"
      />
      <img
        [src]="offerData.chats[4].imageUrl"
        alt=""
        draggable="false"
        class="portrait"
      />
    </div>

    <span>+</span>

    <img
      [src]="
        environment.rootPath + '/assets/img/offers/valentine-day-big/ring.png'
      "
      draggable="false"
      class="ring"
    />
  </div>
  <app-price-block-old
    [offerData]="offerData"
    class="valentine-price-block"
  ></app-price-block-old>
</div>

<div
  class="pcb-container slider-modal valentine-big-offer-modal"
  *ngIf="appearanceType === 'modal'"
>
  <div class="bg">
    <h2 [innerHTML]="'OFFERS.SEDUCTION_PACK.TITLE' | translate"></h2>

    <div class="portraits-wrapper">
      <div class="portraits">
        <img
          [src]="offerData.chats[0].imageUrl"
          alt=""
          draggable="false"
          class="portrait"
        />
        <img
          [src]="offerData.chats[1].imageUrl"
          alt=""
          draggable="false"
          class="portrait"
        />
        <img
          [src]="offerData.chats[2].imageUrl"
          alt=""
          draggable="false"
          class="portrait"
        />
        <img
          [src]="offerData.chats[3].imageUrl"
          alt=""
          draggable="false"
          class="portrait"
        />
        <img
          [src]="offerData.chats[4].imageUrl"
          alt=""
          draggable="false"
          class="portrait"
        />
      </div>

      <span>+</span>

      <img
        [src]="
          environment.rootPath + '/assets/img/offers/valentine-day-big/ring.png'
        "
        draggable="false"
        class="ring"
      />
    </div>

    <ul>
      <li
        [innerHTML]="'OFFERS.SEDUCTION_PACK.RINGS' | translate : { count: 20 }"
      ></li>
      <li
        [innerHTML]="
          'OFFERS.SEDUCTION_PACK.GEMS' | translate : { count: 14000 }
        "
      ></li>
      <li
        [innerHTML]="'OFFERS.SEDUCTION_PACK.CHATS' | translate : { count: 5 }"
      ></li>
    </ul>

    <app-price-block-old
      [offerData]="offerData"
      class="valentine-price-block"
    ></app-price-block-old>
  </div>
</div>
