<div class="modal">
  <div class="modal-head">
    Subscription options
    <button class="prev" (click)="close()">
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0607 3.06066C11.6464 2.47487 11.6464 1.52513 11.0607 0.93934C10.4749 0.353553 9.52513 0.353553 8.93934 0.93934L11.0607 3.06066ZM2 10L0.93934 8.93934C0.353553 9.52513 0.353553 10.4749 0.93934 11.0607L2 10ZM8.93934 19.0607C9.52513 19.6464 10.4749 19.6464 11.0607 19.0607C11.6464 18.4749 11.6464 17.5251 11.0607 16.9393L8.93934 19.0607ZM22 11.5C22.8284 11.5 23.5 10.8284 23.5 10C23.5 9.17157 22.8284 8.5 22 8.5V11.5ZM8.93934 0.93934L0.93934 8.93934L3.06066 11.0607L11.0607 3.06066L8.93934 0.93934ZM0.93934 11.0607L8.93934 19.0607L11.0607 16.9393L3.06066 8.93934L0.93934 11.0607ZM2 11.5H22V8.5H2V11.5Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </div>

  <div class="modal-content">
    <p class="c4">
      <span class="c1"
        >Premium subscription offers $12.99 weekly subscription (with no trial)
        for unlocking secret content and features.</span
      >
    </p>
    <h2 class="c3" id="h.4x53odqxjfjb">
      <span class="c0">Subscription renewal</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >This price is for United States customers. Pricing in other countries
        may vary and actual charges may be converted to your local currency
        depending on the country of residence. Payment will be charged to iTunes
        Account at confirmation of purchase. Subscription automatically renews
        unless auto-renew is turned off at least 24-hours before the end of the
        current period. Account will be charged for renewal within 24-hours
        prior to the end of the current period, and identify the cost of the
        renewal. Subscriptions may be managed by the user and auto-renewal may
        be turned off by going to the user’s Account Settings after purchase.
        Any unused portion of a free trial period, if offered, will be forfeited
        when the user purchases a subscription to that publication, where
        applicable.
      </span>
    </p>

    <h2 class="c3" id="h.4x53odqxjfjb">
      <span class="c0">Canceling trial or subscription</span>
    </h2>
    <p class="c4">
      <span class="c1"
        >You can turn off the auto-renew for the subscription whenever you want
        to through iTunes. Check https://support.apple.com/HT202039. When your
        current trial/subscription period expires, you will be unsubscribed. The
        current active subscription period can not be canceled. After your
        subscription expires, you will no longer be able to use the Premium
        content.
      </span>
      <br />
      <br />
    </p>
    <p class="c4">
      <span class="c1"
        >If you have any questions or comments, feel free to contact us at
        {{ environment.policy.email }}.
      </span>
    </p>
  </div>
</div>
