import { OffersService } from './../../services/offers.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { UnsubscribeHelper } from '../../helpers/unsubscribe.helper';
import { OfferType } from '../../enums/offer-type.enum';
import { Logger, LoggingService } from 'ionic-logging-service';
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
})
export class OffersComponent implements OnInit, OnDestroy {
  private _subscribers = [];
  @Input() appearanceType: 'banner' | 'smallBanner' | 'modal';
  @Input() offerData: OfferData;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);
  @Input() purchased = false;
  offerType = OfferType;
  logger: Logger;
  constructor(
    private _unsubscribeHelper: UnsubscribeHelper,
    private _offersService: OffersService,
    private _loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.logger = this._loggingService.getLogger('App.OffersComponent');
  }

  purchase() {
    this.logger.entry('CLickOnPurchase', this.offerData, this.appearanceType);
    if (this.purchased) {
      return;
    }
    this.purchaseOffer();
  }

  purchaseOffer() {
    this._subscribers.push(
      this._offersService
        .purchaseOffer(this.offerData, this.appearanceType)
        .subscribe((res) => {
          if (res) {
            this.purchased = true;
            if (this.offerData.type !== this.offerType.hiddenFiveChats) {
              this.closeModal.emit(true);
            }
          }
        })
    );
  }

  emitCloseModal() {
    this.closeModal.emit(true);
  }

  ngOnDestroy() {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }
}
