import { AuthStatus } from 'src/app/shared/enums/auth-status.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { ShowRegistrationFormData } from './show-registration-form-data';
import { ModalController } from '@ionic/angular';
import { RegistrationComponent } from '../../components/registration/registration.component';
import { map, switchMap } from 'rxjs/operators';
import { ModalAnimationsService } from '../../animations/modal-animations.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private _authStatus: BehaviorSubject<AuthStatus> = new BehaviorSubject(null);
  private _isGuest: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _registrationModal: any;
  private _isShowed = false;

  set authStatus(value: AuthStatus) {
    if (value) {
      this._authStatus.next(value);
    }
  }

  get authStatus$(): Observable<AuthStatus> {
    return this._authStatus.asObservable();
  }

  set isGuest(value: boolean) {
    this._isGuest.next(value);
  }

  get isGuest$(): Observable<boolean> {
    return this._isGuest.asObservable();
  }

  // DEPRECATED. TODO Move all to showRegistrationForm$() method
  showRegistrationForm(data: ShowRegistrationFormData) {
    if (this._isShowed) {
      return;
    }
    this._isShowed = true;
    this._modalCtrl
      .create({
        component: RegistrationComponent,
        componentProps: {
          additionalData: data,
        },
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
        enterAnimation: this._modalAnimationsService.enterAnimation,
        leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
      .then((modal) => {
        this._registrationModal = modal;
        return modal.present();
      });
  }

  showRegistrationForm$(data: ShowRegistrationFormData): Observable<boolean> {
    if (this._isShowed) {
      return of(false);
    }
    this._isShowed = true;
    return from(
      this._modalCtrl.create({
        component: RegistrationComponent,
        componentProps: {
          additionalData: data,
        },
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
        enterAnimation: this._modalAnimationsService.enterAnimation,
        leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
    ).pipe(
      switchMap((modal) => {
        modal.present();
        this._registrationModal = modal;

        return from(modal.onDidDismiss()).pipe(map((res) => res.data));
      })
    );
  }

  hideRegistrationForm(action: boolean) {
    this._registrationModal?.dismiss(action);
    this._isShowed = false;
  }

  constructor(
    private _modalCtrl: ModalController,
    private _modalAnimationsService: ModalAnimationsService
  ) {}
}
