import { Injectable } from '@angular/core';
import { map, of, fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppVisibilityService {
  constructor() {}

  public waitForAppVisibility<T>(res: T): Observable<T> {
    if (!document.hidden) {
      return of(res);
    }
    return fromEvent(document, 'visibilitychange').pipe(map(() => res));
  }
}
