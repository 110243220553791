import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  HostBinding,
} from '@angular/core';
import { NavHelper } from 'src/app/shared/helpers';
import { UiStateService } from 'src/app/shared/services/comunication_services/uiStates.service';
import { UiState } from 'src/app/shared/types/UiState';
import { Subscription } from 'rxjs';
import { UnsubscribeHelper } from 'src/app/shared/helpers/unsubscribe.helper';
import { UiStates } from 'src/app/shared/enums/uiStates';

@Component({
  selector: 'app-money-counter',
  templateUrl: './money-counter.component.html',
  styleUrls: ['./money-counter.component.scss'],
})
export class MoneyCounterComponent implements OnInit, OnDestroy {
  @Input()
  @HostBinding('class.enabled-nav')
  public navigationEnabled = true;

  public diamonds: number;
  public isDiamondScaled = false;

  private _state: UiState = null;
  private _subscribers: Subscription[] = [];
  private _diamondAnimationTimer: ReturnType<typeof setTimeout>;

  constructor(
    private _navHelper: NavHelper,
    private _uiStateService: UiStateService,
    private _unsubscribeHelper: UnsubscribeHelper
  ) {}

  public ngOnInit(): void {
    this._uiStateService.updateStateData.subscribe(async (state: UiState) => {
      if (!this._state) {
        this.diamonds = state.diamonds;
      }
      if (this.diamonds !== state.diamonds) {
        this.startDiamondAnimation(state.diamonds);
      }
      this._state = state;
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this._subscribers);

    if (this._diamondAnimationTimer !== null) {
      clearTimeout(this._diamondAnimationTimer);
    }
  }

  public goToShop(): void {
    if (!this.navigationEnabled) {
      return;
    }
    this._navHelper.goToShopGems();
  }

  private startDiamondAnimation(diamonds: number): void {
    if (this._diamondAnimationTimer !== null) {
      clearTimeout(this._diamondAnimationTimer);
    }

    this.isDiamondScaled = true;

    this._diamondAnimationTimer = setTimeout(() => {
      this.isDiamondScaled = false;
      this.diamonds = diamonds;
    }, 100);
  }
}
