<div class="neon-modal">
  <button (click)="close($event)" class="close-btn">
    <img src="/assets/img/modals/close-btn-black.svg" alt="" />
  </button>

  <div class="girl-img">
    <img src="/assets/img/offers-new/stater-pack/girl.png" alt="" />
  </div>

  <div class="top">
    <h1 class="title">Starter<br />Pack</h1>

    <ul class="items">
      <li *ngFor="let item of offerData.items; let index = index" class="item">
        <img [src]="item.imageUrl" alt="" />
        {{ itemNames[index] }}
      </li>
    </ul>
  </div>

  <div class="bottom">
    <button class="neon-btn btn-cyan">
      <app-price-block [purchase]="offerData.purchase"></app-price-block>
      <div class="shapes">
        <img
          src="/assets/img/offers-new/misc/shape1.png"
          alt=""
          class="shape-1"
        />
        <img
          src="/assets/img/offers-new/misc/shape2.png"
          alt=""
          class="shape-2"
        />
        <img
          src="/assets/img/offers-new/misc/shape3.png"
          alt=""
          class="shape-3"
        />
      </div>
    </button>
    <p *ngIf="offerData.time !== null" class="time-left">
      Time left:
      <app-offer-timer [initialTime]="offerData.time"></app-offer-timer>
    </p>
  </div>
</div>
