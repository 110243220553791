import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { ShopLot } from '../../types/shop-lot-new.interface';
import { WebsocketSignalRService } from '../../services/websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { LotType } from '../../enums/lot-type.emum';
import { SoftPaymentsService } from '../../services/payments/soft-payments.service';

@Injectable({
  providedIn: 'root',
})
export class ShopBoostersService {
  private _lots = new BehaviorSubject<ShopLot[]>([]);

  public boosters$ = this._lots.pipe(
    map((lots) => lots.filter((lot) => lot.lotType !== LotType.giftPack))
  );
  public gifts$ = this._lots.pipe(
    map((lots) => lots.filter((lot) => lot.lotType === LotType.giftPack))
  );

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _softPaymentsService: SoftPaymentsService
  ) {}

  public fetchBoosters(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<ShopLot[]>(WebsocketCommandType.getBoosters, {})
      .pipe(
        take(1),
        map((res) => {
          this._lots.next(res);
          return true;
        })
      );
  }

  public purchase(lot: ShopLot): Observable<boolean> {
    return this._softPaymentsService
      .purchase({
        productId: lot.productId,
        softPurchase: lot.softPurchase,
      })
      .pipe(take(1));
  }
}
