import { IonicModule } from '@ionic/angular';
import { ChatAnswersModule } from './../chat-answers/chat-answers.module';
import { TutorialModule } from 'src/app/shared/components/tutorial/tutorial.module';
import { LottieModule } from 'ngx-lottie';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    IonicModule,
    LottieModule,
    TutorialModule,
    FormsModule,
    ChatAnswersModule,
    TranslateModule,
    AngularSvgIconModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
