import { from, map, Observable, of } from 'rxjs';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { NavHelper } from '../../helpers';
import { PushNotificationService } from './push-notification.service';

@Injectable({
  providedIn: 'root',
})
export class NativePushNotificationService {
  constructor(
    private _pushNotificationService: PushNotificationService,
    private _navHelper: NavHelper,
    private _analyticsService: AnalyticsService
  ) {}

  public requestPermissions(): Observable<boolean> {
    if (Capacitor.getPlatform() === 'web') {
      return of(false);
    }
    return from(PushNotifications.requestPermissions()).pipe(
      map((result) => {
        if (result.receive === 'granted') {
          PushNotifications.register();
          this._addListeners();
          return true;
        } else {
          return false;
        }
      })
    );
  }

  private _addListeners() {
    PushNotifications.addListener('registration', (token: Token) => {
      this._pushNotificationService.token = {
        token: token.value,
        platform: 'android',
      };
      console.log('My token: ' + JSON.stringify(token));
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Push notification Error', +JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ', JSON.stringify(notification));
        this._analyticsService.nativePushReceived(notification);
        this._navHelper.goToChat(notification?.data?.chatId);
        if (notification?.data?.chatId) {
          this._navHelper.goToChat(notification?.data?.chatId);
        } else {
          this._navHelper.goToMain();
        }
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        this._analyticsService.nativePushActionPerformed(
          notification.notification
        );
        console.log(
          'Action performed: ',
          JSON.stringify(notification.notification)
        );
        if (data?.chatId) {
          this._navHelper.goToChat(data?.chatId);
        } else {
          this._navHelper.goToMain();
        }
      }
    );
  }
}
