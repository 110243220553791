import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { PaymentAnimationStates } from '../../../enums/payment-animation-states';

@Component({
  selector: 'app-payment-animated-states',
  templateUrl: './payment-animated-states.component.html',
  styleUrls: ['./payment-animated-states.component.scss'],
})
export class PaymentAnimatedStatesComponent {
  paymentAnimationStates = PaymentAnimationStates;
  private _state: PaymentAnimationStates = null;
  @Input() set state(value: PaymentAnimationStates) {
    this._state = value;

    if (
      value === this.paymentAnimationStates.success ||
      value === this.paymentAnimationStates.error
    ) {
      setTimeout(() => {
        this._state = null;
      }, 2000);
    }
  }
  get state(): PaymentAnimationStates {
    return this._state;
  }

  paymentInProgressOptions: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/wait.json`,
    loop: true,
  };
  paymentIsSuccessOptions: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/success.json`,
    loop: false,
  };
  paymentIsErrorOptions: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/decline.json`,
    loop: false,
  };

  constructor() {}
}
