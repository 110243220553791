import { Injectable } from '@angular/core';
import { Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsubscribeHelper {
  constructor() {}

  public unsubscribe(subscribtions: any[]): void {
    for (let i = 0; i < subscribtions.length; i++) {
      subscribtions[i]?.unsubscribe();
    }
  }
}
