import { OfferData, OfferDataNew } from './../types/offer-data.interface';
import { WebsocketSignalRService } from './websocket-signalr.service';
import { Injectable } from '@angular/core';
import { OfferModalComponent } from '../components/misc-alerts/offer-modal/offer-modal.component';
import { Observable, of } from 'rxjs';
import { WebsocketCommandType } from '../enums/websocket-command-type.enum';
import { map, switchMap, take } from 'rxjs/operators';
import { OfferType } from '../enums/offer-type.enum';
import { PremiumModalComponent } from '../components/premium/modal/premium-modal.component';
import { ModalsService } from './modals.service';
import { OffersModalComponent } from '../components/offers-modal/offers-modal.component';
import { StoreItemType } from '../enums/store-Item-type.enum';
import { ItemType } from '../enums/item-type.enum';

@Injectable({
  providedIn: 'root',
})
export class OffersModalService {
  private offerShown = false;

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _modalsService: ModalsService
  ) {}

  checkPopupOffer(): Observable<boolean> {
    if (this.offerShown) {
      return of(false);
    }

    return this._websocketSignalRService
      .invoke<OfferDataNew>(WebsocketCommandType.getOffer, {})
      .pipe(
        switchMap((res) => {
          if (res) {
            const knownOfferTypes = this.getKnownOfferTypes();
            if (!knownOfferTypes.includes(res.type)) {
              return of(false);
            }

            if (
              res.type === OfferType.starterPack ||
              res.type === OfferType.bigPack ||
              res.type === OfferType.threeChats ||
              res.type === OfferType.fiveChats ||
              res.type === OfferType.subscription ||
              res.type === OfferType.subscriptionContinue ||
              res.type === OfferType.subscriptionRenewal ||
              res.type === OfferType.summerKickoffOffer ||
              res.type === OfferType.summerKickoffOfferBig
            ) {
              this.showNewOffer(res);
              this.offerShown = true;
              return of(true);
            } else {
              return of(false);
            }

            // if (
            //   res.type === OfferType.subscription ||
            //   res.type === OfferType.subscriptionContinue ||
            //   res.type === OfferType.subscriptionRenewal
            // ) {
            //   return this.showPremiumModal(res, 'checkPopupOffer');
            // } else {
            this.showOffer(res);
            return of(true);
            // }
          }
          return of(false);
        })
      );
  }

  checkOfferBanners(): Observable<OfferData[]> {
    return this._websocketSignalRService
      .invoke<OfferData[]>(WebsocketCommandType.getOffers, {})
      .pipe(
        map((res) => {
          if (res) {
            const knownOfferTypes = this.getKnownOfferTypes();
            return res.filter((el) => knownOfferTypes.includes(el.type));
          }
        })
      );
  }

  public showSubscriptionOffer(callFrom: string): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<OfferData>(WebsocketCommandType.getSubscriptionOffer, {})
      .pipe(
        take(1),
        switchMap((res) => {
          if (res) {
            return this.showPremiumModal(res, callFrom);
          } else {
            return of(false);
          }
        })
      );
  }

  showOffer(offerData: OfferData) {
    this._modalsService
      .openModal(OfferModalComponent, { offerData }, true)
      .subscribe();
  }

  private showNewOffer(offerData: OfferData) {
    this._modalsService
      .openModal(OffersModalComponent, { offerData }, true)
      .subscribe();
  }

  showPremiumModal(
    offerData: OfferData,
    callFrom: string
  ): Observable<boolean> {
    return this._modalsService
      .openModal(PremiumModalComponent, {
        offerData,
        callFrom,
      })
      .pipe(map((res) => !!res));
  }

  private getKnownOfferTypes(): string[] {
    const knownOfferTypes: string[] = [];
    for (const key in OfferType) {
      if (Object.prototype.hasOwnProperty.call(OfferType, key)) {
        knownOfferTypes.push(OfferType[key]);
      }
    }
    return knownOfferTypes;
  }
}
