import { catchError } from 'rxjs/operators';
/* eslint-disable @typescript-eslint/naming-convention */
import { AuthMethodsService } from './../../../services/auth/auth-methods.service';
import { Platform } from '@ionic/angular';
import { Component, Input, OnDestroy } from '@angular/core';
// import {
//   SocialAuthService,
//   GoogleLoginProvider,
//   SocialUser,
// } from '@abacritt/angularx-social-login';
import { from, Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { NavHelper } from 'src/app/shared/helpers';
import { AuthApiService } from 'src/app/shared/services/auth/auth-api.service';
import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';

@Component({
  selector: 'app-social-logins',
  templateUrl: './social-logins.component.html',
  styleUrls: ['./social-logins.component.scss'],
})
export class SocialLoginsComponent implements OnDestroy {
  @Input() place: 'login' | 'signup';
  googleErrorTxt = false;
  appleErrorTxt = false;
  discordErrorTxt = false;
  twitterErrorTxt = false;
  userData: any = null;
  loginGoogleSubscription: Subscription = null;
  loginAndroidGoogleSubscription: Subscription = null;
  loginAppleSubscription: Subscription = null;
  guestSubscription: Subscription = null;
  loadingAndroidGoogle = false;
  loadingGoogle = false;
  loadingApple = false;
  loadingGuest = false;
  loadingTwitter = false;
  isGoogleAuthAllow =
    this._platform.is('android') && this._platform.is('cordova');
  isAppleAuthAllow = this._platform.is('ios') && this._platform.is('cordova');
  constructor(
    private _authApiService: AuthApiService,
    private _authMethodsService: AuthMethodsService,
    // private socialAuthService: SocialAuthService,
    private googlePlus: GooglePlus,
    public _platform: Platform,
    private _navHelper: NavHelper
  ) {}

  loginWithGoogle(): void {
    // this.loadingGoogle = true;
    // this.googleErrorTxt = false;
    // this.loginGoogleSubscription = from(
    //   this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
    // )
    //   .pipe(
    //     switchMap(
    //       (res: SocialUser): Observable<boolean> =>
    //         this._authMethodsService.signUpWithGoogle(res?.idToken)
    //     ),
    //     take(1)
    //   )
    //   .subscribe({
    //     next: (res) => {
    //       this.loadingGoogle = false;
    //       this._navHelper.goToMain();
    //     },
    //     error: (error) => {
    //       this.loadingGoogle = false;
    //       this.googleErrorTxt = true;
    //     },
    //   });
  }

  loginWithApple() {
    this.loadingApple = true;
    this.appleErrorTxt = false;
    const options: SignInWithAppleOptions = {
      clientId: 'com.fgf.lovestory',
      redirectURI: '',
      scopes: 'email name',
      // state
    };

    this.loginAppleSubscription = from(SignInWithApple.authorize(options))
      .pipe(
        switchMap((res: SignInWithAppleResponse) => {
          console.log('APPLE AUTH RES', res);
          // alert(JSON.stringify(res));
          return this._authMethodsService
            .signUpWithApple(res.response)
            .pipe(take(1));
        }),
        catchError((err) => {
          console.log('APPLE AUTH RES ERR', err);
          // alert('APPLE AUTH RES ERR: ' + JSON.stringify(err));
          return null;
        })
      )
      .subscribe({
        next: () => {
          this.loadingApple = false;
          this._navHelper.goToMain();
        },
        error: (error) => {
          // alert('RES ERROR: ' + JSON.stringify(error));
          this.loadingApple = false;
          this.appleErrorTxt = true;
        },
      });
  }

  loginAndroidWithGoogle() {
    if (this._platform.is('hybrid')) {
      this.loadingAndroidGoogle = true;

      this.loginAndroidGoogleSubscription = from(
        this.googlePlus.login({
          webClientId:
            '262025504103-r90t2jebfdpa61qguoh63f5c0cml0m6a.apps.googleusercontent.com',
          //'422350541625-cad89bssaqtb2eda36sqm6fqaj3b1u26.apps.googleusercontent.com',
        })
      )
        .pipe(
          switchMap((res): Observable<boolean> => {
            // alert('Google res: ' + JSON.stringify(res));
            console.log('Google res: ' + res?.idToken);
            return this._authMethodsService.signUpWithGoogle(res?.idToken);
          }),
          take(1)
        )
        .subscribe({
          next: (res) => {
            this.loadingAndroidGoogle = false;
            this._navHelper.goToMain();
          },
          error: (error) => {
            alert('Google res err: ' + JSON.stringify(error));
            this.loadingAndroidGoogle = false;
            this.googleErrorTxt = true;
          },
        });
    }
  }

  goToDiscord(): void {
    const link = {
      url: 'https://discord.com/api/oauth2/authorize?',
      params: {
        client_id: '894944516644155482',
        response_type: 'code',
        scope: 'identify email',
        redirect_uri: window.location.origin,
      },
    };
    const params = new URLSearchParams(link.params).toString();
    window.location.href = link.url + params;
  }

  goToTwitter(): void {
    this._authApiService.getTwitterAuthToken().subscribe({
      next: (token) => {
        window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${token}`;
      },
      error: (err) => {
        this.twitterErrorTxt = true;
        this.loadingTwitter = false;
      },
    });
  }

  ngOnDestroy() {
    this.loginAndroidGoogleSubscription?.unsubscribe();
    this.loginAppleSubscription?.unsubscribe();
    this.loginGoogleSubscription?.unsubscribe();
    this.guestSubscription?.unsubscribe();
  }
}
