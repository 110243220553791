import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChatDataService } from '../services/comunication_services/chatData.service';

@Injectable({
  providedIn: 'root',
})
export class TimeConverter {
  constructor() {}

  public toMinSec(sec: number): string {
    const m = Math.floor(sec / 60);
    const s = Math.floor(sec % 60);
    const strH = m > 9 ? `${m}` : `0${m}`;
    const strm = s > 9 ? `${s}` : `0${s}`;
    const strTime = `${strH}:${strm}`;
    if (sec > 0) {
      return strTime;
    } else {
      return '00:00';
    }
  }

  public toHourMinSec(sec: number): string {
    const h = Math.floor(sec / 3600);
    const m = Math.floor((sec % 3600) / 60);
    const s = Math.floor((sec % 3600) % 60);
    const strH = h > 9 ? `${h}` : `0${h}`;
    const strM = m > 9 ? `${m}` : `0${m}`;
    const strS = s > 9 ? `${s}` : `0${s}`;
    const strTime = `${strH}:${strM}:${strS}`;
    if (sec > 0) {
      return strTime;
    } else {
      return '00:00:00';
    }
  }

  public toDaysHourMinSec(sec: number): string {
    if (sec <= 0) {
      return '00:00:00';
    }

    const d = Math.floor(sec / (60 * 60 * 24));
    const h = Math.floor((sec / 3600) % 24);
    const m = Math.floor((sec % 3600) / 60);
    const s = Math.floor((sec % 3600) % 60);
    const strD = d === 1 ? `${d}day` : `${d}days`;
    const strH = h > 9 ? `${h}` : `0${h}`;
    const strM = m > 9 ? `${m}` : `0${m}`;
    const strS = s > 9 ? `${s}` : `0${s}`;
    return d > 0
      ? `${strD} ${strH}:${strM}:${strS}`
      : `${strH}:${strM}:${strS}`;
  }
}
