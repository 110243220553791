import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-not-enough-money-popup',
  templateUrl: './not-enough-money-popup.component.html',
  styleUrls: ['./not-enough-money-popup.component.scss'],
})
export class NotEnoughMoneyPopupComponent implements OnInit {
  environment = environment;
  buildVersion = BuildVersion;
  constructor(
    private _platform: Platform,
    private _modalCtrl: ModalController,
    private _analyticsService: AnalyticsService
  ) {}

  ngOnInit() {}

  cancel() {
    this._modalCtrl.dismiss();
    this._analyticsService.notEnoughFundsDismiss();
  }

  confirm() {
    switch (this.environment.buildVersion) {
      case BuildVersion.erogames:
        this._confirmErogames();
        break;
      case BuildVersion.nutaku:
        this._confirmNutaku();
        break;
    }
  }

  private _confirmErogames() {
    const erogoldUrl = localStorage.getItem('buyErogoldUrl');
    this._analyticsService.notEnoughFundsConfirm(erogoldUrl);
    if (erogoldUrl) {
      if (this._platform.is('hybrid')) {
        Browser.open({ url: erogoldUrl });
      } else {
        window.open(erogoldUrl, '');
      }
    }
    this._modalCtrl.dismiss();
  }

  private _confirmNutaku() {
    const nutakuTopUpUrl = environment.nutakuConfig?.buyGoldUrl;
    if (this._platform.is('hybrid')) {
      Browser.open({ url: nutakuTopUpUrl });
    } else {
      window.open(nutakuTopUpUrl, '_blank');
    }
    this._modalCtrl.dismiss();
  }
}
