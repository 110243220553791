<ng-container *ngIf="data">
  <div *ngIf="data.avatarLink" class="avatar">
    <img [attr.src]="data.avatarLink" alt="" />
  </div>
  <h2 class="name">
    {{ data.name }} <img src="/assets/img/chat-page/verify.svg" alt="" />
  </h2>
  <p *ngIf="data.distance || data.occupation" class="girl-info text-gray">
    <img src="/assets/img/chat-page/location.svg" alt="" />
    {{ data.distance }} | {{ data.occupation }}
  </p>

  <div class="rating-block">
    <div>
      <div class="rating-value">
        <img src="/assets/img/star.svg" /> {{ rating?.girl | number : "1.0-1" }}
      </div>
      <div class="text-gray main-information-description-10">
        Profile page rating
      </div>
    </div>
    <div>
      <div class="rating-value">
        <img src="/assets/img/star.svg" />
        {{ rating?.story | number : "1.0-1" }}
      </div>
      <div class="text-gray main-information-description-10">
        Story’s rating
      </div>
    </div>
  </div>

  <div *ngIf="data.description || data.links?.length" class="about-block">
    <ng-container *ngIf="data.description">
      <p class="label">About me:</p>
      <p class="description text-gray" [innerHTML]="data.description"></p>
    </ng-container>
    <ng-container *ngIf="data.links?.length">
      <p class="label">Follow me:</p>
      <div class="links-block">
        <ng-container *ngFor="let link of data.links">
          <a
            *ngIf="link.linkType"
            class="promo-link"
            [class.onlyfans]="link.linkType === promoBrand.onlyfans"
            appEnterTheViewPort
            [showPromoOptions]="
              getShowLinkAnalyticOptions(link.linkType, 'Link in bio')
            "
            (click)="clickOnLinkInBio(link.linkType, 'Link in bio')"
            target="_blank"
            [href]="link.link | addUtmContentAsUtmSource"
          >
            <img
              [src]="
                '/assets/img/promo-platforms/' +
                link.linkType?.toLowerCase() +
                '.svg'
              "
            />
          </a>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="environment.showOnlineWebcamNotification"
    class="webcam-notification-block"
  >
    <div>
      <p class="title main-information-description-12">
        {{ "PROFILE_PAGE.WEBCAM_NOTIFICATIONS" | translate }}
      </p>
      <p class="description main-information-description-10 text-gray">
        {{
          "PROFILE_PAGE.NOTIFICATIONS_DESCRIPTION"
            | translate : { name: this.data?.name }
        }}
      </p>
    </div>

    <ion-toggle
      [checked]="switchNotificationsOn"
      [disabled]="false"
      (ionChange)="onlineStreamNotifications()"
    ></ion-toggle>
  </div>

  <div class="photos-block">
    <p class="photos-label">
      Photos {{ albumData?.countContent + "/" + data?.profileGallerySize }}
    </p>
    <app-photo-gallery
      [albumData]="albumData"
      [gallerySize]="data.profileGallerySize"
    ></app-photo-gallery>
  </div>

  <app-banner-slider
    *ngIf="data.chatId !== '38c62168-f1c9-467c-bd79-c7008870dcad'"
    [bannerAnalyticOptions]="
      getShowLinkAnalyticOptions(promoBrand.bannerSlider, 'End of page')
    "
  ></app-banner-slider>

  <app-discord-link
    appEnterTheViewPort
    [showPromoOptions]="
      getShowLinkAnalyticOptions(promoBrand.discord, 'End of page')
    "
  ></app-discord-link>
</ng-container>

<!-- ************************************************ -->

<ng-container *ngIf="data && false">
  <div class="profile-block">
    <div class="info-user">
      <div class="info-user__avatar">
        <img
          [attr.src]="this.data?.avatarLink"
          class="avatar-img"
          alt="ava"
          *ngIf="this.data?.avatarLink"
        />
        <div class="info-titles">
          <!-- <h2>{{this.data?.name}}</h2> -->
          <h6>
            <span class="rating-box">
              <img src="assets/img/cards-icon.svg" />
              {{ albumData?.countContent + "/" + data?.profileGallerySize }}
            </span>
            {{ "PROFILE_PAGE.PHOTOS" | translate }}
          </h6>
          <div class="links-devider"></div>
          <h6>
            <span class="rating-box">
              <img src="/assets/img/star.svg" />
              {{ rating?.girl | number : "1.0-1" }}
            </span>
            {{ "PROFILE_PAGE.RATING" | translate }}
          </h6>
          <div class="links-devider"></div>
          <h6>
            <span class="rating-box">
              <img src="/assets/img/star.svg" />
              {{ rating?.story | number : "1.0-1" }}
            </span>
            {{ "PROFILE_PAGE.STORY_RATING" | translate }}
          </h6>
        </div>
      </div>
      <div class="info-user__content">
        <p class="name" [innerHTML]="this.data?.description"></p>

        <div class="links-block" *ngIf="data.links">
          <ng-container *ngFor="let link of data.links">
            <a
              *ngIf="link.linkType"
              class="promo-link"
              [class.onlyfans]="link.linkType === promoBrand.onlyfans"
              appEnterTheViewPort
              [showPromoOptions]="
                getShowLinkAnalyticOptions(link.linkType, 'Link in bio')
              "
              (click)="clickOnLinkInBio(link.linkType, 'Link in bio')"
              target="_blank"
              [href]="link.link | addUtmContentAsUtmSource"
            >
              <img
                [src]="
                  '/assets/img/promo-platforms/' +
                  link.linkType?.toLowerCase() +
                  '.svg'
                "
              />
              <span>{{ link.linkType }}</span>
            </a>
          </ng-container>
        </div>

        <div
          *ngIf="environment.showOnlineWebcamNotification"
          class="setting-item no-shadow"
        >
          <div>
            <p class="title">
              {{ "PROFILE_PAGE.WEBCAM_NOTIFICATIONS" | translate }}
            </p>
            <p class="title-description">
              {{
                "PROFILE_PAGE.NOTIFICATIONS_DESCRIPTION"
                  | translate : { name: this.data?.name }
              }}
            </p>
          </div>

          <div class="switch-wrap">
            <ion-toggle
              [checked]="switchNotificationsOn"
              [disabled]="false"
              (ionChange)="onlineStreamNotifications()"
            ></ion-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-photo-gallery
    [albumData]="albumData"
    [gallerySize]="data.profileGallerySize"
  ></app-photo-gallery>

  <div
    class="or-visit-my-social"
    *ngIf="data.chatId === '38c62168-f1c9-467c-bd79-c7008870dcad'"
    appEnterTheViewPort
    [showPromoOptions]="onlyFansMaryRockAnalyticOptions()"
  >
    <p>
      {{ "PROFILE_PAGE.MARY_ONLYFANS" | translate }}
    </p>
    <a
      target="_blank"
      [href]="maryOnlyfansLink | addUtmContentAsUtmSource"
      (click)="clickOnOnlyfansBanner()"
      ><img src="/assets/img/onlyfans-logo.png" alt=""
    /></a>
  </div>

  <app-banner-slider
    *ngIf="data.chatId !== '38c62168-f1c9-467c-bd79-c7008870dcad'"
    [bannerAnalyticOptions]="
      getShowLinkAnalyticOptions(promoBrand.bannerSlider, 'End of page')
    "
  ></app-banner-slider>

  <ul style="margin-top: 20px">
    <li class="message-list-item ng-star-inserted">
      <a
        appEnterTheViewPort
        [showPromoOptions]="
          getShowLinkAnalyticOptions(promoBrand.discord, 'End of page')
        "
        [href]="environment.discordLink"
        target="_blank"
        class="promo-click-area"
        (click)="clickOnDiscord()"
      >
        {{ "PROFILE_PAGE.CLICK_HERE" | translate }}
      </a>
      <div class="avatar-box">
        <img
          [src]="environment.rootPath + '/assets/img/discord-blue.png'"
          alt="girl"
          class="avatar"
        />
      </div>
      <div class="message-list-item__content display-flex">
        <div class="message-list-item__head">
          <p [innerHTML]="'PROFILE_PAGE.SHARE_DISCORD' | translate"></p>
        </div>
      </div>
    </li>
  </ul>
</ng-container>
