import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { NavHelper } from 'src/app/shared/helpers';
import { Component, Input, OnInit } from '@angular/core';
import { OnlineStreamNotificationsService } from '../../services/comunication_services/online-stream-notifications.service';
import { slideInOutBottomDir } from 'src/app/shared/animations/slide-in-out.animation';

@Component({
  selector: 'app-online-stream-button',
  templateUrl: './online-stream-button.component.html',
  styleUrls: ['./online-stream-button.component.scss'],
  animations: [slideInOutBottomDir],
})
export class OnlineStreamButtonComponent implements OnInit {
  onlineStreemModalSwitchedOffInCurrentSession = false;
  @Input() chatId: string;
  constructor(
    private _navHelper: NavHelper,
    public onlineStreamNotificationsService: OnlineStreamNotificationsService,
    private _analyticsService: AnalyticsService
  ) {}

  ngOnInit() {}

  closeOnlineStreamModal() {
    this.onlineStreemModalSwitchedOffInCurrentSession = true;
  }

  goToProfile() {
    this._navHelper.goToProfile(this.chatId);
  }

  clickOnOnlineStream() {
    this._analyticsService.clickOnOnlineStream('button', this.chatId);
  }
}
