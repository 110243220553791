import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { TutorialService } from '../services/comunication_services/tutorial-new.service';

@Injectable({
  providedIn: 'root',
})
export class TutorialGuard implements CanActivate {
  constructor(private _tutorialService: TutorialService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._tutorialService.check(route, state);
  }
}
