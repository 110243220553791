import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { LegalPaymentService } from 'src/app/shared/services/payments/legal-payment.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-offer-price',
  templateUrl: './offer-price.component.html',
  styleUrls: ['./offer-price.component.scss'],
})
export class OfferPriceComponent implements OnInit, OnDestroy {
  @Input()
  public offerData: OfferData;
  public isNutaku = environment.buildVersion === BuildVersion.nutaku;
  public isErogames = environment.buildVersion === BuildVersion.erogames;
  public environment = environment;

  price: string | number = null;
  constructor(private _legalPaymentService: LegalPaymentService) {}

  public ngOnInit(): void {
    this.getPrice();
  }

  getPrice() {
    switch (environment.buildVersion) {
      case BuildVersion.default:
        this.price = `${this.offerData.purchase.cost}$`;
        break;
      case BuildVersion.nutaku:
        this.price = this.offerData.purchase.nutakuCost;
        break;
      case BuildVersion.erogames:
        this.price = this.offerData.purchase.erogamesCost;
        break;
      case BuildVersion.legal:
        this._legalPaymentService.getProductPriceByCommonId(
          this.offerData.purchase.googlePlayId
        ).pipe().subscribe(price => {
          this.price = price;
        });
      break;
    }
  }

  public ngOnDestroy(): void {}
}
