import { WebsocketSignalRService } from './../websocket-signalr.service';
import { Injectable } from '@angular/core';
import { AlbumDataService } from '../comunication_services/albumData.service';
import { GalleryDataService } from '../comunication_services/galleryData.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  constructor(
    private _galleryDataService: GalleryDataService,
    private _albumDataService: AlbumDataService,
    private _websocketSignalRService: WebsocketSignalRService
  ) {}

  public getAllGallery(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke(WebsocketCommandType.getGallery, {})
      .pipe(
        map((res: any) => {
          if (res.error !== 'Error') {
            this.saveGallery(res);
            return true;
          } else {
            return false;
          }
        })
      );
    // return new Promise<boolean>(async (resolve) => {
    //     const requestUrl = `${this._host}/api/user/gallery`;
    //     const response = await this._http.get<any>(requestUrl).toPromise();
    //     if(response.error !== 'Error') {
    //         this.saveGallery(response);
    //         resolve(true);
    //         return true;
    //     }

    // });
  }

  public getGalleryById(galleryId: string): Observable<boolean> {
    return this._websocketSignalRService
      .invoke(WebsocketCommandType.getGalleryContent, { galleryId })
      .pipe(
        map((res: any) => {
          this.saveAlbum(res);
          return true;
        })
      );

    // return new Promise<boolean>(async (resolve) => {
    //     const requestUrl = `${this._host}/api/user/gallery/${id}`;
    //     const response = await this._http.get<any>(requestUrl).toPromise();
    //     this.saveAlbum(response);
    //     resolve(true);
    //     return true;
    // });
  }

  private saveGallery(gallery: any) {
    this._galleryDataService.changeAllObject(gallery);
  }

  private saveAlbum(gallery: any) {
    this._albumDataService.changeAllObject(gallery);
  }
}
