import { ChatRatingBarModule } from './../chat-rating-bar/chat-rating-bar.module';
import { CircleDangerLevelModule } from './../circle-danger-level/circle-danger-level.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { PremiumModalModule } from '../premium/modal/premium-modal.module';
import { AnimatedCounterModule } from '../animated-counter/animated-counter.module';
import { TranslateModule } from '@ngx-translate/core';
import { MoneyCounterComponent } from './money-counter/money-counter.component';

@NgModule({
  declarations: [HeaderComponent, MoneyCounterComponent],
  imports: [
    CommonModule,
    RouterModule,
    CircleDangerLevelModule,
    ChatRatingBarModule,
    IonicModule,
    PremiumModalModule,
    AnimatedCounterModule,
    TranslateModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
