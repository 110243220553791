import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlineStreamButtonComponent } from './online-stream-button.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OnlineStreamButtonComponent],
  imports: [CommonModule, TranslateModule],
  exports: [OnlineStreamButtonComponent],
})
export class OnlineStreamButtonModule {}
