import { HttpClient } from '@angular/common/http';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';
import { Injectable } from '@angular/core';
import 'firebase/messaging';
import { BehaviorSubject, filter, switchMap, take } from 'rxjs';
import { PushNotificationToken } from '../../types/push-notification-token';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../../enums/auth-status.enum';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private _host = environment.apiUrl;
  private _token: BehaviorSubject<PushNotificationToken> = new BehaviorSubject(
    null
  );
  constructor(
    private _authStateService: AuthStateService,
    private _http: HttpClient
  ) {
    this._authStateService.authStatus$
      .pipe(
        filter((res) => res === AuthStatus.authenticated),
        take(1),
        switchMap(() => this._token.asObservable()),
        filter((token) => token !== null && token.token !== null)
      )
      .subscribe((token) => {
        this._registerToken(token);
      });
  }

  set token(token: PushNotificationToken) {
    this._token.next(token);
  }

  private _registerToken(token: PushNotificationToken) {
    const requestUrl = `${this._host}/api/User/push-quest-complete`;
    this._http
      .put<any>(requestUrl, { ...token })
      .pipe(take(1))
      .subscribe();
  }
}
