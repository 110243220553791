import { Injectable } from '@angular/core';
import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  AdLoadInfo,
  AdOptions,
  InterstitialAdPluginEvents,
  RewardAdPluginEvents,
  AdMobRewardItem,
  RewardAdOptions,
  AdMobError,
} from '@capacitor-community/admob';
import { App } from '@capacitor/app';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, from, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SettingsDataService } from './comunication_services/settingsData.sevice';
import { AnalyticsService } from './analytics/analytics.service';

export type AdPlace =
  | 'collectDailyBonus'
  | 'skipOffline'
  | 'chatFeedback'
  | 'getOneChat'
  | 'freeGems';

@Injectable({
  providedIn: 'root',
})
export class AdmobService {
  public fullscreenAdLoaded = new BehaviorSubject(false);

  private _initialized = false;
  private _logger: Logger;
  private _isTest = true;

  constructor(
    private _loggingService: LoggingService,
    private _platform: Platform,
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService
  ) {
    if (environment.showAdmobAds) {
      this.init();
      _settingsDataService.updateSettingsData.subscribe((userData) => {
        App.getInfo().then((appInfo) => {
          let lastVersion;

          if (this._platform.is('ios')) {
            lastVersion = userData.va;
          } else if (this._platform.is('android')) {
            lastVersion = userData.vg;
          }

          this._isTest =
            Number(appInfo.build) > Number(lastVersion) ||
            environment.mode === 'test';
        });
      });
    }
  }

  private async init() {
    this._logger = this._loggingService.getLogger('Admob');

    const { status } = await AdMob.trackingAuthorizationStatus();
    if (status === 'notDetermined') {
      // alert('Admob not determinated');
      console.log('Admob not determinated');
    }
    await AdMob.initialize({
      requestTrackingAuthorization: true,
      //testingDevices: ['Test_id'], //['2077ef9a63d2b398840261c8221a0c9b'],
      initializeForTesting: true,
    });

    this._initialized = true;
  }

  public async showBanner() {
    if (!this._initialized) {
      return;
    }

    const options: BannerAdOptions = {
      adId: this._isTest ? 'test-id' : environment.admob.bannerId,
      adSize: BannerAdSize.BANNER,
      isTesting: this._isTest,
    };

    await AdMob.showBanner(options);
  }

  public async showInterstitial() {
    if (!this._initialized) {
      return;
    }

    const options: AdOptions = {
      adId: this._isTest ? 'test-id' : environment.admob.interstitial,
      isTesting: this._isTest,
    };
    await AdMob.prepareInterstitial(options);
    await AdMob.showInterstitial();
  }

  public showRewardVideo(place: AdPlace): Observable<boolean> {
    if (!this._initialized) {
      return;
    }

    let isRewarded = false;

    const options: RewardAdOptions = {
      adId: this._isTest ? 'test-id' : environment.admob.rewardVideoId,
      isTesting: this._isTest,
    };

    let resolveHandler: (value: boolean) => void;
    const promise = new Promise<boolean>(
      (resolve) => (resolveHandler = resolve)
    );

    AdMob.addListener(
      RewardAdPluginEvents.Rewarded,
      (reward: AdMobRewardItem) => {
        isRewarded = true;
      }
    );

    const errorHandler = (error: AdMobError | string) => {
      if (error) {
        this._logger.error('reward video', error);
      }
      resolveHandler(false);
      alert('Something went wrong');
      this.fullscreenAdLoaded.next(false);
    };

    AdMob.addListener(RewardAdPluginEvents.Dismissed, () => {
      if (isRewarded) {
        this._analyticsService.adWatched(place);
        resolveHandler(true);
        this.fullscreenAdLoaded.next(false);
      } else {
        errorHandler('Dismissed');
      }
    });
    AdMob.addListener(RewardAdPluginEvents.FailedToLoad, errorHandler);
    AdMob.addListener(RewardAdPluginEvents.FailedToShow, errorHandler);

    this.fullscreenAdLoaded.next(true);
    AdMob.prepareRewardVideoAd(options).then(() => AdMob.showRewardVideoAd());

    return from(promise).pipe(take(1));
  }
}
