import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NavHelper } from '../../helpers';

export interface TutorialDataFromApi {
  tutorialChatId: string;
  tutorialIsPassed: boolean;
  tutorialStep: number;
}
@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  private _currentStep: number;
  private _tutorialModeOn = true;
  private _host: string = environment.apiUrl;

  // This is need for rolling back steps on some unrouting states as:
  // Photo full screen (TODO refactor), Offline timer popup and ctr.
  private rollbackStepsOnPageRefresh = [
    // {
    //   goToStep: 3,
    //   steps: [5]
    // },
    {
      goToStep: 6,
      steps: [5, 7],
    },
    {
      goToStep: 8,
      steps: [9],
    },
    {
      goToStep: 13,
      steps: [14],
    },
  ];
  private tutorialPagesStepsMap = [
    {
      page: 'goToChat',
      steps: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    },
    {
      page: 'goToMain',
      steps: [12],
    },
    {
      page: 'goToBonusDailyReweards',
      steps: [13, 14],
    },
    {
      page: 'goToShopChats',
      steps: [15, 16],
    },
  ];

  public showTutorial: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _step: BehaviorSubject<number> = new BehaviorSubject(null);
  private _isShowTips: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _tutorialIsPassed = true;

  get currentStep(): number {
    return this._currentStep;
  }

  get step$(): Observable<number> {
    return this._step?.asObservable();
  }

  get tutorialModeOn(): boolean {
    return this._tutorialModeOn;
  }

  get isShowTips$(): Observable<boolean> {
    return this._isShowTips?.asObservable();
  }

  constructor(private _navHelper: NavHelper, private _http: HttpClient) {
    this.init();
  }

  private init() {
    // TEST

    const tutorialChatId = localStorage.getItem('tutorialChatId');
    this._tutorialIsPassed = localStorage.getItem('tutorialIsPassed')
      ? true
      : false;
    if (this._tutorialIsPassed || !tutorialChatId) {
      this.disableTutorial();
      return;
    }

    const stepFromLS = localStorage.getItem('tutorialStep');
    const step = stepFromLS ? parseInt(stepFromLS, 10) : 1;
    const rollBackStep = this.rollbackStepsOnPageRefresh.filter((res) =>
      res.steps.includes(step)
    )[0]?.goToStep;
    if (rollBackStep) {
      this._sendStepToApi(rollBackStep);
      localStorage.setItem('tutorialStep', rollBackStep.toString());
    }

    this._currentStep = rollBackStep || step;

    this._step.next(this.currentStep);
    this.enableTutorial();
    const page = this.tutorialPagesStepsMap.filter((res) =>
      res.steps.includes(this._currentStep)
    )[0]?.page;

    if (!page) {
      return;
    }

    setTimeout(() => {
      if (page === 'goToChat') {
        this._navHelper.goToChat(tutorialChatId);
      } else {
        this._navHelper[page]();
      }
    }, 500);
  }

  public reInit() {
    this.init();
  }

  public setTutorialDataAndRun(tutorialData: TutorialDataFromApi) {
    localStorage.setItem('tutorialChatId', tutorialData.tutorialChatId);
    if (tutorialData.tutorialIsPassed) {
      localStorage.setItem('tutorialIsPassed', 'yes');
    }
    localStorage.setItem('tutorialStep', tutorialData.tutorialStep?.toString());
    this.init();
  }

  disableTutorial() {
    this._tutorialModeOn = false;
    this.showTutorial.next(false);
  }

  enableTutorial() {
    if (!this._tutorialIsPassed) {
      this._tutorialModeOn = true;
      this.showTutorial.next(true);
    }
  }

  nextStep(st: number) {
    this._currentStep = st;
    this._step.next(this.currentStep);
    this._sendStepToApi(st);
    localStorage.setItem('tutorialStep', this.currentStep.toString());
  }

  hideTips() {
    this._isShowTips.next(false);
  }

  showTips() {
    this._isShowTips.next(true);
  }

  finishTutorial() {
    this._currentStep = null;
    this._step.next(null);
    this.disableTutorial();
    localStorage.removeItem('tutorialStep');
    this._sendTutorialPassedToApi();
    localStorage.setItem('tutorialIsPassed', '1');
  }

  private _sendStepToApi(step: number) {
    const requestUrl = `${this._host}/api/User/set-tutorial-step?step=${step}`;
    this._http.put<any>(requestUrl, {}).pipe(take(1)).subscribe();
  }

  private _sendTutorialPassedToApi() {
    const requestUrl = `${this._host}/api/User/set-tutorial-passed`;
    this._http.put<any>(requestUrl, {}).pipe(take(1)).subscribe();
  }

  public cleanData() {
    localStorage.removeItem('tutorialStep');
    localStorage.removeItem('tutorialIsPassed');
    localStorage.removeItem('tutorialChatId');
  }
}
