import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-animated-counter',
  templateUrl: './animated-counter.component.html',
  styleUrls: ['./animated-counter.component.scss'],
})
export class AnimatedCounterComponent implements OnInit, OnDestroy {
  @Input()
  public set value(value: number) {
    if (isNaN(value)) {
      this._value = 0;
    } else {
      this._value = value;
    }
    this.startAnimation();
  }
  public get value() {
    return this._value;
  }
  @Input()
  public animationLength = 1000;
  public displayedValue: number;
  private _interval: ReturnType<typeof setInterval>;
  private _tickInterval = 17;
  private _value = 0;
  @HostBinding('class.animated')
  private _isAnimationStarted = false;

  constructor() {}

  public ngOnInit(): void {
    if (isNaN(this._value)) {
      this._value = 0;
    } else {
      this._value = this._value;
    }
  }

  public ngOnDestroy(): void {
    if (this._interval !== null) {
      clearInterval(this._interval);
    }
  }

  private startAnimation(): void {
    if (this._interval !== null) {
      clearInterval(this._interval);
    }

    if (isNaN(this.displayedValue)) {
      this.displayedValue = this._value;
      return;
    }

    const step = this._tickInterval / this.animationLength;
    const start = this.displayedValue;
    const diff = this._value - this.displayedValue;
    this._isAnimationStarted = true;
    let progress = 0;

    this._interval = setInterval(() => {
      progress += step;

      if (progress >= 1) {
        progress = 1;
        this._isAnimationStarted = false;
        clearInterval(this._interval);
      }

      this.displayedValue = Math.round(
        start + diff * this.easeInOutCubic(progress)
      );
    }, this._tickInterval);
  }

  private easeInOutCubic(x: number): number {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }
}
