import { Subscription, map, throwError, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SoundsService } from './../../services/sounds.service';
import { UiStatesEnum } from './../../enums/ui-states.enum';
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { NavHelper } from '../../helpers/nav.helper';
import { UiStateService } from '../../services/comunication_services/uiStates.service';
import { UiState } from '../../types/UiState';
import { UiStates } from '../../enums/uiStates';
import { SoundsEnum } from '../../enums/sounds.enum';
import { ModalController, Platform } from '@ionic/angular';
import { PremiumModalComponent } from '../premium/modal/premium-modal.component';
import { SubscriptionStatus } from '../../types/user-my-response.interface';
import { SettingsDataService } from '../../services/comunication_services/settingsData.sevice';
import { UnsubscribeHelper } from '../../helpers/unsubscribe.helper';
import { ModalAnimationsService } from '../../animations/modal-animations.service';
import {
  TutorialService,
  TutorialStep,
} from '../../services/comunication_services/tutorial-new.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public state: UiState = null;
  public uiStatesEnum = UiStatesEnum;
  public stateEnum = UiStates;
  environment = environment;
  tutorialStepsThatAvoidBackAndProfileButtons = [1, 2, 6, 8, 10];
  private _isPremiumModalInstanceOpen = false;
  public subscriptionStatus: SubscriptionStatus;
  public subscriptionStatuses = SubscriptionStatus;
  private _subscribers: Subscription[] = [];
  public isIos = this._platform.is('ios') && !this._platform.is('mobileweb');
  public isTopSpacer = this.isIos;

  public tutorialStep = this._tutorialService.currentStep;
  public isChatBlurred = this._tutorialService.isChatBlurred;

  constructor(
    private _navHelper: NavHelper,
    private _uiStateService: UiStateService,
    private cdr: ChangeDetectorRef,
    private soundsService: SoundsService,
    private _tutorialService: TutorialService,
    private _modalController: ModalController,
    private _settingsDataService: SettingsDataService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _modalAnimationsService: ModalAnimationsService,
    private _platform: Platform
  ) {}

  public ngOnInit() {
    this._uiStateService.updateStateData.subscribe(async (res: UiState) => {
      this.state = res;
      this.cdr.detectChanges();
    });

    this._subscribers.push(
      this._settingsDataService.updateSettingsData.subscribe((res) => {
        this.subscriptionStatus = res.subscriptionStatus;
      })
    );
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }

  public playPing1() {
    this.soundsService.play(SoundsEnum.coins);
  }

  public back(): void {
    if (this.state[UiStatesEnum.state] === 'chat') {
      this._navHelper.goToCards();
    } else if (this.state[UiStatesEnum.state] === 'album') {
      this._navHelper.goToGallery();
    } else if (this.state[UiStatesEnum.state] === 'profile') {
      this._navHelper.goToChat(this.state[UiStatesEnum.chatId]);
    }
  }

  public gotoProfile(chatId: string) {
    if (!chatId) {
      throwError('Cannot go to Profile. No ChatID.');
    }

    this._navHelper.goToProfile(chatId);
  }

  public async openPremiumModal(): Promise<void> {
    if (this._isPremiumModalInstanceOpen) {
      return;
    }

    this._isPremiumModalInstanceOpen = true;
    const premiumModalInstance = await this._modalController.create({
      component: PremiumModalComponent,
      swipeToClose: true,
      showBackdrop: true,
      cssClass: 'transparent-modal',
      componentProps: {
        callFrom: 'header',
      },
      enterAnimation: this._modalAnimationsService.enterAnimation,
      leaveAnimation: this._modalAnimationsService.leaveAnimation,
    });

    premiumModalInstance.present();

    await premiumModalInstance.onDidDismiss();
    this._isPremiumModalInstanceOpen = false;
  }
}
