import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lifetime-offer',
  templateUrl: './lifetime.component.html',
  styleUrls: ['./lifetime.component.scss'],
})
export class LifetimeOfferComponent implements OnInit {
  @Input() offerData: OfferData;
  @Input() appearanceType: 'modal' | 'banner' | 'smallBanner';
  @Input() purchased: boolean;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);

  public environment = environment;

  constructor() {}

  ngOnInit() {}

  close() {
    this.closeModal.emit(true);
  }
}
