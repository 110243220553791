<!-- BANNER EXCLUSIVE -->
<div
  *ngIf="appearanceType === 'banner'"
  class="pcb-container offer-banner-container new-year-offer"
>
  <div class="bg-wrapper">
    <img
      [src]="environment.rootPath + '/assets/img/offers/new-year/snow-bg.svg'"
      draggable="false"
      class="bg-snow"
    />
    <img
      [src]="environment.rootPath + '/assets/img/offers/new-year/flare.png'"
      draggable="false"
      class="flare"
    />
    <div class="girl-ball first-girl">
      <div>
        <img
          [src]="
            environment.rootPath + '/assets/img/offers/new-year/girls/1.png'
          "
          draggable="false"
        />
      </div>
    </div>
    <div class="girl-ball second-girl">
      <div>
        <img
          [src]="
            environment.rootPath + '/assets/img/offers/new-year/girls/2.png'
          "
          draggable="false"
        />
      </div>
    </div>
  </div>

  <h2 class="title">MERRY <span>CHRISTMAS!</span></h2>
  <p class="description">2 hot holiday chats<br />and 500 gems.</p>

  <app-price-block-old
    [offerData]="offerData"
    class="new-year-price-block"
  ></app-price-block-old>

  <div class="sale-block">
    <span class="sale">SALE</span>
    <span class="percents">{{ offerData?.purchase.discount }}%</span>
    <span class="ring"></span>
  </div>
</div>

<div
  class="pcb-container slider-modal new-year-offer-modal"
  *ngIf="appearanceType === 'modal'"
>
  <div class="head">
    <img
      [src]="environment.rootPath + '/assets/img/offers/new-year/snow-bg.svg'"
      draggable="false"
      class="bg-snow"
    />
    <div class="girl-ball first-girl">
      <div>
        <img
          [src]="
            environment.rootPath + '/assets/img/offers/new-year/girls/1.png'
          "
          draggable="false"
        />
      </div>
    </div>
    <div class="girl-ball second-girl">
      <div>
        <img
          [src]="
            environment.rootPath + '/assets/img/offers/new-year/girls/2.png'
          "
          draggable="false"
        />
      </div>
    </div>
    <div class="title-wrapper">
      <img
        [src]="
          environment.rootPath +
          '/assets/img/offers/new-year/santa-claus-hat.svg'
        "
        draggable="false"
        class="hat"
      />

      <h1 class="title">MERRY<br /><span>CHRISTMAS!</span></h1>
    </div>
    <p>2 hot holiday chats<br />and 500 gems.</p>
  </div>
  <app-price-block-old
    [offerData]="offerData"
    class="new-year-price-block"
  ></app-price-block-old>
</div>
