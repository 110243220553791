<div
  *ngIf="appearanceType === 'banner'"
  class="pcb-container offer-banner-container valentine-small-offer"
>
  <h2 [innerHTML]="'OFFERS.SEDUCTION_PACK.TITLE' | translate"></h2>
  <ul>
    <li
      [innerHTML]="'OFFERS.SEDUCTION_PACK.CHATS' | translate : { count: 2 }"
    ></li>
    <li
      [innerHTML]="'OFFERS.SEDUCTION_PACK.GEMS' | translate : { count: 2000 }"
    ></li>
    <li
      [innerHTML]="'OFFERS.SEDUCTION_PACK.RINGS' | translate : { count: 10 }"
    ></li>
  </ul>

  <div class="portraits-wrapper">
    <img
      [src]="offerData.chats[0].imageUrl"
      alt=""
      draggable="false"
      class="portrait"
    />
    <img
      [src]="
        environment.rootPath +
        '/assets/img/offers/valentine-day-small/bottle.png'
      "
      draggable="false"
      class="bottle"
    />
    <img
      [src]="offerData.chats[1].imageUrl"
      alt=""
      draggable="false"
      class="portrait"
    />
  </div>
  <app-price-block-old
    [offerData]="offerData"
    class="valentine-price-block"
  ></app-price-block-old>
</div>

<div
  class="pcb-container slider-modal valentine-small-offer-modal"
  *ngIf="appearanceType === 'modal'"
>
  <div class="bg">
    <h2 [innerHTML]="'OFFERS.SEDUCTION_PACK.TITLE' | translate"></h2>

    <div class="portraits-wrapper">
      <img
        [src]="offerData.chats[0].imageUrl"
        alt=""
        draggable="false"
        class="portrait"
      />
      <img
        [src]="
          environment.rootPath +
          '/assets/img/offers/valentine-day-small/bottle.png'
        "
        draggable="false"
        class="bottle"
      />
      <img
        [src]="offerData.chats[1].imageUrl"
        alt=""
        draggable="false"
        class="portrait"
      />
    </div>

    <ul>
      <li
        [innerHTML]="'OFFERS.SEDUCTION_PACK.RINGS' | translate : { count: 10 }"
      ></li>
      <li
        [innerHTML]="'OFFERS.SEDUCTION_PACK.GEMS' | translate : { count: 2000 }"
      ></li>
      <li
        [innerHTML]="'OFFERS.SEDUCTION_PACK.CHATS' | translate : { count: 2 }"
      ></li>
    </ul>

    <app-price-block-old
      [offerData]="offerData"
      class="valentine-price-block"
    ></app-price-block-old>
  </div>
</div>
