import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import {
  NotificationType,
  UserNotification,
} from '../../types/user-my-response.interface';
// eslint-disable-next-line max-len
import { CommonNotificationModalComponent } from '../../components/misc-alerts/common-notification-modal/common-notification-modal.component';
import { ModalAnimationsService } from '../../animations/modal-animations.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private _modalCtrl: ModalController,
    private _modalAnimationsService: ModalAnimationsService
  ) {}

  private _notificationArray: UserNotification[] = [];

  public init(): void {
    // this._localNotifications.schedule({
    //     id: 1,
    //     text: 'Your girlfriends are waiting for you!',
    //     trigger: {at: new Date(new Date().getTime() + 86400000)}
    // });
  }

  public addNotifications(notification: UserNotification[]) {
    if (!notification) {
      return;
    }
    this._notificationArray = notification;

    this._notificationArray.forEach((n) => {
      if (n.notificationType === NotificationType.text) {
        this.showNotification(n);
      }
    });
  }

  showNotification(notification: UserNotification) {
    this._modalCtrl
      .create({
        component: CommonNotificationModalComponent,
        componentProps: {
          notification,
        },
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
        enterAnimation: this._modalAnimationsService.enterAnimation,
        leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
      .then((modal) => modal.present());
  }
}
