import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  map,
  switchMap,
  take,
  timer,
} from 'rxjs';
import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';
import { DailyQuest, DailyQuestsResponse } from './daily-quests.interface';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { AdCollectCoins } from 'src/app/shared/types/ads.interface';
import { UiStateService } from 'src/app/shared/services/comunication_services/uiStates.service';
import { UiStatesEnum } from 'src/app/shared/enums/ui-states.enum';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { ToastsService } from '../../services/toasts.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class DailyQuestsService {
  public dailyData: BehaviorSubject<DailyQuestsResponse> = new BehaviorSubject(
    null
  );
  private _updateTimer: Subscription;

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _uiStateService: UiStateService,
    private _analyticsService: AnalyticsService,
    private _toastsService: ToastsService,
    private _translateService: TranslateService
  ) {}

  public fetchQuests(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<DailyQuestsResponse>(WebsocketCommandType.getDailyQuests, {})
      .pipe(
        take(1),
        map((res) => {
          this.dailyData.next(res);
          this._updateTimer?.unsubscribe();
          this._updateTimer = timer(res.time * 1000).subscribe(() =>
            this.fetchQuests()
          );

          return true;
        })
      );
  }

  public claim(quest: DailyQuest): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<AdCollectCoins>(WebsocketCommandType.getDailyQuestReward, {
        id: quest.id,
      })
      .pipe(
        switchMap((res) => {
          this._analyticsService.claimDailyQuest(quest.type);
          return this.applyReward(res);
        })
      );
  }

  public claimAll(): Observable<any> {
    return this._websocketSignalRService
      .invoke<AdCollectCoins>(WebsocketCommandType.getDailyQuestDayReward, {})
      .pipe(
        switchMap((res) => {
          this._analyticsService.claimAllDailyQuests();
          return this.applyReward(res);
        })
      );
  }

  private applyReward(reward: AdCollectCoins): Observable<boolean> {
    this._toastsService.showMessage(
      this._translateService.instant('BONUS_PAGE.COINS_RECEIVED', {
        value: reward.difference,
      })
    );
    this._uiStateService.changeData(UiStatesEnum.diamonds, reward.coins);
    return this.fetchQuests();
  }
}
