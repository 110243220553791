import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  OfferData,
  OfferDataNew,
} from 'src/app/shared/types/offer-data.interface';

@Component({
  selector: 'app-five-chats-offer',
  templateUrl: './five-chats.component.html',
  styleUrls: ['./five-chats.component.scss'],
})
export class FiveChatsComponent implements OnInit {
  @Input() public offerData: OfferDataNew;
  @Output() public closeModal: EventEmitter<boolean> = new EventEmitter(false);

  constructor() {}

  public ngOnInit() {}

  public close(e: Event) {
    e.stopPropagation();
    this.closeModal.emit(true);
  }
}
