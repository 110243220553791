import {
  PromptsInChatService,
  PromptsInChatType,
} from './../../services/prompts-in-chat.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { NextMessage } from 'src/app/shared/types/next-message.interface';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, map, take } from 'rxjs';
import { ChatMessageType } from '../../enums/chat-message-type.enum';
import { FooterChatState } from '../../enums/footer-chat-state.enum';
import { NavHelper } from '../../helpers';
import { MessageParser } from '../../helpers/messageParser';
import { TimeConverter } from '../../helpers/timeConverter';
import { UnsubscribeHelper } from '../../helpers/unsubscribe.helper';
import { ChatService } from '../../services/API_services/chat.service';
import { ChatDataService } from '../../services/comunication_services/chatData.service';
import { NewMessageService } from '../../services/comunication_services/new-message.service';
import { NextMessageEmitterService } from '../../services/comunication_services/nextMessageEmitter.service';
import { SettingsDataService } from '../../services/comunication_services/settingsData.sevice';
import { NicknameService } from '../../services/nickname.service';
import { ChatData } from '../../types/chat-data.interface';
import { NextMessageAnswer } from '../../types/next-message.interface';
import { OpenNextMessage } from '../../types/open-next-message.interface';
import { SetChatStateForFooterData } from '../footer/set-chat-state-for-footer-data.interface';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { environment } from 'src/environments/environment';
import { AnimationOptions } from 'ngx-lottie';
import { TranslateService } from '@ngx-translate/core';
import {
  TutorialService,
  TutorialStep,
} from '../../services/comunication_services/tutorial-new.service';

export enum CustomMessageId {
  agePrompt = 'AGE_PROMPT',
  agreementPrompt = 'AGREEMENT_PROMPT',
}

@Component({
  selector: 'app-chat-answers',
  templateUrl: './chat-answers.component.html',
  styleUrls: ['./chat-answers.component.scss'],
})
export class ChatAnswersComponent implements OnInit, OnDestroy {
  @ViewChild('nicknameInput')
  public nicknameInput: ElementRef;
  currentChatId: string = null;
  private _currentMessageId: string = null;
  public time = '00:00';
  private subscribers: any[] = [];
  readonly writeNicknamePhrase = this._translateService.instant(
    'CHATS_PAGE.WRITE_YOUR_NICKNAME'
  );
  public currentNickname: string;
  public enterNicknameFieldtext = '';
  public readonly environment = environment;
  isWriteYourNickname = false;
  isWriteYourAge = false;
  private _lastMessage: NextMessage;
  public inputPlaceholderEnabled = true;

  answersEmpty: NextMessageAnswer[] = [
    {
      id: null,
      messageText: '',
      costInGems: 0,
    },
  ];

  answers: NextMessageAnswer[] = this.answersEmpty;
  chatState: FooterChatState = null;
  footerChatState = FooterChatState;
  timeSTR = '00:00:00';
  private timer: any;

  public tutorialStep = this._tutorialService.currentStep;

  constructor(
    private _navHelper: NavHelper,
    private _nextMessageEmitterService: NextMessageEmitterService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _chatDataService: ChatDataService,
    private _timeConverter: TimeConverter,
    private _chatService: ChatService,
    public messageParser: MessageParser,
    private _newMessageService: NewMessageService,
    private _nicknameService: NicknameService,
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService,
    private _translateService: TranslateService,
    private _promtsInChatService: PromptsInChatService,
    private _tutorialService: TutorialService,
    private _changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscribers.push(
      this._chatDataService.updateChatData.subscribe((res: ChatData) => {
        // Predefine false
        this.isWriteYourNickname = false;

        if (!res || !res.messages || !res.messages.length) {
          return;
        }

        this._lastMessage =
          res.messages && res.messages[res.messages.length - 1];

        if (
          this._lastMessage?.type === ChatMessageType.hero &&
          this._lastMessage.messageText
        ) {
          // If Hero has text filled means that he already answered. We don;t need to show the answer.
          return;
        }

        this.currentChatId = res.id;
        this._currentMessageId = this._lastMessage.id;

        if (this._lastMessage?.type === ChatMessageType.hero) {
          this._tutorialService.isAnswersVisible.next(true);
          this.answers = this._lastMessage.answers;

          // Hack coz strange problem with this._chatDataService.updateBlockData subscription. DOesn't work properly
          this.setChatState(null);
        } else {
          this.answers = this.answersEmpty;
          // Hack coz strange problem with this._chatDataService.updateBlockData subscription. DOesn't work properly
          this.setChatState(null);
        }

        // SHOW NICKNAME INPUT
        if (!this._settingsDataService.nicknameIsSet) {
          if (this._lastMessage.showNickNameInput) {
            this._analyticsService.showNicknameInput(this.currentChatId);
            this.isWriteYourNickname = true;
            this._nicknameService
              .getNicknameFromPlatform()
              .subscribe((nickName) => {
                if (nickName) {
                  this.currentNickname = nickName;
                } else {
                  if (environment.buildVersion === BuildVersion.default) {
                    this.currentNickname = 'Pink Bunny';
                  }
                }
              });
          }
        }

        // SHOW AGE AND AGREENENTT PROMPT
        if (
          this._currentMessageId === CustomMessageId.agePrompt ||
          this._currentMessageId === CustomMessageId.agreementPrompt
        ) {
          if (this._lastMessage.isAgeQuestion) {
            this._analyticsService.showAgeInput(this.currentChatId);
          }
          if (this._lastMessage.isAgreementQuestion) {
            this._analyticsService.showAgreementInput(this.currentChatId);
          }
        }

        // this.setChatState({
        //   isOffline: res.isOffline,
        //   isAdditionalFinished: res.isAdditionalFinished,
        //   offlineTime: res.offlineTime,
        // });
      })
    );
    this.subscribers.push(
      this._chatDataService.updateBlockData.subscribe(
        (blockData: OpenNextMessage) => {
          if (blockData) {
            this.setChatState({
              isOffline: blockData.isOffline,
              isAdditionalFinished:
                (blockData.isAdditionalFinished ||
                  !blockData.hasAdditionalContent) &&
                blockData.isFinished,
              offlineTime: blockData.offlineTime,
            });
          }
        }
      )
    );
  }

  setChatState(data: SetChatStateForFooterData) {
    if (data?.isAdditionalFinished) {
      this.chatState = FooterChatState.lastMessage;
    } else if (data?.isOffline) {
      this.chatState = FooterChatState.offline;
      this.setOfflineTimer(data);
    } else {
      this.chatState = FooterChatState.chat;
    }
  }

  setOfflineTimer(data: SetChatStateForFooterData) {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timeSTR = this._timeConverter.toHourMinSec(data.offlineTime);
    this.timer = setInterval(() => {
      if (data.offlineTime > 0) {
        data.offlineTime -= 1;
        this.timeSTR = this._timeConverter.toHourMinSec(data.offlineTime);
        if (data.offlineTime === 0) {
          // this.outButton.emit();
        }
      } else {
        clearInterval(this.timer);
        this.timeSTR = '00:00:00';
        this._newMessageService.toggleState(true);
        this.getNextMassage();
      }
    }, 1000);
  }

  public getNextMassage() {
    this._nextMessageEmitterService.changeData('clickOnAnswer');
  }

  public clickAnswer(answer: NextMessageAnswer) {
    // If message is custom like setting Age or setting Agreement Confirm -> move to custom cases instead submitting an answer.
    if (
      Object.values(CustomMessageId).includes(
        this._currentMessageId as CustomMessageId
      )
    ) {
      this.handleCustomQuestionAnswering(answer);
      return;
    }
    if (answer.id === null) {
      this._nextMessageEmitterService.changeData('clickOnAnswer');
      return;
    }

    this._chatService
      .clickOnAnswer(answer, this._currentMessageId, this.currentChatId)
      .subscribe((res) => {
        if (this.tutorialStep.value !== null) {
          switch (this._lastMessage.orderIdx) {
            case 3:
              this._tutorialService.setStep('select-answer');
              break;
            case 5:
              this._tutorialService.setStep('rating-tutor');
              break;
            case 8:
              this._tutorialService.setStep('wait-for-end-first-chat');
              break;
          }
        }
      });
    this._tutorialService.isAnswersVisible.next(false);
    this.answers = this.answersEmpty;
  }

  public handleCustomQuestionAnswering(answer: NextMessageAnswer) {
    if (answer.id < 0) {
      // < 0 => negative answer
      // Goto legal stores
      this._analyticsService.ageIsSet(false);
      setTimeout(() => (location.href = 'https://lovechats.app/'), 1000); //  Settimout to set analytics before redirect
    } else {
      let promptType: PromptsInChatType;

      switch (this._currentMessageId) {
        case CustomMessageId.agePrompt:
          promptType = PromptsInChatType.ageOver18;
          break;
        case CustomMessageId.agreementPrompt:
          promptType = PromptsInChatType.agreement;
          break;
        default:
          return;
      }

      this.subscribers.push(
        this._promtsInChatService
          .setPrompt(promptType)
          .pipe(take(1))
          .subscribe((res) => {
            this._chatDataService.updateHeroLastMessageText(
              this._lastMessage.id,
              answer.messageText
            );
            this._nextMessageEmitterService.changeData('clickOnAnswer');
          })
      );
    }
  }

  public backToChatsList() {
    this._navHelper.goToMain();
  }

  public onWriteNicknameFocus() {
    // this.enterNicknameFieldtext = '';
    this.inputPlaceholderEnabled = false;
    this._changeDetection.detectChanges();
    this.nicknameInput.nativeElement.focus();
  }
  public onWriteNicknameBlur() {
    if (this.enterNicknameFieldtext === '') {
      this.inputPlaceholderEnabled = true;
      // this.enterNicknameFieldtext = this.writeNicknamePhrase;
    }
  }

  public submitNickname(nickName, handWrited = false) {
    if (nickName === '') {
      return;
    }

    if (
      handWrited &&
      this.enterNicknameFieldtext === this.writeNicknamePhrase
    ) {
      return;
    }

    this._nicknameService
      .set(nickName, handWrited)
      .pipe(take(1))
      .subscribe(() => {
        this._nextMessageEmitterService.changeData('clickOnAnswer');
        this._chatDataService.updateHeroLastMessageText(
          this._lastMessage.id,
          nickName
        );
      });
  }

  public tutorialGoToSwipes() {
    this._tutorialService.setStep('swipe');
    this._navHelper.goToCards();
  }

  ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this.subscribers);
  }
}
