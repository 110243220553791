export enum WebsocketCommandType {
  unknown = 'Unknown',
  chooseAnswer = 'ChooseAnswer',
  myChat = 'MyChat',
  myChats = 'MyChats',
  chatFeedback = 'ChatFeedback',
  useGift = 'UseGift',
  useGiftFromChat = 'UseGiftFromChat',
  getMyGifts = 'GetMyGifts',
  buySoftChat = 'BuySoftChat',
  unban = 'Unban',
  buyAddOns = 'BuyAddOns',
  skipOffline = 'SkipOffline',
  getPromoCode = 'GetPromoCode',
  activatePromoCode = 'ActivatePromoCode',
  updateUserSettings = 'UpdateUserSettings',
  updateAvatar = 'UpdateAvatar',
  myUser = 'MyUser',
  editName = 'EditName',
  getGallery = 'GetGallery',
  getGalleryContent = 'GetGalleryContent',
  addToGallery = 'AddToGallery',
  getRewards = 'GetRewards',
  collectReward = 'CollectReward',
  getTimeReward = 'GetTimeReward',
  setTutorialStep = 'SetTutorialStep',
  setTutorialPassed = 'SetTutorialPassed',
  getQuests = 'GetQuests',
  takeQuestReward = 'TakeQuestReward',
  manualQuestCompletion = 'ManualQuestCompletion',
  pushNotificationQuestCompletion = 'PushNotificationQuestCompletion',
  addQuestProgress = 'AddQuestProgress',
  getChatsAccessibility = 'GetChatsAccessibility',
  changeChatAccessibility = 'ChangeChatAccessibility',
  nextMessages = 'NextMessages',
  coins = 'Coins',
  getCardSet = 'GetCardSet',
  buyCardSet = 'BuyCardSet',
  buyOneCard = 'BuyOneCard',
  getChatLots = 'GetChatLots',
  getChatPacks = 'GetChatPacks',
  error = 'Error',
  reactCard = 'ReactCard',
  getOffer = 'GetOffer',
  getOffers = 'GetOffers',
  getSubscriptionOffer = 'GetSubscriptionOffer',
  readNotification = 'ReadNotification',
  getOnlineStripChat = 'GetOnlineStripChat',
  getStoreLots = 'GetStoreLots',
  getShopAdReward = 'GetShopAdReward',
  collectAdReward = 'CollectAdReward',
  setLanguage = 'SetLanguage',
  getChatRewardInfo = 'GetChatRewardInfo',
  confirmAge = 'ConfirmAge',
  acceptAgreement = 'AcceptAgreement',
  getDailyQuests = 'GetDailyQuests',
  getDailyQuestReward = 'GetDailyQuestReward',
  getDailyQuestDayReward = 'GetDailyQuestDayReward',
  getBoosters = 'GetBoosters',
  getGems = 'GetGems',
  getPacks = 'GetPacks',
  getDailyRewards = 'GetDailyRewards',
  collectDailyReward = 'CollectDailyReward',
  getSubscriptionStatus = 'GetSubscriptionStatus',
}
