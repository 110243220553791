<!-- BANNER EXCLUSIVE -->
<div
  *ngIf="appearanceType === 'banner'"
  class="pcb-container offer-banner-container three-german-chats-banner"
>
  <!-- <app-offer-timer
    *ngIf="offerData.time"
    [initialTimer]="offerData.time"
    class="offer-timer"
  ></app-offer-timer> -->

  <div class="text-top">
    <span [innerHTML]="'Sie sprechen Deutsch!'"></span>
  </div>

  <div class="text-bottom">
    <span [innerHTML]="'3 Chats + 400 Diamanten + 10 Präsent' | translate"></span>
  </div>

  <div class="price-container">
    <button class="btn red-hot-button"><app-offer-price [offerData]="offerData"></app-offer-price></button>
  </div>
</div>

<div
  class="pcb-container slider-modal three-german-chats-modal"
  *ngIf="appearanceType === 'modal'"
>

  <div class="bg-container">
    <div class="text-top">
      <span [innerHTML]="'Sie sprechen Deutsch!' | translate"></span>
    </div>

    <div class="text-bottom">
      <span [innerHTML]="'3 Chats + 400 Diamanten + 10 Präsent' | translate"></span>
    </div>
  </div>
  <!-- <app-offer-timer
    *ngIf="offerData.time"
    [initialTimer]="offerData.time"
    class="offer-timer"
  ></app-offer-timer> -->

  <div class="price-container">
    <button class="btn red-hot-button"><app-offer-price [offerData]="offerData"></app-offer-price></button>
  </div>







</div>
