import { Injectable } from '@angular/core';
import { SettingsDataService } from './settingsData.sevice';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ModalsService } from '../modals.service';
import { TutorialPaywallModalComponent } from '../../components/tutorial-new/paywall-modal/tutorial-paywall-modal.component';
import { BehaviorSubject, combineLatest, map, switchMap, take } from 'rxjs';
import { RouteName } from '../../enums/route-name.enum';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { OfferData } from '../../types/offer-data.interface';
import { AnalyticsService } from '../analytics/analytics.service';

const tutorialSteps = [
  'first-paywall',
  'chat-first-message',
  'select-answer',
  'rating-tutor',
  'wait-for-end-first-chat',
  'swipe',
] as const;
export type TutorialStep = typeof tutorialSteps[number];

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  public currentStep: BehaviorSubject<TutorialStep> = new BehaviorSubject(null);
  public isAnswersVisible = new BehaviorSubject(false);
  public isChatBlurred = combineLatest([
    this.isAnswersVisible,
    this.currentStep,
  ]).pipe(
    map(
      ([isAnswersVisible, step]) => step === 'rating-tutor' && isAnswersVisible
    )
  );

  private readonly _localStorageKey = 'tutorialStep';
  private _tutorialChatId: string;
  private _initialized: boolean;

  constructor(
    private _settingsDataService: SettingsDataService,
    private _modalsService: ModalsService,
    private _router: Router,
    private _websocketSignalRService: WebsocketSignalRService,
    private _analyticsService: AnalyticsService
  ) {}

  public check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this._initialized) {
      this.init();
      this._initialized = true;
    }

    if (!this.currentStep.value) {
      return true;
    }

    switch (this.currentStep.value) {
      case 'first-paywall':
        return this._websocketSignalRService
          .invoke<OfferData>(WebsocketCommandType.getSubscriptionOffer, {})
          .pipe(
            switchMap((res) =>
              this._modalsService
                .openModal<TutorialPaywallModalComponent>(
                  TutorialPaywallModalComponent,
                  { offerData: res }
                )
                .pipe(
                  map(() => {
                    this.setStep('chat-first-message');
                    return this._router.parseUrl(
                      `${RouteName.allChats}/${this._tutorialChatId}`
                    );
                  })
                )
            )
          );
      case 'chat-first-message':
      case 'select-answer':
      case 'rating-tutor':
      case 'wait-for-end-first-chat':
        if (state.url !== `/${RouteName.allChats}/${this._tutorialChatId}`) {
          return this._router.parseUrl(
            `${RouteName.allChats}/${this._tutorialChatId}`
          );
        }
        return true;
      case 'swipe':
        if (state.url !== `/${RouteName.swipingChatCards}`) {
          return this._router.parseUrl(RouteName.swipingChatCards);
        }
        return true;
    }

    const check: never = this.currentStep.value;
  }

  public setStep(step: TutorialStep) {
    console.log(`set tutorial step: ${step}`);
    this._analyticsService.tutorialStepCompleted(this.currentStep.value);
    this.currentStep.next(step);
    localStorage.setItem(this._localStorageKey, step);
  }

  public after(current: TutorialStep, step: TutorialStep): boolean {
    return tutorialSteps.indexOf(current) > tutorialSteps.indexOf(step);
  }

  public before(current: TutorialStep, step: TutorialStep): boolean {
    return tutorialSteps.indexOf(current) < tutorialSteps.indexOf(step);
  }

  public finishTitorial() {
    this._websocketSignalRService
      .invoke(WebsocketCommandType.setTutorialPassed, {})
      .pipe(take(1))
      .subscribe(() => {
        this.setStep(null);
        this._analyticsService.tutorialCompleted();
      });
  }

  private init() {
    const isActive =
      !this._settingsDataService.updateSettingsData.value.tutorialIsPassed;

    if (!isActive) {
      return;
    }

    this._tutorialChatId =
      this._settingsDataService.updateSettingsData.value.tutorialChatId;

    let savedStep = localStorage.getItem(this._localStorageKey) as TutorialStep;

    if (!tutorialSteps.includes(savedStep)) {
      savedStep = null;
    }
    this.setStep(savedStep ?? tutorialSteps[0]);
  }
}
