<ng-container>
  <div class="modal">
    <div class="modal-head">
      {{ "MODALS.PAYMENTS.CONFIRM.CONFIRMATION" | translate }}
    </div>
    <div class="modal-body">
      <div
        class="confirm-text"
        *ngIf="environment.buildVersion === buildVersion.erogames"
      >
        {{ "MODALS.PAYMENTS.CONFIRM.BUYING_ITEM_FOR" | translate }}
        <span><img src="./assets/img/erogold.svg" />{{ cost }}</span>
      </div>
      <div
        class="confirm-text"
        *ngIf="environment.buildVersion === buildVersion.nutaku"
      >
        {{ "MODALS.PAYMENTS.CONFIRM.BUYING_ITEM_FOR" | translate }}
        <span><img src="./assets/img/nutaku-gold-small.png" />{{ cost }}</span>
      </div>

      <div class="buttons">
        <button
          class="btn btn-block btn-uppercase"
          type="submit"
          (click)="cancel()"
        >
          {{ "SHARED.CANCEL" | translate }}
        </button>

        <button
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="confirm()"
        >
          {{ "SHARED.CONFIRM" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
