import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DailyRewardsModalComponent } from './daily-rewards-modal.component';
import { DailyRewardComponent } from './daily-reward/daily-reward.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [DailyRewardsModalComponent, DailyRewardComponent],
  imports: [CommonModule, TranslateModule, PipesModule],
  exports: [DailyRewardsModalComponent],
})
export class DailyRewardsModalModule {}
