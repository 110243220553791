import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChatData } from '../../types/chat-data.interface';

export interface UserData {
  avatar: string;
  chats: ChatData[];
  createdAt: string;
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  modifiedAt: string;
  music: boolean;
  nickName: string;
  pushNotifications: boolean;
  sound: boolean;
  user: [];
}
@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private _nutakuDisplayVersion: string;

  private data: UserData = {
    avatar: null,
    chats: [],
    createdAt: '',
    email: '',
    firstName: null,
    fullName: '',
    id: '',
    lastName: null,
    modifiedAt: '',
    music: false,
    nickName: '',
    pushNotifications: false,
    sound: false,
    user: [],
  };

  public updateUserData: BehaviorSubject<UserData>;

  get nutakuDisplayVersion(): string {
    return this._nutakuDisplayVersion;
  }

  constructor() {
    this.updateUserData = new BehaviorSubject(this.data);
  }

  public changeChatsData(value: ChatData[]): void {
    const defaultData = this.setNewData(this.data, 'chats', value);
    this.data = defaultData;
    this.updateUserData.next(defaultData);
  }

  public setNewData(obj: any, field: string, value: any): any {
    const setPath = (object: any, path: any, value: any) =>
      path
        .split('.')
        .reduce(
          (o: any, p: any, i: any) =>
            (o[p] = path.split('.').length === ++i ? value : o[p] || {}),
          object
        );
    setPath(obj, field, value);
    return obj;
  }

  public changeAllObject(value: any): void {
    this.updateUserData.next(value);
    this.data = value;
  }

  public setNutakuDisplayVersion(v: string) {
    this._nutakuDisplayVersion = v;
  }
}
