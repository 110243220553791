import { Component, Input, OnInit } from '@angular/core';
import { LegalPaymentService } from 'src/app/shared/services/payments/legal-payment.service';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { RealCostProperties } from '../../types/purchase-data.interface';

@Component({
  selector: 'app-price-block',
  templateUrl: './price-block.component.html',
  styleUrls: ['./price-block.component.scss'],
})
export class PriceBlockComponent implements OnInit {
  @Input()
  public purchase: RealCostProperties;
  public readonly isNutaku = environment.buildVersion === BuildVersion.nutaku;
  public readonly isErogames =
    environment.buildVersion === BuildVersion.erogames;
  public readonly environment = environment;

  public price: string | number = null;

  constructor(private _legalPaymentService: LegalPaymentService) {}

  public ngOnInit(): void {
    this.setPrice();
  }

  public setPrice() {
    switch (environment.buildVersion) {
      case BuildVersion.default:
        this.price = `$${this.purchase.cost}`;
        break;
      case BuildVersion.nutaku:
        this.price = this.purchase.nutakuCost;
        break;
      case BuildVersion.erogames:
        this.price = this.purchase.erogamesCost;
        break;
      case BuildVersion.legal:
        this._legalPaymentService
          .getProductPriceByCommonId(this.purchase.googlePlayId)
          .pipe()
          .subscribe((price) => {
            this.price = price;
          });
        break;
    }
  }
}
