<div class="neon-modal">
  <button (click)="close($event)" class="close-btn">
    <img src="/assets/img/modals/close-btn-black.svg" alt="" />
  </button>

  <h1 class="title">PREMIUM</h1>

  <div class="bottom">
    <p class="features-title">Get access to all the features:</p>
    <ul class="features">
      <li class="feature-card">
        <img src="/assets/img/quests-page/daily-icons/skipOffline.png" alt="" />
        Girls always<br />online
      </li>
      <li class="feature-card">
        <img src="/assets/img/quests-page/daily-icons/photo.png" alt="" />
        +300<br />Hot photos
      </li>
      <li class="feature-card">
        <img src="/assets/img/quests-page/icons/chat.png" alt="" />
        Girls text<br />you first
      </li>
      <li class="feature-card">
        <img src="/assets/img/quests-page/icons/email.png" alt="" />
        <span>+30</span>Flirty stories
      </li>
    </ul>

    <button class="neon-btn btn-cyan">
      <p class="btn-title">Subscribe</p>
      <p class="price">
        3 days free then
        <app-price-block [purchase]="offerData.purchase"></app-price-block> /
        week
      </p>

      <div class="shapes">
        <img
          src="/assets/img/offers-new/misc/shape1.png"
          alt=""
          class="shape-1"
        />
        <img
          src="/assets/img/offers-new/misc/shape2.png"
          alt=""
          class="shape-2"
        />
        <img
          src="/assets/img/offers-new/misc/shape3.png"
          alt=""
          class="shape-3"
        />
      </div>
    </button>
    <p *ngIf="offerData.time !== null" class="time-left">
      Time left:
      <app-offer-timer [initialTime]="offerData.time"></app-offer-timer>
    </p>
  </div>
</div>
