import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { ShopLot } from '../../types/shop-lot-new.interface';
import { WebsocketSignalRService } from '../../services/websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { PaymentsService } from '../../services/payments/payments.service';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ShopTokensService {
  public lots = new BehaviorSubject<ShopLot[]>([]);

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _paymentsService: PaymentsService
  ) {}

  public fetchTokens(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<ShopLot[]>(WebsocketCommandType.getGems, {})
      .pipe(
        take(1),
        map((res) => {
          this.lots.next(res);
          return true;
        })
      );
  }

  public purchase(lot: ShopLot): Observable<boolean> {
    return this._paymentsService
      .purchase({
        productId: lot.productId,
        purchase: lot.purchase,
        source: PurchaseItemType.gems,
        nutakuData: {
          nutakuName: lot.purchase.nutakuName,
          nutakuImageUrl: lot.purchase.nutakuImageUrl,
          nutakuDescription: lot.purchase.nutakuDescription,
        },
      })
      .pipe(take(1));
  }
}
