<ng-container>
  <div class="modal-container animate-bottom">
    <div
      class="modal modal-vertical modal-no-padding modal-auto-height"
      [style.width]="modalWidth"
    >
      <button (click)="this.close()" class="close-modal-btn">
        <img [src]="environment.rootPath + '/assets/img/close-modal-btn.svg'" />
      </button>
      <app-offers
        [appearanceType]="'modal'"
        (closeModal)="close()"
        [purchased]="purchased"
        [offerData]="offerData"
      ></app-offers>
    </div>
  </div>
</ng-container>
