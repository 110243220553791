import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { environment } from 'src/environments/environment';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthMethodsService } from './auth-methods.service';
import { UserDataService } from '../comunication_services/userData.service';
import { ModalController, Platform } from '@ionic/angular';
import { NutakuLoginModalComponent } from '../../components/misc-alerts/nutaku-login-modal/nutaku-login-modal.component';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { AuthStatus } from '../../enums/auth-status.enum';
import { from } from 'rxjs';
import { ModalAnimationsService } from '../../animations/modal-animations.service';
declare const gadgets;

@Injectable({
  providedIn: 'root',
})
export class AuthNutakuService {
  constructor(
    private _authMethodsService: AuthMethodsService,
    private _userDataService: UserDataService,
    private _modalCtrl: ModalController,
    private _tokenService: JWTTokenService,
    private _authStateService: AuthStateService,
    private _platform: Platform,
    private _analyticsService: AnalyticsService,
    private _modalAnimationsService: ModalAnimationsService
  ) {}

  public auth() {
    // TODO Improve version checker.
    if (this._platform.is('hybrid')) {
      this._analyticsService.setNutakuVersion('APK');
      console.log('Nutaku APK version');
      this._apkLogin();
    } else if (this._userDataService.nutakuDisplayVersion === 'pc') {
      this._analyticsService.setNutakuVersion('pc');
      console.log('Nutaku PC version');
      this._onLoginPc();
    } else {
      this._analyticsService.setNutakuVersion('sp');
      console.log('Nutaku SP version');
      this._onLoginSp();
    }
  }

  // PC version of Nutaku
  private _onLoginPc() {
    window.parent.postMessage(
      JSON.stringify({ action: 'auth', height: window.innerHeight }),
      environment.nutakuConfig?.parentUrl
    );
    window.addEventListener(
      'message',
      (res) => {
        try {
          const result = JSON.parse(res?.data);
          if (result?.action === 'auth') {
            this._authMethodsService
              .signWithNutaku(result.data)
              .pipe(take(1))
              .subscribe();
          }
        } catch (error) {
          console.warn('Listener warn', error);
        }
      },
      false
    );
  }

  // SP version of Nutaku // opensocial_app_id
  private _onLoginSp() {
    console.log('_onLoginSp', window.location.href);
    gadgets?.rpc?.call(null, 'nutaku.setIframeHeight', null, 750 + 'px');
    if (
      this._tokenService.getToken() &&
      (window.location.search.indexOf('payment_id') > -1 ||
        window.location.search.indexOf('opensocial_app_id') === -1)
    ) {
      from(this._authMethodsService.getUser())
        .pipe(take(1))
        .subscribe((res) => {
          this._authStateService.authStatus = AuthStatus.authenticated;
          gadgets?.rpc?.call(null, 'nutaku.setIframeHeight', null, 750 + 'px');
        });
    } else {
      this._authMethodsService.signWithNutakuSp().pipe(take(1)).subscribe();
    }
  }

  // APK version of Nutaku
  private _apkLogin() {
    if (this._tokenService.getToken()) {
      this._authMethodsService
        .signWithNutakuApkTry()
        .pipe(take(1))
        .subscribe(
          (res) => {},
          (err) => {
            this._showLoginCustomModal();
          }
        );
    } else {
      this._showLoginCustomModal();
    }
  }

  private _showLoginCustomModal() {
    this._modalCtrl
      .create({
        component: NutakuLoginModalComponent,
        componentProps: {},
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
        enterAnimation: this._modalAnimationsService.enterAnimation,
        leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
      .then((modal) => modal.present());
  }
}
