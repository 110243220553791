import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { ChatData, ChatType } from '../types/chat-data.interface';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthPayload } from '../enums/auth-payload.enum';
import { PromoWidgetStatus } from '../enums/promo-widget-status';
import { PromoBrand } from '../enums/chat-promo-brand.enum';
import { HelpersService } from './helpers.service';
import { SettingsDataService } from './comunication_services/settingsData.sevice';
import {
  SwipingCard,
  SwipingCardType,
} from './API_services/swiping-cards.interface';

export interface ModelsResponse {
  count: number;
  defaultTags: any[];
  isUser: boolean;
  models: {
    avatarUrl: string;
    broadcastGender: string;
    broadcastHD: boolean;
    broadcastInteractiveToy: string[];
    broadcastMobile: boolean;
    broadcastVR: boolean;
    earnedForGoal: number;
    favoritedCount: number;
    gender: string;
    goalEnabled: boolean;
    goalMessage: string;
    goalReachedAt: string;
    hasSignupRate: boolean;
    id: number;
    isFace: boolean;
    isMlAnal: boolean;
    isMlBlowjob: boolean;
    isNew: boolean;
    isPerson: boolean;
    languages: string[];
    modelsCountry: string;
    neededForGoal: number;
    normalizedHistoryRating: number;
    normalizedViewersRating: number;
    popularSnapshotUrl: string;
    previewUrl: string;
    previewUrlThumbBig: string;
    previewUrlThumbSmall: string;
    priceGroupShow: number;
    priceP2p: number;
    priceP2pVoice: number;
    pricePrivate: number;
    priceSpy: number;
    priceTicketShow: number;
    ratingTrending: number;
    snapshotUrl: string;
    status: string;
    statusChangedAt: string;
    stream: any;
    strict: boolean;
    token: string;
    topBestPlace: number;
    username: string;
    viewersByTypeCount: any;
    viewersCount: number;
    widgetPreviewUrl: string;
  }[];
  prevailingGender: string;
  tagName: string;
  tagType: string;
  total: number;
  ttl: number;
  useLive: boolean;
  userType: string;
}

@Injectable({
  providedIn: 'root',
})
export class PromoStripchatService {
  private _host = environment.apiUrl;
  private _promoUrl: BehaviorSubject<string> = new BehaviorSubject(null);

  get promoUrl$(): Observable<string> {
    return this._promoUrl.asObservable();
  }

  constructor(
    private _helpersService: HelpersService,
    private _platform: Platform,
    private _http: HttpClient,
    private _settingsDataService: SettingsDataService
  ) {}

  private _getUrlFromApi(characterName: string): void {
    const url = `${this._host}/api/User/get-stripchat-login-url`;
    const campaignId = this._platform.is('hybrid') ? 'GMN_N1_M' : 'GMN_N1';
    const trafficSource = localStorage.getItem(AuthPayload.trafficSource);
    const requestUrl = `${url}?campaignId=${campaignId}&room=${characterName}&trafficSource=${trafficSource}`;
    this._http
      .get(requestUrl)
      .pipe(take(1))
      .subscribe((res: { loginUrl: string }) => {
        this._promoUrl.next(res.loginUrl);
      });
  }

  private _getStaticUrl(characterName: string): void {
    const clientId = this._settingsDataService.clientId;
    // eslint-disable-next-line max-len
    const link = `https://go.xlrdr.com?campaignId=gmn2&userId=4919d570c9c3cb363708c997071c64b343711a037a55be3af6c9b78d666779ff&onlineModels=${characterName}&memberId=${clientId}`;
    this._promoUrl.next(link);
  }

  private _addPromoChatToListOfChats(
    listOfChats: ChatData[],
    promoChat: ModelsResponse,
    model
  ) {
    listOfChats.splice(this._helpersService.randomIntFromInterval(0, 4), 0, {
      characterAvatar: model.avatarUrl,
      characterName: model.username,
      id: '',
      messages: [
        {
          messageText: 'Chat with real girls online',
        },
      ],
      status: 'Open',
      feedbackFormShow: false,
      promoWidget: PromoWidgetStatus.pending,
      promoCompany: PromoBrand.stripchat,
      type: ChatType.promo,
    } as ChatData);
    return listOfChats;
  }

  private _addPromoChatToListOfCards(
    listOfCards: SwipingCard[],
    promoChat: ModelsResponse,
    model
  ) {
    listOfCards.splice(this._helpersService.randomIntFromInterval(0, 4), 0, {
      id: '',
      cardSetId: '',
      type: SwipingCardType.promo,
      name: model.username,
      description: 'Chat with real girls online',
      chatId: '',
      imageUrl: model.previewUrlThumbBig,
      chatLotId: '',
      purchase: null,
      promoWidget: PromoWidgetStatus.pending,
      promoCompany: PromoBrand.stripchat,
    } as SwipingCard);
    return listOfCards;
  }

  addPromoWidgeToChat(response): Observable<ChatData[]> {
    const listOfChats = response;
    return this.fetchUrl().pipe(
      map((res: ModelsResponse) => {
        this._promoUrl.next(null);
        const model =
          res?.models[
            this._helpersService.randomIntFromInterval(
              0,
              res?.models.length - 1
            )
          ];
        // this._getUrlFromApi(model.username);
        this._getStaticUrl(model.username);
        return this._addPromoChatToListOfChats(listOfChats, res, model);
      })
    );
  }

  addPromoWidgeToCards(response): Observable<SwipingCard[]> {
    const listOfCards = response;
    return this.fetchUrl().pipe(
      map((res: ModelsResponse) => {
        this._promoUrl.next(null);
        const model =
          res?.models[
            this._helpersService.randomIntFromInterval(
              0,
              res?.models.length - 1
            )
          ];
        // this._getUrlFromApi(model.username);
        this._getStaticUrl(model.username);
        return this._addPromoChatToListOfCards(listOfCards, res, model);
      })
    );
  }

  fetchUrl(): Observable<any> {
    return this._http.get(
      `${this._host}/api/proxy/models?limit=10&status=public&sortOrder=desc&sortBy=viewersCount`
    );
  }

  getStandaloneBannerLink(): string {
    const clientId = this._settingsDataService.clientId;
    // eslint-disable-next-line max-len
    return `https://go.xlviiirdr.com?campaignId=gmn4&userId=4919d570c9c3cb363708c997071c64b343711a037a55be3af6c9b78d666779ff&memberId=${clientId}`;
  }
}
